import { Toast } from "antd-mobile";
import Ambassadors from "../vendorMoudle/containers/Ambassadors";

export default {
  translation: {
    CHECK_EMAIL_TITLE: "Check Email",
    WELCOME_BACK: "Welcome Back!",
    ENTER_YOUR_CREDENTIALS: "Enter your credentials to continue",
    EMAIL_FIELD: "Email",
    CHECK_BUTTON: "Check",
    EMAIL_IS_REQUIRED: "E-mail Is required",
    CREATE_PASSWORD_TITLE: "Create Password",
    SUBMIT_BUTTON: "submit",
    LOGIN_TITLE: "Login",
    PASSWORD_FIELD: "Password",
    PLEASE_INPUT_YOUR_PASSWORD: "Please input your password!",
    FORGOT_PASSWORD: "Forgot Password?",
    REMEMBER_ME: "Remember me",
    LOGIN_TO_ACCOUNT_BUTTON: "Login to Account",
    SIDEBAR_ORDER_HISTORY: "Order History",
    SIDEBAR_SIGNOUT: "Signout",
    FOOTER_MENU_HOME: "Home",
    FOOTER_MENU_SHARE: "Share",
    FOOTER_MENU_KWIKCASH: "1Cash",
    FOOTER_MENU_HISTORY: "History",
    SHARE_THE_PRODUCTS_TITLE: "Share the Products",
    MY_CAMPAIGNS: "ARIIX Campaigns",
    PLEASE_SELECT_A_CAMPAIGN_TO_SHARE:
      "Select the products you would like to create a link for.",
    CREATE_A_KWIKLINK_BUTTON: "Create a Kwiklink",
    MANAGE_MY_KWIKLINKS: "Manage my Kwiklinks",
    KWIK_CASH_TITLE: "1Cash",
    KWIK_LINK_TITLE: "Share the Products",
    FIELD_PRODUCT: "Product",
    FIELD_QUANTITY: "Quantity",
    FIELD_PRICE: "Price",
    FIELD_KWIKCASH: "1Cash",
    SHARE_THIS_PRODUCT_TO: "Share",
    COPY_LINK: "Copy Link",
    RETURN_TO_HOME_BUTTON: "Return to Home",
    COPY_SUCCESS: "Link Successfully Copied",
    TOTAL_KWIKCASH_EARNED: "Total Kwikcash Earned",
    TOTAL_CASHED_OUT: "Total Cashed Out",
    KWIKCASH_BALANCE: "1Cash Balance",
    CASH_OUT_BUTTON: "Cash Out",
    WEEK_COL_NAME: "Week",
    KWIK_CASH_COL_NAME: "Kwik Cash",
    PRODUCT_INFO_TITLE: "Product Info",
    KWIKCASH_VALUE: "KwikCash Value",
    DESCRIPTION: "Description",
    IMPORTANT_INFORMATION: "Important Information",
    INGREDIENTS: "Ingredients",
    USAGE: "Usage",
    HANDLING_INSTRUCTIONS: "Handling Instructions",
    MANUFACTURER: "Manufacturer",
    ADD_TO_CART_BUTTON: "Add to Cart",
    YOUR_CART_TITLE: "Your Cart",
    PRODUCTS: "Products",
    REMOVE_LINK: "Remove",
    FIELD_TAX: "Tax",
    FIELD_TOTAL: "Total",
    PROCEED_TO_CHECKOUT_BUTTON: "Proceed to Checkout",
    CHECKOUT_TITLE: "Checkout",
    SHIPPING_ADDRESS: "Shipping Address",
    NEW_LINK: "New",
    PAYMENT_BREAKDOWN: "Payment Breakdown",
    SHOW_SAVE_CARDS: "Show Save The Cards",
    FIELD_NAME_ON_CARD: "Name on Card",
    FIELD_CARD_NUMBER: "Card Number",
    FIELD_EXPIRY_YEAR: "Expiry Year",
    FIELD_EXPIRY_MONTH: "Expiry Month",
    FIELD_CVV: "CVV",
    SAVE_THIS_CARD: "Save This Card",
    BILLING_ADDRESS_IS_SAME_SHIPPING_ADDRESS:
      "Billing Address is Same As Shipping Address",
    BILLING_ADDRESS: "Billing address",
    FIELD_FIRST_NAME: "First Name",
    FIELD_LAST_NAME: "Last Name",
    FIELD_ADDRESS_LINE: "Address Line",
    FIELD_ZIP: "Zip",
    FIELD_PHONE: "Phone Number",
    FIELD_CITY: "City",
    FIELD_STATE: "State",
    FIELD_COUNTRY: "Country",
    FIELD_NAME: "Name",
    PLEASE_SELECT: "select",
    SHIPPING: "Shipping",
    CONTINUE_TO_PLACE_ORDER_BUTTON: "Continue to Place Order",
    SAVE_AND_USE_THIS_ADDRESS_BUTTON: "Proceed to Next",
    CONGRATULATIONS: "Order Complete!",
    PAYMENT_SUCCESSFUL: "Payment Successful",
    STANDARD: "Standard",
    ORDER_ACCEPTED: "Order Accepted",
    CHECKOUT_MESSAGE1:
      "Your Order No. #{{orderId}} has been placed and an email confirmation has been sent to",
    CHECKOUT_MESSAGE2:
      "Congrats! Earn $2.5 every time someone buys from your promo code! Click the Share button below.",
    SHARE_THE_PRODUCT_BUTTON: "Share Your Link",
    TRACK_ORDER: "Track Order",
    WAVES_COL: "Waves",
    NAME_COL: "Name",
    ORDER_K_CASH_COL: "Order Total",
    K_CASH_RATE: "K.Cash Rate",
    CASH_HISTORY_TITLE: "Cash History",
    TRANSFER_TO_BANK_TITLE: "Transfer to Bank",
    SAVED_BANK_LINK: "Saved Bank",
    ADD_BANK_LINK: "Add Bank",
    SELECT_LINK: "Select",
    PROCEED_TO_NEXT_BUTTON: "Proceed to Next",
    FIELD_BANKS_COUNTRY: "Bank's Country",
    FIELD_CURRENCY_CODE: "Currency Code",
    FIELD_NAME_ON_ACCOUNT: "Name on Account",
    FIELD_NAME_IBAN: "IBAN",
    FIELD_NAME_RE_IBAN: "Re-IBAN",
    FIELD_NAME_ON_ROUTING_NUMBER: "Routing Number",
    FIELD_NAME_SORT_CODE: "Sort Code",
    FIELD_NAME_STATE: "State",
    FIELD_NAME_SWIFT_CODE: "Swift Code",
    FIELD_NAME_ZIP_CODE: "Postal Code",
    FIELD_ACCOUNT_NUMBER: "Account Number",
    FIELD_RE_ACCOUNT_NUMBER: "Re-Enter Account Number",
    FIELD_ACCOUNT_TYPE: "Account Type",
    FIELD_ACCOUNT_TYPE_OPTION_CHECKING: "Checking",
    FIELD_ACCOUNT_TYPE_OPTION_SAVINGS: "Savings",

    FIELD_SWIFT_CODE: "SWIFT",
    FIELD_BIC: "BIC",
    FIELD_SWIFT_CODE_BIC: "Swift Code/BIC",
    FIELD_BIC_CODE_SORT: "BIC/Sort Code",
    FIELD_BANK_NAME: "Bank Name",
    FIELD_BANK_CODE: "Bank Code",
    FIELD_BANK_NUMBER: "Bank Number",
    FIELD_BRANCH_NAME: "Branch Name",
    FIELD_BRANCH_CODE: "Branch Code",
    FIELD_BANK_BSB: "BSB",
    SAFE_KEEP_MESSAGE:
      "This information will be securely saved as per the Terms of Service & Privacy Policy",
    SAVE_BANK_DETAILS_BUTTON: "Save Bank Details",
    AVAILABLE_BALANCE: "Available Balance",
    TRANSFER_TO_INTRODUCE_MESSAGE:
      "Choose the amount of money you would like to transfer from your kwikcash to your selected bank account.",
    FIELD_AMOUNT: "Amount",
    SELECTED_BANK: "Selected Bank",
    VERIFY_BANK_CARD_INFORMATION:
      "Please Verify that the Bank and the Kwikcash Amount are correct. You CANNOT change your mind after you do this.",
    TRANSACTION_COMPLETE: "Transaction Complete!",
    TRANSACTION_CONSEQUENCE_MESSAGE:
      "Your Transaction number is {{amount}} and it has been placed. An email confirmation has been sent to {{email}}",
    TRANSACTION_CONSEQUENCE_MESSAGE2:
      "Awesome! Please allow up to 24 Hours for the 1Cash Balance to get updated.",
    RETURN_BUTTON: "Return To Home",
    VIEW_TRANSACTION_HISTORY: "View Transaction history",
    ORDER_TEXT: "Order",
    ITEM_LIST_IS_EMPTY: "Please select product",
    CART_MUST_NOT_BE_EMPTY: "The shopping cart must not be empty",
    CLICK_SEND_EMAIL:
      "Please click the button to send an email to continue the initial password",
    SELECTED_DATE_GREATER_CURRENT_DATE:
      "The selected date should be greater than the current date",
    CARD_NUMBER_FORMAT_IS_INCORRECT: "The card number format is incorrect",
    ITEM_ADDED_TO_CART: "You have successfully added item to cart!",
    ORDER_NUMBER: "Order Number",
    CREATION_TIME: "Order Date",
    ORDER_STATUS: "Order status",
    ARE_YOU_SURE: "Are you sure?",
    CANCEL: "Cancel",
    OK: "Ok",
    EMAIL_IS_NOT_UNIQUE_SHARE_LINK:
      "Our records show that this email already has an account with WER1. Please use a different email, or login to complete the order.",
    EMAIL_IS_NOT_UNIQUE:
      "This email is already used in kwikclick.com. Please use another email, or go to kwikclick.com to log in to place orders on these or more products.",
    EMAIL_IS_NOT_UNIQUE_ARIIX:
      "Our records show that this email has already been registered as a customer of ARIIX Japan. Please use another email, or go to kwikclick.com to log in with this email to explore more campaigns.",
    REQUIRED_FIELD: "Required field",
    INCORRECT_EMAIL_FORMAT: "Incorrect email format",
    REFUND: "Refund",
    DELETE: "Delete",
    PLEASE_INPUT_TAGLINK: "Please input Tag Link",
    PLEASE_INPUT_CITY: "Please input city",
    UPDATE_SUCCESSFULLY: "Updated Successfully",
    TAGLINK_TEXT: "Tag Link",
    // shipping
    EXPLORATION_TITLE: "Exploration",
    SEE_ALL_NUM_PRODUCTS: "See All {{prodNumber}} Products",
    LEARN_MORE_LINK: "Learn More",
    KWIKCASH_VALUE_MESS: "Kwikcash Value",
    SHARE_YOUR_LINK: "SHARE YOUR LINK",
    SEND_LINK_AND_EARN_KWIKCASH_MESS: "Send Link & Earn Kwikcash",
    PRICE_TEXT: "PRICE",
    //'ADD_TO_CART_BUTTON': 'Add To Cart',
    OUT_OF_STOCK_TEXT: "Out of Stock",

    // order ,
    ORDER_HISTORY_TEXT: "Order History",
    ORDER_HISTORY_TIPS_NEED_PURCHASE: "You need to make a purchase first!",
    ORDER_HISTORY_EXPLORE_PRODUCTS: "Explore Products",
    ORDER_HISTORY_ORDER_ON: "Order On",

    ORDER_DETAIL_EXPECTED_DATE: "Expected Date",
    ORDER_DETAIL_TRACKING_ID: "Tracking ID",
    ORDER_DETAIL_BILLING_INFO_SAME_AS_SHIPPING: "Billing Info Same as Shipping",
    ORDER_DETAIL_ORIGINAL_PRICE: "Original Price",
    ORDER_DETAIL_SHIPPINGN_HANDING: "Shipping and Handling",

    // KwikCash
    KWIK_CASH_AVAILABLE_BALANCE: "Available Balance",
    KWIK_CASH_PENDING_BALANCE: "Pending Balance",
    KWIK_CASH_VIEW_HISTORY: "View History",
    KWIK_CASH_TRANSFER_BANK: "Transfer to Bank",
    ACCOUNT_NUMBER_4: "Account number should be no less than 4 characters",
    KWIK_CASH_PENDING: "pending",
    KWIK_CASH_PAID: "paid",
    KWIK_CASH_FULFILLED: "fulfilled",

    //Campaigns
    CAMPAIGNS_SHARE_A_CAMPAIGN: "Share a Campaign",
    CAMPAIGNS_ACTIVE_CAMPAIGN: "Active Campaign",
    CAMPAIGNS_NODATA_TEXT: "To get started, find a product to endorse",
    CAMPAIGNS_EXPLORE_PRODUCTS: "Explore Products",

    //Commen
    PROGRESS_LOADING: "Loading...",

    // login in
    LOGIN_WELCOME_BACK: "Welcome Back!",
    LOGIN_CREDENTIALS: "Enter your credentials to continue",
    LOGIN_LABEL_USERNAME: "Username",
    LOGIN_PLACEHOLDER_USERNAME: "Username or Email",
    LOGIN_REQUIRED_USERNAME: "Username is required",
    LOGIN__LABEL_PASSWORD: "Password",
    LOGIN_PLACEHOLDER_PASSWORD: "Password",
    LOGIN_REQUIRED_PASSWORD: "Please input your password!",
    LOGIN_REMEMBER_ME: "Remember me",
    LOGIN_FORGOT_PASSWORD: "Forgot Password?",
    LOGIN_BUTTON_LOGIN: "Login",
    LOGIN_NEED_ACCOUNT: "Need an account?",
    LOGIN_CREATE_ACCOUNT: "Create New Account",

    // register
    VIEWS_REGISTER_1_CREATE_ACCOUNT: "Create Your Account ",
    VIEWS_REGISTER_1_FIELDS_REQUIRED:
      "Please fill out the form below. All fields are required.",
    VIEWS_REGISTER_1_LABEL_EMAIL_ADDRESS: "Email Address",
    VIEWS_REGISTER_1_PLACEHOLDER_EMAIL_ADDRESS: "Email Address",
    VIEWS_REGISTER_1_REQUIRED_EMAIL: "Email Address must not be empty",
    VIEWS_REGISTER_1_LABEL_FIRST_NAME: "First Name",
    VIEWS_REGISTER_1_PLACEHOLDER_FIRST_NAME: "First Name",
    VIEWS_REGISTER_1_REQUIRED_FIRST_NAME: "First Name must not be empty",
    VIEWS_REGISTER_1_LABEL_LAST_NAME: "Last Name",
    VIEWS_REGISTER_1_PLACEHOLDER_LAST_NAME: "Last Name",
    VIEWS_REGISTER_1_REQUIRED_LAST_NAME: "Last Name must not be empty",
    VIEWS_REGISTER_1_LABEL_USER_NAME: "Username",
    VIEWS_REGISTER_1_PLACEHOLDER_USER_NAME: "Username",
    VIEWS_REGISTER_1_REQUIRED_USER_NAME: "User Name must not be empty",
    VIEWS_REGISTER_1_LABEL_PHONE: "Phone Number",
    VIEWS_REGISTER_1_PLACEHOLDER_PHONE: "Phone Number(unique)",
    VIEWS_REGISTER_1_REQUIRED_PHONE: "Phone Number must not be empty",
    VIEWS_REGISTER_1_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_1_TIPS_DIGIT_SENT:
      "A 4-digit code has been sent to your email",
    VIEWS_REGISTER_1_TIPS_EMAIL_REGISTERED:
      "Our records show that this email has already been registered with WER1.",
    VIEWS_REGISTER_1_BUTTON_EMAIL_REGISTERED_FORGOT_PASSWORD: "Forgot Password",
    VIEWS_REGISTER_1_BUTTON_EMAIL_REGISTERED_CANCEL: "Cancel",
    VIEWS_REGISTER_1_TIPS_USERNAME_REGISTERED:
      "This username is taken. Please try another one. ",
    VIEWS_REGISTER_1_BUTTON_OK: "Ok",

    VIEWS_REGISTER_2_VERIFICATION: "Verification",
    VIEWS_REGISTER_2_TO_VERIFY: "To verify that it is you",
    VIEWS_REGISTER_2_TIPS_ENTER:
      "Enter the 4 digit code that was sent to you at",
    VIEWS_REGISTER_2_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_2_NEED_CODE: "Don’t have a code yet?",
    VIEWS_REGISTER_2_LINK_RESEND: "Resend",
    VIEWS_REGISTER_2_LOADING: "Loading...",

    VIEWS_REGISTER_3_TITLE_PASSWORD_CREATE: "Create New Password",
    VIEWS_REGISTER_3_TITLE_ENTER_NEW_PASSWORD: "Enter a new secure password",
    VIEWS_REGISTER_3_CREATE_LABEL: "Create Password",
    VIEWS_REGISTER_3_CREATE_PLACEHOLDER: "Create Password",
    VIEWS_REGISTER_3_CREATE_REQUIRED: "Please input your password!",
    VIEWS_REGISTER_3_CONFIRM_LABEL: "Confirm Password",
    VIEWS_REGISTER_3_CONFIRM_PLACEHOLDER: "Confirm Password",
    VIEWS_REGISTER_3_CONFIRM_REQUIRED: "Please input your password!",
    VIEWS_REGISTER_3_TOAST_PATTERN:
      "The password requires eight characters mininum with at least one upper case, one lower case and a numeric number.",
    VIEWS_REGISTER_3_TIPS_MATCH: "The two passwords don't match",
    VIEWS_REGISTER_3_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_3_BUTTON_BACK: "Back",
    VIEWS_REGISTER_3_LOADING: "Loading...",

    VIEWS_REGISTER_4_TITLE: "Upload a profile picture",
    VIEWS_REGISTER_4_MESSAGE:
      "This will make you famous and add trust on your account.",
    VIEWS_REGISTER_4_BUTTON_NEXT: "Proceed to Next",
    VIEWS_REGISTER_4_TIPS_NOT_NOW: "Not Now?",
    VIEWS_REGISTER_4_LINK_DO_LATER: "Wanna do it later",

    VIEWS_REGISTER_5_TITLE: "Create your account",
    VIEWS_REGISTER_5_MESSAGE: "Please provide the following info below",
    VIEWS_REGISTER_5_TIPS_YOUR_BIO: "Your Bio",
    VIEWS_REGISTER_5_PLACEHOLDER_TYPING: "Start typing here...",
    VIEWS_REGISTER_5_BUTTON_NEXT: "Submit & Proceed to Next",
    VIEWS_REGISTER_5_TIPS_NOT_NOW: "Not Now?",
    VIEWS_REGISTER_5_LINK_DO_LATER: "Wanna do it later",

    VIEWS_REGISTER_6_BUTTON_BACK: "Back",
    VIEWS_REGISTER_6_TITLE: "Provide your personal info",
    VIEWS_REGISTER_6_MESSAGE: "Please provide some of your info.",
    VIEWS_REGISTER_6_ADDRESS_LINE_1: "Address Line 1",
    VIEWS_REGISTER_6_ADDRESS_LINE_2: "Address Line 2",
    VIEWS_REGISTER_6_POSTAL_CODE: "Postal Code",
    VIEWS_REGISTER_6_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_6_COUNTRY_USA: "United States",
    VIEWS_REGISTER_6_COUNTRY_CHA: "China",

    VIEWS_REGISTER_SSO_1_TIPS_EMAIL_REGISTERED:
      "Our records show that this User Name has already been registered with Kwikclick. ",
    VIEWS_REGISTER_SSO_1_BUTTON_OK: "Ok",
    VIEWS_REGISTER_SSO_1_TIPS_SIGNUP: "Signup",
    VIEWS_REGISTER_SSO_1_TITLE: "Create your account",
    VIEWS_REGISTER_SSO_1_MESSAGE:
      "Congratulation! Your NewAge ID and Password have been verified. Please provide the following info below",
    VIEWS_REGISTER_SSO_1_TIPS_EMAIL:
      "Email  (We will verify the uniqueness of your email)",
    VIEWS_REGISTER_SSO_1_TIPS_FIRST: "First Name",
    VIEWS_REGISTER_SSO_1_REQUIRED_FIRST: "First Name must not be empty",
    VIEWS_REGISTER_SSO_1_TIPS_LAST: "Last Name",
    VIEWS_REGISTER_SSO_1_REQUIRED_LAST: "Last Name must not be empty",
    VIEWS_REGISTER_SSO_1_TIPS_USERNAME:
      "User Name  (We will verify the uniqueness of your username)",
    VIEWS_REGISTER_SSO_1_REQUIRED_USERNAME: "User Name must not be empty",
    VIEWS_REGISTER_SSO_1_TIPS_PHONE: "Phone Number",
    VIEWS_REGISTER_SSO_1_REQUIRED_PHONE: "Phone Number must not be empty",
    VIEWS_REGISTER_SSO_1_BUTTON_NEXT: "Next",

    // Campaigns
    CAMPAIGNS_BUTTON_OK: "Ok",
    CAMPAIGNS_LOADING: "Loading...",
    CAMPAIGNS_CANNOT_SHARE_MESSAGE_1: "Sorry, you can't share product ",
    CAMPAIGNS_CANNOT_SHARE_MESSAGE_2:
      ". Please buy this product now, or apply for the eligibility.",
    CAMPAIGNS_TITLE_STATS: "Stats",
    CAMPAIGNS_TIPS_CASH_EARNED: "Total Kwik Cash Earned This Week",
    CAMPAIGNS_TIPS_TOTAL_KWIKCASH_EARNED: "Total Kwikcash Earned",
    CAMPAIGNS_TIPS_GENERATE_SALES: "Generated Sales",
    CAMPAIGNS_TITLE_CAMPAIGNS: "Campaigns",
    CAMPAIGNS_TIPS_VIEW_DETAIL: " (Click to view details)",
    CAMPAIGNS_TITLE_ALL_STATS: "All Campaign Stats",
    CAMPAIGNS_TIPS_KWIK_INFO: "Kwik Info",
    CAMPAIGNS_TITLE_TOTAL_BUYERS: "Total Buyers",

    CAMPAIGNS_BUTTON_QR_CODE: "QR code",
    CAMPAIGNS_BUTTON_COPY_LINK: "Copy Link",
    CAMPAIGNS_HEADER_TITLE_CAMPAIGNS_STATS: "Campaigns Stats",
    CAMPAIGNS_TITLE_TOTAL_CUSTOMERS: "Total Customers",
    CAMPAIGNS_BUTTON_VIEW_DATA: "View Data",

    CAMPAIGNS_TITLE_ACTIVE_CAMPAIGN: "Active Campaign",
    CAMPAIGNS_TITLE_SHARE_CAMPAIGN: "Share a Campaign",
    CAMPAIGNS_TITLE_CAMPAIGN_DETAILS: "Campaign Details",
    CAMPAIGNS_TITLE_WAVES: "Waves",
    CAMPAIGNS_TITLE_WAVE: "Wave",
    CAMPAIGNS_TIPS_KWIKCASH_EARNED: "1Cash Earned",
    CAMPAIGNS_TITLE_BUYER: "Buyer",
    CAMPAIGNS_COLOR_MEANS_GREEN: "Green means KwikCash is avaliable",
    CAMPAIGNS_COLOR_MEANS_YELLOW: "Yellow means KwikCash is pending",
    CAMPAIGNS_COLOR_MEANS_RED: "Red means KwikCash is not deliverable",
    CAMPAIGNS_TITLE_KWIK_INFO: "Kwik Info",

    //ShareLink
    SHARE_LINK_IMAGE_5M: "Images must not be larger than 5 megabytes",
    SHARE_LINK_REFERRED_BY: "Referred By",
    SHARE_LINK_DESCRIPTION: "Description",
    SHARE_LINK_ADD_TO_CART: "+ Add To Cart",
    SHARE_LINK_PRODUCTS_DESC: "Product Description",
    SHARE_LINK_LEAVE_MESSAGE: "Leave a review",
    SHARE_LINK_LEARN_MORE: "Learn More",
    SHARE_LINK_REVIEWS: "Reviews",
    SHARE_LINK_RATE_THE_PRODUCT: "Rate the Product",
    SHARE_LINK_SHARE_YOUR_THOUGHTS_ON_THE_PRODUCT:
      "Share your thoughts on the product",
    SHARE_LINK_ADD_AN_IMAGE_TO_YOUR_REVIEW: "Add an image to your review",
    SHARE_LINK_DRAG_AND_DROP: "DRAG AND DROP",
    SHARE_LINK_YOUR_FILES_TO_ASSETS_OR_BROWSE:
      "your files to assets,or <span>browse</span>",
    SHARE_LINK_LEAVE_REVIEW: "Leave Review",
    SHARE_LINK_MY_CART: "My Cart",
    SHARE_LINK_CHECKOUT: "CHECKOUT",
    SHARE_LINK_CHECKOUT2: "Checkout",
    SHARE_LINK_DISCOUNT: "Discount",
    SHARE_LINK_WILL_BE_CALCULATED_AT_CHECKOUT: "Will be calculated at checkout",
    SHARE_LINK_RETURNING_CUSTOMER: "Returning Customer",
    SHARE_LINK_SIGN_IN_FOR_FASTER_CHECKOUT: "Sign in for faster checkout",
    SHARE_LINK_USERNAME_OR_EMAIL: "Username or Email",
    SHARE_LINK_USERNAME_IS_REQUIRED: "Username is required",
    SHARE_LINK_PASSWORD: "Password",
    SHARE_LINK_OR: "OR",
    SHARE_LINK_NEW_CUSTOMER: "New Customer",
    SHARE_LINK_DONT_HAVE_A_MESSAGE:
      "Dont have a kwik account yet? This is the first step! Complete the transaction to begin setting up your account!",
    SHARE_LINK_ADD_NEW_ADDRESS: "Add New Address",
    SHARE_LINK_TO_MAKE_SURE_MESSAGE:
      "To make sure the connection works property, please make sure this is the email that you used or will use on your Kwik Account.",
    SHARE_LINK_ADDRESS: "Address",
    SHARE_LINK_APARTMENT_SUITE_ETC: "Apartment, Suite, Etc. (Optional)",
    SHARE_LINK_APT_SUITE_ETC: "Apt, suite, etc. (optional)",
    SHARE_LINK_CITY: "City",
    SHARE_LINK_COUNTRY: "Country",
    SHARE_LINK_STATE: "State",
    SHARE_LINK_ZIP_CODE: "Zip Code",
    SHARE_LINK_PHONE: "Phone",
    SHARE_LINK_CANCEL: "Cancel",
    SHARE_LINK_PRODUCTS: "Products",
    SHARE_LINK_SHIPPING: "Shipping",
    SHARE_LINK_TOTAL: "Total",
    SHARE_LINK_ENTER_YOUR_CARD_DETAILS: "Enter Your Card Details",
    SHARE_LINK_PLEASE_INPUT_YOUR_CARD_NAME: "Please input your card name!",
    SHARE_LINK_PLEASE_INPUT_YOUR_CARD_NUMBER: "Please input your card number!",
    SHARE_LINK_EXPIRATION_DATE: "Expiration Date",
    SHARE_LINK_EXPIRATION_YEAR: "Expiration Year",
    SHARE_LINK_PLEASE_INPUT_YOUR_CARD_CVV: "Please input your card CVV!",
    SHARE_LINK_BILLING_ADDRESS_IS_THE_SAME_MESSAGE:
      "Billing address is the same as delivery address",
    SHARE_LINK_ENTER_YOUR_BILLING_ADDRESS: "Enter Your Billing address",
    SHARE_LINK_SAVE_THIS_CARD: "Save This Card",
    SHARE_LINK_COUNTINUE: "Continue",
    SHARE_LINK_SHIPPING_ADDRESS: "Shipping Address",
    SHARE_LINK_EDIT: "Edit",
    SHARE_LINK_SHIPPING_AND_HANDLING: "Shipping and Handling",
    SHARE_LINK_CHANGE: "Change",
    SHARE_LINK_PRICE_COUNT_ITEM: "Price ({{count}} item)",
    SHARE_LINK_PRICE_TAX: "Tax",
    SHARE_LINK_PRICE: "PRICE",
    SHARE_LINK_PLACE_YOUR_ORDER: "Place Your Order",
    SHARE_LINK_PAYMENT_SUCCESSFUL: "Payment Successful!",
    SHARE_LINK_CONGRATS_MESSAGE:
      "Congrats! Earn Kwik cash every time someone buys from your promo code! Click the Share button below.",
    SHARE_LINK_SHARE_YOUR_LINK: "SHARE YOUR LINK",
    SHARE_LINK_CREATE_MY_ACCOUNT: "Create My Account",
    SHARE_LINK_DOWNLOAD_IMAGE: "download image",
    SHARE_LINK_COPY_LINK: "Copy Link",
    SHARE_LINK_ORDER_ACCEPTED: "Order Accepted",
    SHARE_LINK_SELECT_THE_PRODUCTS_YOU_WOULD_LIKE:
      "Select the products you would like",
    SHARE_LINK_PRODUCT_NOT_AVAILABLE:
      "The product you have chosen is not available in the selected country.",
    SHARE_LINK_OUT_OF_STOCK: "Out of Stock",
    SHARE_LINK_PRICE2: "Price",
    SHARE_LINK_ADD: "Add",
    SHARE_LINK_CONTINUE_TO_CHECKOUT: "Continue to Checkout",
    SHARE_LINK_SO_YOURE_ALREADY_MESSAGE:
      "So you’re already in {{totalAlreadyJoin}} of the {{totalCpgn}} campaigns you selected. When you complete the purchase of the products you will be joining {{referName}} in the {{totalCanJoin}} campaigns selected",
    SHARE_LINK_SINCE_YOU_ALREADY_MESSAGE:
      "Since you’ve already joined this campaign with {{originalName}}, they will get the credit.",
    SHARE_LINK_SINCE_YOU_HAVE_ALREADY_MESSAGE:
      "Since you’ve have already joined all {{totalAlreadyJoin}} campaigns. When you complete the purchase of the products , they will get the credit.",
    SHARE_LINK_CLICK_TO_SEE_DETAILS: "Click to see details",
    SHARE_LINK_REFERRER: "Referrer",
    SHARE_LINK_CAMPAIGN: "Campaign",
    SHARE_LINK_CAMPAIGNS_TYPE: "Campaign Type",
    SHARE_LINK_DATE_JOINED: "Date Joined",
    SHARE_LINK_SINGLE_CAMPAIGN: "Single Campaign",
    SHARE_LINK_ULTI_PRODUCT: "ulti - Product",
    SHARE_LINK_KWIKCASH: "1Cash",
    SHARE_LINK_QUANTITY: "Quantity",
    SHARE_LINK_SUBTOTAL: "SubTotal",
    SHARE_LINK_PLEASE_INPUT_YOUR_FIRSTNAME: "Please input your firstName!",
    SHARE_LINK_PLEASE_INPUT_YOUR_LASTNAME: "Please input your lastName!",
    SHARE_LINK_PLEASE_INPUT_YOUR_ZIP: "Please input your zip!",
    SHARE_LINK_PLEASE_INPUT_YOUR_STATE: "Please input your state!",
    SHARE_LINK_PLEASE_INPUT_YOUR_CITY: "Please input your city!",
    SHARE_LINK_PLEASE_INPUT_YOUR_ADDRESS_LINE1:
      "Please input your Address Line1!",
    SHARE_LINK_PLEASE_SELECT_YOUR_STATE: "Please select your state!",
    SHARE_LINK_PLEASE_INPUT_YOUR_ADDRESS_PHONE:
      "Please input your Address phone!",
    SHARE_LINK_EXISTING_KWIK_USER_LOGIN_HERE: "Existing Kwik User? Login Here",
    SHARE_LINK_STEP_ADDRESS: "Address",
    SHARE_LINK_STEP_PAYMENTS: "Payments",
    SHARE_LINK_STEP_SUMMARY: "Summary",
    SHARE_LINK_BUTTON_LOGIN: "Login",
    SHARE_LINK_BUTTON_CONTINUE: "Continue",

    // shopping
    SHOPPING_NEW_AGE_PV: "NewAge PV",
    SHOPPING_PRODUCT_DESCRIPTION: "Product Details",
    SHOPPING_REVIEWS: "Reviews",
    SHOPPING_LEAVE_REVIEW: "Leave a review",
    SHOPPING_RATE_PRODUCT: "Rate the Product",
    SHOPPING_SHARE_THOUGHTS_PRODUCT: "Share your thoughts on the product",
    SHOPPING_PLACEHOLDER_TYPING: "Start typing",
    SHOPPING_PLEASE_TYPING: "Please input Start typing",
    SHOPPING_TIPS_ADD_IMAGE: "Add an image to your review",
    SHOPPING_BUTTON_REMOVE: "Remove",
    SHOPPING_TIPS_IMAGE_UPLOAD_1: "your files to assets,or ",
    SHOPPING_TIPS_IMAGE_UPLOAD_2: "browse",
    SHOPPING_BUTTON_LEAVE_REVIEW: "Leave Review",
    SHOPPING_BUTTON_DRAG_DROP: "DRAG AND DROP",
    SHOPPING_BUTTON_CHECKOUT: "Checkout",
    SHOPPING_SHARE_LINK_TEXT_PRICE: "PRICE",
    SHOPPING_SHARE_LINK_TEXT_NEW_AGE_PV: "NewAge PV",
    SHOPPING_BUTTON_QR_CODE: "QR code",
    SHOPPING_BUTTON_COPY_LINK: "Copy Link",
    SHOPPING_BUTTON_QUICK_SHARE: "Quick Share",
    SHOPPING_BUTTON_DOWNLOAD_IMAGE: "download image",
    SHOPPING_BUTTON_X: "X",
    SHOPPING_BUTTON_CANCEL: "Cancel",
    SHOPPING_CART_TEXT_PRICE: "Price",
    SHOPPING_CART_TEXT_KWIKASH: "1Cash",
    SHOPPING_CART_TEXT_SHIP_FROM: "Ship from",
    SHOPPING_CART_TEXT_SUBTOTAL: "Subtotal",
    SHOPPING_BUTTON_CHANGE: "Change",
    SHOPPING_TITLE_COUPON: "Coupon",
    SHOPPING_OPTION_SELECT_COUPON: "Select a Coupon Available to You",
    SHOPPING_REQUIRED_INPUT_CARD_NAME: "Please input your card name!",
    SHOPPING_REQUIRED_INPUT_CARD_NUMBER: "Please input your card number!",
    SHOPPING_TEXT_EXPIRATION_DATE: "Expiration Date",
    SHOPPING_TEXT_EXPIRATION_YEAR: "Expiration Year",
    SHOPPING_REQUIRED_INPUT_CARD_CVV: "Please input your card CVV!",
    SHOPPING_REQUIRED_INPUT_FIRST_NAME: "Please input your firstName!",
    SHOPPING_REQUIRED_INPUT_LAST_NAME: "Please input your lastName!",
    SHOPPING_REQUIRED_INPUT_ADDRESS_1: "Please input your Address Line1!",
    SHOPPING_REQUIRED_INPUT_STATE: "Please select your state!",
    SHOPPING_REQUIRED_INPUT_CITY: "Please input your city!",
    SHOPPING_REQUIRED_INPUT_ZIP: "Please input your zip!",
    SHOPPING_REQUIRED_INPUT_PHONE: "Please input your Address phone!",
    SHOPPING_TEXT_SHIPPING_HANDING: "Shipping and Handling",
    SHOPPING_TEXT_SHOW_SAVED_CARD: "Show Saved Cards",
    SHOPPING_SUCCESS_TEXT_CONGRATS:
      "Congrats! Earn Kwik cash every time someone buys from your promo code! Click the Share button below.",
    SHOPPING_PAYMENT_TEXT_PAYMENT_SUCCESSFUL: "Payment Successful!",
    SHOPPING_PAYMENT_TEXT_SHARE_LINK: "SHARE YOUR LINK",
    SHOPPING_PAYMENT_TEXT_ORDER_ACCEPTED: "Order Accepted",

    SHOPPING_PASSWORD_TITLE_FORGOT: "Forgot Your Password",
    SHOPPING_PASSWORD_TEXT_VERIFY: "To verify that it is you",
    SHOPPING_PASSWORD_TEXT_ENTER_EMAIL:
      "Enter your registered email below to receive password \n reset link instructions",
    SHOPPING_PASSWORD_LABEL_EMAIL: "Email address",
    SHOPPING_PASSWORD_PLACEHOLDER_EMAIL: "Email address",
    SHOPPING_PASSWORD_REQUIRED_EMAIL: "E-mail Is required",
    SHOPPING_PASSWORD_BUTTON_RESET: "Reset Password",
    SHOPPING_PASSWORD_SEND_EMAIL: "An email has been sent!",
    SHOPPING_PASSWORD_SEND_CHECK_INBOX:
      " Please check your inbox and click the link to\n reset your password.",
    SHOPPING_PASSWORD_SEND_BACK_TO_LOGIN: "Back To Login",
    SHOPPING_PASSWORD_SEND_TEXT_DONT_RECEIVE: "Didn't receive the link?",
    SHOPPING_PASSWORD_SEND_BUTTON_RESEND: "Resend",
    SHOPPING_PASSWORD_SEND_BUTTON_BACK: "Back",
    HEADER_PHOTO_EDIT_IMAGE: "Edit image",
    HEADER_PHOTO_OK: "OK",
    HEADER_PHOTO_CANCEL: "Cancel",

    //baseHeader
    HEADER_HOME: "Home",
    HEADER_1CASH: "Rewards",
    HEADER_CAMPAIGNS: "Campaigns",
    HEADER_REWARDS: "Rewards",
    HEADER_ORDER_HISTORY: "Order History",
    HEADER_SIGN_OUT: "Sign out",
    HEADER_LOGOUT: "Logout",

    // login header
    LOGIN_HEADER_TITLE_REPORT_ISSUE: "Report an Issue",
    LOGIN_HEADER_LABEL_SUBJECT: "Subject",
    LOGIN_HEADER_PLACEHOLDER_SUBJECT: "Summarize your issue here",
    LOGIN_HEADER_LABEL_DESCRIPTION: "Description",
    LOGIN_HEADER_PLACEHOLDER_DESCRIPTION: "Type out issue in detail here...",

    // weLink Address
    WELINK_ADDRESS_WE_RE_CURRENTLY:
      "We’re Currently Focused On expanding in the following Metro areas: Las Vegas, NV; Pheonix, AZ, TUSCON, AZ. WE’LL BE BRINGING OUR WIRELESS FIBER TO EVEN MORE MARKETS IN 2022",
    WELINK_ADDRESS_FIRST_NAME: "First Name",
    WELINK_ADDRESS_LAST_NAME: "Last Name",
    WELINK_ADDRESS_COMPANY_NAME: "Company Name",
    WELINK_ADDRESS_EMAIL: "Email",
    WELINK_ADDRESS_ADDRESS: "Address",
    WELINK_ADDRESS_APT_SUITE_ETC_OPTIONAL: "Apt,suite,etc.(optional)",
    WELINK_ADDRESS_CITY: "City",
    WELINK_ADDRESS_COUNTRY: "Country",
    WELINK_ADDRESS_STATE: "State",
    WELINK_ADDRESS_ZIP_CODE: "Zip Code",
    WELINK_ADDRESS_PHONE: "Phone",
    WELINK_ADDRESS_HOW_MANY_W2_EMPLOYEES_DO_YOU_HAVE:
      "How many w2 Employees do you have?",
    WELINK_ADDRESS_HAS_YOUR_BUSINESS_BEEN:
      "Has your business been impacted or changed by COVID?",
    WELINK_ADDRESS_HAS_YOUR_BUSINESS_HAD:
      "Has your business had a supply chain 'disruption' to your business?",
    WELINK_ADDRESS_PROCEED_TO_NEXT: "Proceed to Next",
    //12.1
    FIELD_PROVINCE: "Province",
    FIELD_TOWN_CITY: "Town/City",
    FIELD_POSTAL_CODE: "Postalcode",
    // ssonewage
    SSONEWAGE_IF_YOU_ALREADY_SET_UP_MESSAGE:
      'If you already set up your Kwik account by using "Sign in with NewAge", you can also log in the Kwik App with your Kwik email and password by <a href="/">clicking here</a>.',
    SSONEWAGE_SIGN_IN_WITH_NEWAGE: "Sign in with NewAge",
    SSONEWAGE_LINK_AN_EXISTING_ACCOUNT_WITH_NEWAGE_ACCOUNT:
      "Link an existing account with NewAge Account",
    SSONEWAGE_WELCOME_BACK: "Welcome Back!",
    SSONEWAGE_ENTER_YOUR_CREDENTIALS_TO_CONTINUE:
      "Enter your credentials to continue",
    SSONEWAGE_EMAIL: "Email",
    SSONEWAGE_USERNAME: "Username",
    SSONEWAGE_USERNAME_IS_REQUIRED: "Username is required",
    SSONEWAGE_PASSWORD: "Password",
    SSONEWAGE_NEXT: "Next",
    SSONEWAGE_PLEASE_VERIFY_KWIK_ACCOUNT_INFOMATION:
      "Please verify Kwik account Infomation",
    SSONEWAGE_KWIK_ACCOUNT_EMAIL: "Kwik Account Email",
    SSONEWAGE_FIRST_NAME: "First Name",
    SSONEWAGE_LAST_NAME: "Last Name",
    SSONEWAGE_PHONE_NUMBER: "Phone Number",
    SSONEWAGE_NEWAGE_ID: "NewAge ID",
    SSONEWAGE_YOUR_KWIK_ACCOUNT_HAS_ALREADY_MESSAGE:
      "Your Kwik account has already been linked to this NewAge ID. If this is your correct NewAge ID, no more action needed.",
    SSONEWAGE_IF_THIS_IS_NOT_MESSAGE:
      "If this is not your correct NewAge ID, You can update it.",
    SSONEWAGE_UPDATE: "Update",
    SSONEWAGE_IS_THIS_YOU: "Is this you?",
    SSONEWAGE_YES_AND_PROCEED_TO_NEXT_STEP: "Yes and Proceed to Next Step",
    SSONEWAGE_OUR_RECORDS_SHOW_THAT_THIS_MESSAGE:
      "Our records show that this NewAge ID {{newAgeID}} has been linked to another Kwik account. Please check your NewAge ID and try it later. Thanks!",
    SSONEWAGE_OK: "OK",
    SSONEWAGE_CONDRATULATIONS_YOUR_NEWAGE_ID_MESSAGE:
      "Condratulations! Your NewAge ID and Password have been verified. Could you like to link this NewAge ID with your Kwik Account?",
    SSONEWAGE_YES: "Yes",
    SSONEWAGE_NO: "No",
    SSONEWAGE_OUR_RECORDS_SHOW_THAT_MESSAGE:
      "Our records show that this Username has already been registered with Kwik.",
    SSONEWAGE_OUR_RECORDS_SHOW_THAT_THIS_EMAIL_MESSAGE:
      "Our records show that this email has already been registered with WER1.",
    SSONEWAGE_FORGOT_PASSWORD: "Forgot Password",

    FIELD_DUTY: "Duty",
    SHARE_LINK_BTGIN_QUALIFYING: "Begin Qualifying",

    // campaigns
    // 'SHARELINK_HAS_BEEN_CREATED_MESSAGE': 'Sharelink has been created, please click OK to share.'

    // Kwikcash Pages
    KWIKCASH: "1Cash",
    CONNECTED: "Connected!",
    ADD_BANK_ACCOUNT: "Add a Bank Account",
    CONNECTED_YOUR_ACCOUNT: "We've connected your account with",
    KWIKCASH_SUCCESSFULLY_ADDED_1: "You have successfully added this",
    KWIKCASH_SUCCESSFULLY_ADDED_2:
      "to your account! Now, we need to confirm that you own this bank account. Here is how we do that",
    KWIKCASH_PROCESSING_STEP_1:
      "Over the next 1 - 3 business days, we will make two small deposits into your account. Amounts will not be over $1.",
    KWIKCASH_PROCESSING_STEP_2:
      "Once you see these deposists in your bank account, enter those amounts on the next page",
    KWIKCASH_PROCESSING_STEP_3: "Start cashing out!",
    COMPLETE: "Complete",
    ENTER_AMOUNT: "Enter Amount",
    TRANSFER_AMOUNT_DESC:
      "Choose the amount of money you would like to transfer from your kwikcash to your selected bank account.",
    KWIKCASH_SELECT_BANK_ACCOUNT: "Select Bank Account",
    KWIKCASH_AMOUNT_TO_TRANSFER: "Amount to transfer",
    KWIKCASH_ADD_ACCOUNT: "Add Account",
    KWIKCASH_PLEASE_CONFIRM: "Please confirm",
    KWIKCASH_TRANSFER_AMOUNT: "KwikCash Transfer Amount",
    KWIKCASH_TO: "to",
    KWIKCASH_PROCESSING_FEE: "Processing Fee:",
    KWIKCASH_NET_CASHOUT: "Net cash out:",
    KWIKCASH_TRANSFER: "Transfer",
    KWIKCASH_CONFIRM: "Confirm",
    KWIKCASH_CANCEL: "Cancel",
    KWIKCASH_AMOUNT: "Amount",
    KWIKCASH_BANK_NAME: "Bank Name",
    KWIKCASH_NEXT: "Next",
    KWIKCASH_TRANSFER_SUCCESS_1: "You Transaction number is",
    KWIKCASH_TRANSFER_SUCCESS_2:
      "and it has been placed. An email confirmation has been sent to",
    KWIKCASH_TRANSFER_SUCCESS_3:
      "Please allow up to 24 hours for 1Cash Balance to get updated",
    KWIKCASH_BACK_KWIKCASH: "Back to 1Cash",
    KWIKCASH_SUCCESS_HEADER: "Success!",
    KWIKCASH_PHONE_HEADER: "Phone Verification",
    KWIKCASH_PHONE_1: "Please verify that it is you",
    KWIKCASH_PHONE_2: "Enter the 4 digit code that was sent to you at",
    KWIKCASH_DIDNT_GET_CODE: "Didn't get the code?",
    KWIKCASH_RESEND_IT: "Resend it",
    KWIKCASH_THE_BANK_NAME: "The Bank Account",
    KWIKCASH_BANK_LINKED: "Has been linked to your account",
    KWIKCASH_ENTER_DEPOSIT_DESC:
      "Enter two amounts we deposited into your account",
    KWIKCASH_DEPOSIT_1: "Depost amount 1",
    KWIKCASH_DEPOSIT_2: "Depost amount 2",
    KWIKCASH_DEPOSIT_DESC:
      "It may take time for the deposits to appear on your account. Please allow 1-3 days to see deposits.",
    KWIKCASH_VERIFY: "Verify",
    KWIKCASH_BACK: "Back",
    KWIKCASH_BANK_VERIFY: "Verify Bank Account",
    KWIKCASH_HISTORY: "KwikCash History",
    KWIKCASH_NO_TRANSACTIONS: "No Recent Transactions",
    KWIKCASH_CONNECT: "Connect",
    KWIKCASH_BANK_ACCOUNTS: "Cash Out Options",
    KWIKCASH_PENDING: "pending",
    KWIKCASH_CONNECT_TO: "Connect to",
    KWIKCASH_FEATURE_TBA:
      "Sorry, this feature is still being built. Please return at a later date",
    KWIKCASH_LINKED_ACCOUNTS: "Linked Accounts",
    KWIKCASH_VALIDATION_MESSAGE:
      "Please fill out the form below. All fields are required.",
    REGISTER_IMAGE_5M: "The image must not be larger than 2M",
    ACCOUNT: "Account",
    EDIT_ACCOUNT: "Edit Account",
    ACCOUNT_SETTING: "Account Settings",
    HELP_CONTACT: "Help/Contact",
    LEGAL: "Legal",
    LANGUAGE: "Language",
    SELECT_REG: "Select your geographical region",
    SELECT_LANG: "Select your preferred language",
    HEADER_SHARING: "Sharing",
    HEADER_SHOPPING: "Shopping",
    HELP: "Help",
    HELP_SUPPORT: "Help & Support",
    TRACKING: "Tracking",
    STATUS: "Status",
    VIEW_DETAILS: "View Details",
    MY_REVIEW: "My Review",
    YOUR_STARRATING: "Your Star Rating",
    SAVE: "Save",
    DETAILED_REVIEW: "Detailed Review",
    ORDER_MORE: "Order More",
    SHARE_PRODUCT: "Share Product",
    EDIT_REVIEW: "Edit a Review",
    WRITE_REVIEW: "Write a Review",
    RETURN_PRODUCT: "Return Product",
    SHIPPING_DETAILS: "Shipping Details",
    TRACKING_NUMBER: "Tracking Number",
    ARRIVAL_DATE: "Arrival Date",
    ORDER_INFO: "Order Information",
    TOTAL_COST: "Total Cost",
    ORDER_DATE: "Order Date",
    SHIP_DATE: "Ship Date",
    SHIP_LOCATION: "Ship Location",
    CHANGE_IMAGE: "Change Image",
    PROFILE_INFO: "Profile Contact Info",
    SETTING_PROFILE_INFO:
      "Please Note: this is how people will be able to reach you from your Profile Page. Update these so people know where to reach out to you.",
    SETTING_LOGIN_EMAIL_PHONE:
      "Your login email and account phone number will still be the same.",
    SECURITY: "Security",
    DISCARD: "Discard",
    CHANGE_PASSWORD: "Change Password",
    "HELP&CONTACT": "Help & Contact",
    CONTACT_US_AT: "Contact us at",
    WHAT_TYPE_PROBLEM: "What type of Problem",
    PROBLEM_REQUIRED: "Problem is required",
    FIELD_REQUIRED: "This field is required",
    ATTACH_IMAGE: "Attach any images or screen captures",
    CONNECTED_DATE: "Connected Date",
    SAVE_ACCONT_DESC:
      "Make changes to this payment method as necessary. When you are finished, click the save button.",
    BANK_NAME_REQUIRED: "Bank Name is required",
    ROUTING_REQUIRED: "Routing Number is required",
    ACCOUNT_NUMBER_REQUIRED: "Account Number is required",
    ACCOUNT_TYPE_REQUIRED: "Account Type is required",
    ACCONT_CONFIRM:
      "Changes to this type of account may require you to confirm the account. This will be done by entering the amounts of two small deposits into the account.",
    REMOVE_METHOD: "Remove Method",
    LEGAL_INFO: "Legal Information",
    /*---------------vendor moudle--------------------*/
    // vendor campaigns
    VENDOR_ALL_VENDORS: "All Vendors",
    VENDOR_CAMPAIGNS: "Campaigns",
    VENDOR_CAMPAIGN_LOGO: "Campaign Logo",
    VENDOR_CAMPAIGN_NAME: "Campaign Name",
    VENDOR_MARKET: "Market",
    VENDOR_CURRENCY: "Currency",
    VENDOR_START_DATE: "Start Date",
    VENDOR_END_DATE: "End Date",
    VENDOR_ACTIONS: "Actions",
    VENDOR_EDIT: "Edit",
    VENDOR_DELETE: "Delete",
    VENDOR_BACK_TO_CAMP_LIST: "Back to Campaigns List",
    VENDOR_CAMP_DETAIL: "Campaigns Detail",
    VENDOR_UPDATE: "Update",
    VENDOR_SAVE: "Save",
    VENDOR_CANCEL: "Cancel",
    VENDOR_PLS_INPUT_CAMP_NAME: "Please input Campaign Name!",
    VENDOR_PLS_SELECT_ONE: "Please select one!",
    VENDOR_PRTS: "Purchase Req'd to Share",
    VENDOR_NPRTS: "No Purchase Req'd to Share",
    VENDOR_FREE_TO_SHARE: "None-purchasable Item, Free to Share",
    VENDOR_CAMP_IMAGE: "Campaign Image",
    VENDOR_DRAG_AND_DROP: "DRAG AND DROP",
    VENDOR_YFTA: "your files to assets, or",
    VENDOR_BROWSE: "browse",
    VENDOR_IMAGE: "Image",
    VENDOR_PRODUCT_NAME: "Product Name",
    VENDOR_AC_NAME: "Actions/Campaign Name",
    VENDOR_TPHBATTC: "This product has been assigned to the campaign",
    VENDOR_AYSYWT_DELETE: "Are you sure you want to delete?",
    VENDOR_YCLTC_TIPS:
      "You cannot link the campaign to the product as they belong to different vendors.",
    VENDOR_SEARCH_TIPS: "Search for product, customer, etc..",
    VENDOR_PRODUCT_ID: "Product ID",
    VENDOR_COUNTRY: "Country",
    VENDOR_REMOVE_FROM_CAMP: "remove from campaigns",

    // vendor customer
    VENDOR_CUSTOMERS: "Customers",
    VENDOR_HEAD: "Head",
    VENDOR_USER_NAME: "User Name",
    VENDOR_USER_ID: "User ID",
    VENDOR_EMAIL_ADDRESS: "Email Address",
    VENDOR_PHONE_NUMBER: "Phone Number",
    VENDOR_BACK_TO_CUSTOMER_LIST: "Back to Customer List",
    VENDOR_USER_PROFILE: "User Profile",
    VENDOR_UPDATE_PROFILE: "Update Profile",
    VENDOR_FIRST_ROMAN_NAME: "First Roman Name",
    VENDOR_LAST_ROMAN_NAME: "Last Roman Name",
    VENDOR_FIRST_NATIVE_NAME: "First Native Name",
    VENDOR_LAST_NATIVE_NAME: "Last Native Name",
    VENDOR_ADD_LINE_1: "Address Line 1",
    VENDOR_ADD_LINE_2: "Address Line 2",
    VENDOR_CITY: "City",
    VENDOR_STATE: "State",
    VENDOR_ZIP_CODE: "Zipcode",
    VENDOR_BIO: "Bio",
    VENDOR_BIO_TIPS:
      "Make it short and simple. Describe yourself to the world. This will be your bio for everyone to see.",
    VENDOR_STATUS: "Status",
    VENDOR_ORDER_HISTORY: "Order History",
    VENDOR_ORDERS: "Orders",
    VENDOR_DATE: "Date",
    VENDOR_CUSTOMER: "Customer",
    VENDOR_PAYMENT_STATUS: "Payment Status",
    VENDOR_FULFILLMENT_STATUS: "Fulfillment Status",
    VENDOR_PRICE: "Price",

    // vendor order
    VENDOR_ALL_ORDERS: "All Orders",
    VENDOR_OPERATE: "Operate",
    VENDOR_EXPORT: "Export",
    VENDOR_ORDER_ID: "Order ID",
    VENDOR_MARK_AS_FULFILLED: "Mark as Fulfilled",
    VENDOR_ALL_STATUS: "All Status",
    VENDOR_PLS_SELECT: "Please select",
    VENDOR_START_TIME_TO_END_TIME: "Start Date - End Date",
    VENDOR_PLS_INPUT_STTET: "Please input Start Date - End Date!",
    VENDOR_TRACKING: "Tracking",
    VENDOR_REFUNDED: "Refunded",

    //vendor staff
    VENDOR_STAFF_DELETE_TIPS: "Are you sure you want to delete?",
    VENDOR_STAFF: "Staff",
    VENDOR_ADD_STAFF: "Add Staff",
    VENDOR_FULL_NAME: "Full Name",
    VENDOR_DEPARTMENT: "Department",
    VENDOR_ROLE: "Role",
    VENDOR_ADD: "Add",
    VENDOR_PFTFB: "Please fill the form bellow.",
    VENDOR_PWD: "Password",
    VENDOR_PSYR: "Please select your Role!",
    VENDOR_PIYUI: "Please input your User ID!",
    VENDOR_PIYUN: "Please input your User Name!",
    VENDOR_PIYP: "Please input your Password!",
    VENDOR_PIYE: "Please input your Email!",

    //vendor login
    VENDOR_BSE: "Buy. Share. Earn",
    VENDOR_BSE_DESC:
      "Kwikclick is a new marketplace platform that connects brands to their biggest fans, Creating a powerful word of mouth salesforce.",
    VENDOR_PETIMBTAYA:
      "Please enter the info mention below to access your account.",
    VENDOR_PIYEOU: "Please input your Email or Username!",
    VENDOR_KEEP_SIGN_IN: "Keep Signed In",
    VENDOR_DONT_HAVE_ACCOUNT: "Don't have an account yet?",
    VENDOR_CREATE_ONE: "Create one",
    VENDOR_PPTREATRP:
      "Please provide the registered email address to reset password.",
    VENDOR_ALREADY_KNOW_PASSWORD: "Already know password",
    VENDOR_GO_TO_SIGNIN: "Go to Sign in",
    VENDOR_PCYI_CTLTRYP:
      "Please check your inbox and click the link to reset your password.",
    VENDOR_DIDNOT_RECEIVE_THE_LINK: "Didn't receive the link?",
    VENDOR_CHOOSE_NEW_PWD: "Choose a new password",
    VENDOR_EAUPTCYNP: "Enter a unique password to create your new password",
    VENDOR_NEW_PWD: "New Password",
    VENDOR_PIYCP: "Please input your confirm password!",
    VENDOR_SUCESS: "Success",
    VENDOR_YAHBC_PL: "Your account has been created.Please login",
    VENDOR_CYBAN: "Create your business account now.",
    VENDOR_PPUWSITCYA:
      "Please provide us with some information to create your account.",
    VENDOR_BUSINESS_NAME: "Business Name",
    VENDOR_PIBN: "Please input Business Name",
    VENDOR_BPN: "Business Phone Number",
    VENDOR_PIPN: "Please input Phone number",
    VENDOR_EAFCA: "Email Address For Company Account",
    VENDOR_PIEA: "Please input Email Address",
    VENDOR_PIP: "Please input Password",
    VENDOR_CYP: "Confirm Your Password",
    VENDOR_PICP: "Please input Confirm Password",
    VENDOR_INCONSISTENT_PWD: "Inconsistent passwords",
    VENDOR_IAGREETOTHE: "I agree to the",
    VENDOR_TERMS_CONDITIONS: "terms and conditions",
    VENDOR_PCTT_CONDITONS_AGREEMENT:
      "Please check the terms and conditions agreement!",
    VENDOR_BUSINESS_INFO: "Business Information",
    VENDOR_BUSINESS_INFO_DESC:
      "Please note that this information is not necessary at sign-up, but it is required to go live.",
    VENDOR_BUSINESS_TYPE: "Business Type",
    VENDOR_PLS_INPUT_BUSINESS_TYPE: "Please input Business Type",
    VENDOR_SSN_EIN: "SSN (Or EIN if Business)",
    VENDOR_PLS_INPUT_SSN: "Please input SSN",
    VENDOR_WHO_REFER_YOU_TO_KWIK: "Who Referred You To Kwik?",
    VENDOR_JSWCGTCTTRP: "Just so we can give the credit to the right person.",
    VENDOR_PLS_INPUT_FN: "Please input First Name",
    VENDOR_PLS_INPUT_LN: "Please input Last Name",
    VENDOR_PLS_INPUT_EA: "Please input Email Address",
    VENDOR_BRAND_INDUSTRY: "Brand Industry",
    VENDOR_PLS_INPUT_BRAND_INDUSTRY: "Please select Brand Industry",
    VENDOR_CONTACT_INFO: "Contact Information",
    VENDOR_CONTACT_INFO_DESC:
      "Your brand will be pending until it has been approval. We need someone to contact.",
    VENDOR_BUSINESS_URL: "Business URL",
    VENDOR_CR: "Company Revenue(prior year annual revenue)",
    VENDOR_PLS_INPUT_CR: "Please input Conpany Revenue",
    VENDOR_SUCCESS: "Success",
    VENDOR_REGISTER_SUCCESS_1:
      "Thank you for filling out the brand profile information.",
    VENDOR_REGISTER_SUCCESS_2:
      "A member of our Brands Team will be reaching out to you soon to gather more information and to assist you in onboarding your products to Kwik!",
    VENDOR_REGISTER_SUCCESS_3: "Thank you for your patience.",
    VENDOR_REGISTER_SUCCESS_4: "Estimated Time 1-3 days",
    VENDOR_KWIK_HOME: "Kwik Home",
    VENDOR_STILL_PENDING: "Still Pending",
    VENDOR_REGISTER_PENDING_1:
      "We’re sorry to say, our Brand Management Team is very busy right now, and onboarding is taking longer than expected.",
    VENDOR_REGISTER_PENDING_2:
      "We are working our hardest to get every Brand on Kwik.",
    VENDOR_REGISTER_PENDING_3:
      "Use your brand login credentials to get in and see what users would see!",
    VENDOR_CHECK_OUT_USER: "Check out user experience",
    VENDOR_USER_USERNAME: "Username",

    // dashboard page
    DASHBOARD_HI_USER_NAME: "Hi, {{userName}}",
    DASHBOARD_ANALYTIC_REPORT: "Analytic Report",
    DASHBOARD_SEARCH_FOR_PRODUCT_PLACEHOLDER:
      "Search for product, customer, etc..",
    DASHBOARD_TOTAL_EARNINGS: "Total Earnings",
    DASHBOARD_EARNINGS_REPORT: "Earnings Report",
    DASHBOARD_HERE_IS_THE_UPDATE_OF_YOUR_EARNING:
      "Here is the update of your earning.",
    DASHBOARD_THIS_WEEK_ITEM: "This Week",
    DASHBOARD_THIS_MONTH_ITEM: "This Month",
    DASHBOARD_THIS_YEAR_ITEM: "This year",
    DASHBOARD_YOUR_TOTAL_EARNINGS: "Your total earnings",
    DASHBOARD_GET_ALL_THE_EARNING_MESSAGE:
      "Get all the earning of the week, month, year at one place.",
    DASHBOARD_REVENUE: "Revenue",
    DASHBOARD_USERS: "Users",
    DASHBOARD_ALL_USERS: "All Users",
    DASHBOARD_NEW_USERS: "New Users",
    DASHBOARD_TOP_SELLING_PRODUCTS: "Top Selling Products",
    DASHBOARD_IMAGE_TH: "Image",
    DASHBOARD_PRODUCT_NAME_TH: "Product Name",
    DASHBOARD_STATUS_TH: "Status",
    DASHBOARD_PRICE_TH: "Price",
    DASHBOARD_SALES_TH: "Sales",
    DASHBOARD_KWIKCASH_VALUE_TH: "Kwikcash Value",
    DASHBOARD_INVENTORY_IN_STOCK: "{{inventory}} in stock",
    DASHBOARD_TOP_EARNERS: "Top Earners",
    DASHBOARD_ACTIVITY: "Activity",
    DASHBOARD_PURCHASED: "purchased",
    // producs page
    PRODUCTS_PRODUCTS_TITLE: "Products",
    PRODUCTS_IMAGE_TH: "Image",
    PRODUCTS_PRODUCT_NAME_TH: "Product Name",
    PRODUCTS_PRODUCT_ID_TH: "Product Id",
    PRODUCTS_COUNTRY_TH: "Country",
    PRODUCTS_CURRENCY_TH: "Currency",
    PRODUCTS_TYPE_TH: "Type",
    PRODUCTS_PRICE_TH: "Price",
    PRODUCTS_BUDGET_TH: "Budget",
    PRODUCTS_KWIKCASH_VALUE_TH: "Kwikcash Value",
    PRODUCTS_ACTIONS_TH: "Actions",
    PRODUCTS_EDIT_BUTTON: "Edit",
    PRODUCTS_PREVIEW_BUTTON: "Preview",
    // products detail page
    PRODUCTS_DETAIL_UPDATE_PRODUCT: "Update Product",
    PRODUCTS_DETAIL_ADD_PRODUCT: "Add Product",
    PRODUCTS_DETAIL_PLEASE_FILL_THE_FORM_BELLOW: "Please fill the form bellow.",
    PRODUCTS_DETAIL_CANCEL_BUTTON: "Cancel",
    PRODUCTS_DETAIL_SAVE_PRODUCT: "Save Product",
    PRODUCTS_DETAIL_COUNTRY: "Country",
    PRODUCTS_DETAIL_PLEASE_SELECT_COUNTRY: "Please select Country!",
    PRODUCTS_DETAIL_PLEASE_SELECT_WAREHOUSE: "Please select WareHouse!",
    PRODUCTS_DETAIL_SELECT_COUNTRY: "Select Country",
    PRODUCTS_DETAIL_CURRENCY: "Currency",
    PRODUCTS_DETAIL_SELECT_CURRENCY: "Select Currency",
    PRODUCTS_DETAIL_WAREHOUSE: "WareHouse",
    PRODUCTS_DETAIL_SELECT_WAREHOUSE: "Select WareHouse",
    PRODUCTS_DETAIL_GENERAL_INFO: "General Info",
    PRODUCTS_DETAIL_PRODUCT_NAME: "Product Name",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_PRODUCT_NAME:
      "Please input your Product Name!",
    PRODUCTS_DETAIL_PRODUCT_SHORT_DESCRIPTION:
      "Product Short Description (limited to 200 characters)",
    PRODUCTS_DETAIL_ADD_PRODUCT_IMAGE: "Add Product Image",
    PRODUCTS_DETAIL_REMOVE: "Remove",
    PRODUCTS_DETAIL_DRAG_AND_DROP: "DRAG AND DROP",
    PRODUCTS_DETAIL_YOUR_FILES_TO_ASSETS:
      "your files to assets, or <span>browse</span>",
    PRODUCTS_DETAIL_ACCEPTED_FILE_TYPE: "Accepted File Type: PNG, JPEG only. ",
    PRODUCTS_DETAIL_RETURN_POLICY: "Return Policy",
    PRODUCTS_DETAIL_PLEASE_ENTER_THE_NUMBER: "Please enter the number",
    PRODUCTS_DETAIL_DAYS: "Days",
    PRODUCTS_DETAIL_ADD_PRODUCT_VIDEO: "Add Product Video",
    PRODUCTS_DETAIL_VIDEO_URL: "Video URL",
    PRODUCTS_DETAIL_PRICING: "Pricing",
    PRODUCTS_DETAIL_PRICE: "Price",
    PRODUCTS_DETAIL_COMPARE_AT_PRICE: "Compare at price (Sale Price)",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_SALE_PRICE:
      "Please input your Sale Price!",
    PRODUCTS_DETAIL_BUDGET: "Budget",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_BUDGET: "Please input your Budget!",
    PRODUCTS_DETAIL_FULL_KWIK_CASH: "Full Kwik Cash",
    PRODUCTS_DETAIL_1ST_WAVE_COMMISSION_RATE: "1st Wave Commission Rate",
    PRODUCTS_DETAIL_VAT_RATE: "Vat Rate",
    PRODUCTS_DETAIL_TAX_RATE: "Tax Rate",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_VAT_RATE: "Please input your Vat Rate!",
    PRODUCTS_DETAIL_VAT_INCLUSIVE: "Vat Inclusive",
    PRODUCTS_DETAIL_TAX_INCLUSIVE: "Tax Inclusive",
    PRODUCTS_DETAIL_TAX_CATEGORY: "Tax Category",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_TAX_CATEGORY:
      "Please input your Tax Category!",
    PRODUCTS_DETAIL_DUTY_RATE: "Duty Rate",
    PROUDCTS_DETAIL_DUTY_INCLUSIVE: "Duty Inclusive",
    PRODUCTS_DETAIL_NEWAGE_CAMPAIGN: "NewAge Campaign",
    PRODUCTS_DETAIL_FULFILED_BY_NEWAGE: "Fulfilled by NewAge",
    PRODUCTS_DETAIL_PV: "PV",
    PRODUCTS_DETAIL_INVENTORY: "Inventory",
    PRODUCTS_DETAIL_SKU_STOCK_KEPING_UNIT: "SKU (Stock Keeping Unit)",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_SKU: "Please input your SKU!",
    PRODUCTS_DETAIL_QUANTITY: "Quantity",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_INVENTORY: "Please input your inventory!",
    PRODUCTS_DETAIL_SHIPPING: "Shipping",
    PRODUCTS_DETAIL_WEIGHT: "Weight",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_WEIGHT: "Please input your Weight!",
    PRODUCTS_DETAIL_CANADA_NFR_LIMITS: "Canada NFR Limits",
    PRODUCTS_DETAIL_PLEASE_INPUT_YOUR_NFR_LIMITS:
      "Please input your NFR limits!",
    PRODUCTS_DETAIL_PRODUCT_STATUS: "Product Status",
    PRODUCTS_DETAIL_SCHEDULE_LAUNCH_TIME: "Schedule Launch Time",
    PRODUCTS_DETAIL_PRODUCT_LONG_DESCRIPTION: "Product Long Description",
    PRODUCTS_DETAIL_ORGANIZATION: "Organization",
    PRODUCTS_DETAIL_PRODUCT_CATEGORY: "Product Category",
    PRODUCTS_DETAIL_PLEASE_SELECT_YOUR_PRODUCT_CATEGORY:
      "Please select your Product Category!",
    PRODUCTS_DETAIL_PLEASE_SELECT: "Please select",
    PRODUCTS_DETAIL_SUBCATEGORY: "Subcategory",
    PRODUCTS_DETAIL_PLEASE_SELECT_YOUR_SUBCATEGORY:
      "Please select your Subcategory!",
    PRODUCTS_DETAIL_VARIANTS_INFO: "Variants Info",
    PRODUCTS_DETAIL_VARIANT_NAME: "Variant Name",
    PRODUCTS_DETAIL_ADD_OPTION: "Add Option",
    PRODUCTS_DETAIL_ADD_VARIANT: "Add Variant",
    PRODUCTS_DETAIL_PLEASE_FILL_THE_FORM_BELLOW: "Please fill the form bellow.",
    PRODUCTS_DETAIL_VARIANTS_TH: "Variants",
    PRODUCTS_DETAIL_IMAGE_URL_TH1: "Image1",
    PRODUCTS_DETAIL_IMAGE_URL_TH2: "Image2",
    PRODUCTS_DETAIL_IMAGE_URL_TH3: "Image3",
    PRODUCTS_DETAIL_IMAGE_URL_TH4: "Image4",
    PRODUCTS_DETAIL_DETELE_TH: "Detele",
    PRODUCTS_DETAIL_DELETE_IT_TH: "Delete it?",
    PRODUCTS_DETAIL_SKU_TH: "SKU",
    PRODUCTS_DETAIL_PRICE_TH: "PRICE",
    PRODUCTS_DETAIL_COMPARE_TO_TH: "COMPARE_TO",
    PRODUCTS_DETAIL_POINTS_TH: "POINTS",
    PRODUCTS_DETAIL_BUDGET_TH: "BUDGET",
    PRODUCTS_DETAIL_KCASH_TH: "KCASH",
    PRODUCTS_DETAIL_INVENTORY_TH: "INVENTORY",
    PRODUCTS_DETAIL_START_DATE_TH: "START_DATE",
    PRODUCTS_DETAIL_END_DATE_TH: "END_DATE",
    PRODUCTS_DETAIL_STATUS_TH: "STATUS",
    PRODUCTS_DETAIL_ACTIVE: "Active",
    PRODUCTS_DETAIL_HIDDEN: "Hidden",
    PRODUCTS_DETAIL_WEIGHT_TH: "WEIGHT",
    // warehouse page
    WAREHOUSE_PRODUCTS: "Products",
    WAREHOUSE_WAREHOUSE: "Warehouse",
    WAREHOUSE_ADD_WAREHOUSE_BUTTON: "Add Warehouse",
    WAREHOUSE_NAME_TH: "Name",
    WAREHOUSE_COUNTRY_TH: "Country",
    WAREHOUSE_ADDRESS1_TH: "Address 1",
    WAREHOUSE_ADDRESS2_TH: "Address 2",
    WAREHOUSE_CITY_TH: "City",
    WAREHOUSE_STATE_TH: "State",
    WAREHOUSE_POSTAL_CODE_TH: "Postal Code",
    WAREHOUSE_CONTACT_TH: "Contact",
    WAREHOUSE_PHONE_TH: "Phone",
    WAREHOUSE_SHIPPING_VENDOR_TH: "Shipping Vendor",
    WAREHOUSE_ACTIONS_TH: "Actions",
    WAREHOUSE_EDIT_BUTTON: "Edit",
    // warehouse detail page
    WAREHOUSE_DETAIL_BACK_TO_WAREHOUSE_LIST: "Back to Warehouse List",
    WAREHOUSE_DETAIL_WAREHOUSE_DETAIL: "Warehouse Detail",
    WAREHOUSE_DETAIL_UPDATE: "Update",
    WAREHOUSE_DETAIL_SAVE: "Save",
    WAREHOUSE_DETAIL_CANCEL: "Cancel",
    WAREHOUSE_DETAIL_FORM_NAME: "Name",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_NAME: "Please input Name!",
    WAREHOUSE_DETAIL_FORM_COUNTRY: "Country",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_COUNTRY: "Please input Country!",
    WAREHOUSE_DETAIL_FORM_SELECT_COUNTRY: "Select Country",
    WAREHOUSE_DETAIL_FORM_PLEASE_SELECT: "Please select",
    WAREHOUSE_DETAIL_FORM_ADDRESS1: "Address 1",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_ADDR1: "Please input addr1!",
    WAREHOUSE_DETAIL_FORM_ADDRESS2: "Address 2",
    WAREHOUSE_DETAIL_FORM_CITY: "City",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_CITY: "Please input city!",
    WAREHOUSE_DETAIL_FORM_STATE: "State",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_STATE: "Please input state!",
    WAREHOUSE_DETAIL_FORM_POSTAL_CODE: "Postal Code",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_POSTAL_CODE: "Please input Postal Code!",
    WAREHOUSE_DETAIL_FORM_CONTACT: "Contact",
    WAREHOUSE_DETAIL_FORM_PHONE: "Phone",
    WAREHOUSE_DETAIL_FORM_PLEASE_INPUT_PHONE: "Please input Phone!",
    WAREHOUSE_DETAIL_FORM_SHIPPING_VENDOR: "Shipping Vendor",
    // inventory page
    INVENTORY_PRODUCTS: "Products",
    INVENTORY_INVENTORY: "Inventory",
    INVENTORY_IMAGE: "Image",
    INVENTORY_PRODUCT_NAME: "Product Name",
    INVENTORY_SUPPLIER: "Supplier",
    INVENTORY_SKU: "SKU#",
    INVENTORY_QTY_ALLOTED: "Qty Allotted",
    INVENTORY_QTY_SOLD: "Qty Sold",
    INVENTORY_QTY_ON_HAND: "Qty on-hand",
    INVENTORY_SALES: "Sales",
    // sidebar component
    SIDEBAR_MENU: "Menu",
    SIDEBAR_OVERVIEW: "Overview",
    SIDEBAR_PRODUCTS: "Products",
    SIDEBAR_WAREHOUSE: "Warehouse",
    SIDEBAR_INVENTORY: "Inventory",
    SIDEBAR_ADD_PRODUCT: "Add Product",
    SIDEBAR_GRAPH_VIEW: "Graph View",
    SIDEBAR_CAMPAIGNS: "Campaigns",
    SIDEBAR_CREATE_EDIT_CAMPAIGNS: "Create/Edit Campaigns",
    SIDEBAR_MAP_CAMPAIGNS_PRODUCTS: "Map Campaigns/Products",
    SIDEBAR_CUSTOMERS: "Customers",
    SIDEBAR_ORDERS: "Orders",
    SIDEBAR_AMBASSADORS: "Ambassadors",
    SIDEBAR_PAYOUTS: "Payouts",
    SIDEBAR_STAFF_MANAGEMENT: "Staff Management",
    SIDEBAR_SETTINGS: "Settings",
    SIDEBAR_SIGNOUT: "Signout",
    // settings page
    SETTINGS_GENERAL_STORE_DETAILS: "Store details",
    SETTINGS_GENERAL_STORE_ADDRESS: "Store Address",
    SETTINGS_GENERAL_YOUR_CUSTOMERS_WILL_MESSAGE:
      "Your customers will use this information to contact you.",
    SETTINGS_GENERAL_STORE_NAME: "Store Name",
    SETTINGS_GENERAL_CONTACT_EMAIL: "Contact Email",
    SETTINGS_GENERAL_ADD_LOGO: "Add Logo",
    SETTINGS_GENERAL_DRAG_AND_DROP: "DRAG AND DROP",
    SETTINGS_GENERAL_YOUR_FILES_TO_ASSETS: "<span>Browse</span>",
    SETTINGS_ACCEPTED_FILE_TYPE: "Accepted File Type PNG, JPEG only",
    SETTINGS_ACCEPTED_ADD_HERO_BANNER: "Add Hero Banner",
    SETTINGS_ACCEPTED_REMOVE: "Remove",
    SETTINGS_GENERAL_UPLOAD_TERMS_AND_CONDITIONS: "Upload Terms and Conditions",
    SETTINGS_GENERAL_ENTER_YOUR_TEXT: "Enter your text",
    SETTINGS_GENERAL_STORE_INFORMATION: "Store Information",
    SETTINGS_GENERAL_SHARE_BIO_LIMIT_CHARACTERS:
      "Share Bio (Limit 120 characters)",
    SETTINGS_GENERAL_COPY_AND_PASTE_YOUR_MESSAGE:
      "Copy and Paste your terms and conditions here",
    SETTINGS_GENERAL_ENTER_URL_S: "Enter Url's",
    SETTINGS_GENERAL_WEBSITE_URL: "Website URL",
    SETTINGS_GENERAL_FACEBOOK_URL: "Facebook URL",
    SETTINGS_GENERAL_INSTAGRAM_URL: "Instagram URL",
    SETTINGS_GENERAL_TWITTER_URL: "Twitter URL",
    SETTINGS_GENERAL_UPLOAD_RETURN_POLICY_HERE: "Upload Return Policy Here",
    SETTINGS_GENERAL_UPLOAD_SHIPPING_POLICY_HERE: "Upload Shipping Policy Here",
    SETTINGS_GENERAL_ACTIVE_STATUS: "Active Status",
    SETTINGS_GENERAL_PENDING: "Pending",
    SETTINGS_GENERAL_APPROVED: "Approved",
    SETTINGS_GENERAL_LIVE: "Live",
    SETTINGS_GENERAL_DISCARD: "Discard",
    SETTINGS_GENERAL_SAVE: "Save",
    SETTINGS_PROFILE_SAVE_PROFILE: "Save Profile",
    SETTINGS_PROFILE_PREVIEW: "Preview",
    SETTINGS_BANKINFO_CONNECT_BANK_ACCOUNT: "Connect Bank Account",
    SETTINGS_BANKINFO_ACCEPT_PAYMENTS_ON_YOUT_MESSAGE:
      "Accept payments on your store using third-party providers such as PayPal or other payment methods",
    SETTINGS_BANKINFO_ADD_BANK_ACCOUNT: "Add Bank Account",
    SETTINGS_BANKINFO_EDIT: "Edit",
    SETTINGS_BANKINFO_MANUAL_VERIFICATION: "Manual Verification",
    SETTINGS_BANKINFO_AT_KWIK_YOUR_SECURITY_MESSAGE:
      "At Kwik your security is very important to us so we are going to verfy your bank account by putting in two very small deposits into your account and please enter in those amounts when they appear on your account",
    SETTINGS_BANKINFO_GO_BACK: "Go Back",
    SETTINGS_BANKINFO_CONTINUE: "Continue",
    SETTINGS_BANKINFO_PLEASE_VERIFY_THE_MESSAGE:
      "Please Verify the Small Deposits we have placed on your accounts",
    SETTINGS_BANKINFO_CHARGE1: "Charge 1",
    SETTINGS_BANKINFO_ENTER_AMOUNT: "Enter Amount",
    SETTINGS_BANKINFO_CHARGE2: "Charge 2",
    SETTINGS_BANKINFO_TO_VERIFY_YOUR_MESSAGE:
      "To verify your bank account, you will see two deposits and two withdrawals on your bank statement over the next two to three days. Each will be less than a dollar",
    SETTINGS_BANKINFO_PLEASE_MAKE_SURE_MESSAGE:
      "Please make sure there is a balance of at least $2 to prevent overdraft fees",
    SETTINGS_BANKINFO_BANK_ROUTING_NUMBER: "Bank Routing Number",
    SETTINGS_BANKINFO_ROUTING_NUMBER: "Routing Number",
    SETTINGS_BANKINFO_ACCOUNT_NUMBER: "Account Number",
    SETTINGS_BANKINFO_CONFIRM_ACCOUNT_NUMBER: "Confirm Account Number",
    SETTINGS_BANKINFO_ACCOUNT_NAME: "Account Name",
    SETTINGS_BANKINFO_ENTER_ACCOUNT_NAME: "Enter Account Name",
    SETTINGS_BANKINFO_SET_YOUR_SHIPPING_RATES: "Set Your Shipping Rates",
    SETTINGS_BANKINFO_SET_YOUR_VARIOUS_SHIPPING_RATES:
      "Set Your Various Shipping Rates",
    SETTINGS_BANKINFO_ADD_RATE: "Add Rate",
    SETTINGS_BANKINFO_ADD_A_NEW_SHIPPING_RATES: "Add A New Shipping Rates",
    SETTINGS_BANKINFO_PRICING: "Pricing",
    SETTINGS_BANKINFO_ADD_CONDITIONS: "Add Conditions",
    SETTINGS_BANKINFO_BASED_ON_ITEM_WEIGHT: "Based on item weight",
    SETTINGS_BANKINFO_BASED_ON_ORDER_PRICE: "Based on order price",
    SETTINGS_BANKINFO_NUMBER_OF_UNITS: "Number of units",
    SETTINGS_BANKINFO_SAVE_RATE: "Save Rate",
    SETTINGS_BANKINFO_CANCEL: "Cancel",
    SETTINGS_SHIPPINGCOST_SHOW_ALL: "Show All",
    SETTINGS_SHIPPINGCOST_ADD_SHIPPING_FORMULA: "Add Shipping Formula",
    SETTINGS_SHIPPINGCOST_EDIT_SHIPPING_FORMULA: "Edit Shipping Formula",
    SETTINGS_SHIPPINGCOST_SAVE: "Save",
    SETTINGS_SHIPPINGCOST_SKU_ID: "SKU ID",
    SETTINGS_SHIPPINGCOST_PRODUCT_NAME: "Product Name",
    SETTINGS_SHIPPINGCOST_DELETE: "Delete This Formula",
    SETTINGS_SHIPPINGCOST_BY_PRICE: "By Price",
    SETTINGS_SHIPPINGCOST_BY_WEIGHT: "By Weight",
    SETTINGS_SHIPPINGCOST_BY_COUNT: "By count",
    SETTINGS_SHIPPINGCOST_FROM_PRICE: "From Price",
    SETTINGS_SHIPPINGCOST_TO_PRICE: "To Price",
    SETTINGS_COUNTRY_CODE: "Country Code",
    SETTINGS_VAT_ID: "VAT ID",
    SHIPPINGS_SHIPPINGCOST_FROM_LBS: "From lbs",
    SHIPPINGS_SHIPPINGCOST_TO_LBS: "To lbs",
    SHIPPINGS_SHIPPINGCOST_FROM_COUNTS: "From Counts",
    SHIPPINGS_SHIPPINGCOST_TO_COUNTS: "To Counts",
    SHIPPINGS_SHIPPINGCOST_PLEASE_SELECT_VENDOR: "please select vendor",
    SHIPPINGS_SHIPPINGCOST_PLEASE_SELECT_SHIPPING_METHOD:
      "Please select Shipping Method",
    SHIPPINGS_SHIPPINGCOST_ITEM_ALREADY_EXISTS: "Item already exists",
    SHIPPINGS_SHIPPINGCOST_TO_PRICE_CANNOT_BE_ADDED:
      "To Price is #{{unlimited}} and new data cannot be added",
    SHIPPINGS_SHIPPINGCOST_TO_LBS_CANNOT_BE_ADDED:
      "To Lbs is #{{unlimited}} and new data cannot be added",
    SHIPPINGS_SHIPPINGCOST_TO_COUNT_CANNOT_BE_ADDED:
      "To Count is #{{unlimited}} and new data cannot be added",
    SHIPPINGS_SHIPPINGCOST_FORMULA_save_successfully:
      "Formula(s) saved successfully",
    SHIPPINGS_SHIPPINGCOST_DATA_MUST_NOT_BE_EMPTY: "Data must not be empty",
    SHIPPINGS_SHIPPINGCOST_TO_PRICE_DATA_FORMAT_ERROR:
      "To Price Data format error",
    SHIPPINGS_SHIPPINGCOST_TO_LBS_DATA_FORMAT_ERROR: "To Lbs Data format error",
    SHIPPINGS_SHIPPINGCOST_TO_COUNT_DATA_FORMAT_ERROR:
      "To Count Data format error",
    SHIPPINGS_SHIPPINGCOST_PLEASE_EDIT_UNDER_THE_CORRESPONDING_TYPE:
      "Please edit under the corresponding type",
    SHIPPINGS_SHIPPINGCOST_NUMBERS_CAN_NOT_BE_NEGATIVE:
      "numbers can not be negative",
    // checkout page
    CHECKOUT_PAGE_MY_CART: "My Cart",
    CHECKOUT_PAGE_SHIPPING_ADDRESS: "Shipping Address",
    CHECKOUT_PAGE_SAVE: "Save",
    CHECKOUT_PAGE_CHANGE: "Change",
    CHECKOUT_FIELD_NAME: "Name",
    CHECKOUT_FIELD_ADDRESS: "Address",
    CHECKOUT_FIELD_APT_SUITE_ETC: "Apt, Suite, etc. (Optional)",
    CHECKOUT_FIELD_CITY: "City",
    CHECKOUT_FIELD_ZIP: "Zip",
    CHECKOUT_FIELD_PROVINCE: "Province",
    CHECKOUT_FIELD_STATE: "State",
    CHECKOUT_IS_BILLING_ADDRESS_DIFFERENT: "Is Billing Address Different?",
    CHECKOUT_ITEM: "item",
    CHECKOUT_ITEMS: "items",
    CHECKOUT_PLEASE_FILL_OUT_NAME_FOR_CARD: "Please fill out name for card",
    CHECKOUT_TEXT_CHECKOUT: "Checkout",
    CHECKOUT_ENTER_CREDIT_CARD: "Enter Credit Card",
    CHECKOUT_FIRST: "First",
    CHECKOUT_LAST: "Last",
    CHECKOUT_SAVE_SAVE_TO_MY_ACCOUNT: "Save card to my account",
    CHECKOUT_BY_CLICKING_CONFIRM_MESSAGE:
      'By clicking Confirm, you agree to Wer1\'s <a href="https://cms.kwik.com/Terms%26ConditionsWeR1.pdf" target="_blank"><span>Privacy Policy</span></a> and <a href="https://cms.kwik.com/PrivacyPolicyWeR1.pdf.pdf" target="_blank"><span>Terms and Conditions</span></a>',
    CHECKOUT_CONFIRM: "Confirm",
    CHECKOUT_CARD_NUMBER: "Card Number",
    CHECKOUT_CARD_MM_YY: "MM/YY",
    CHECKOUT_CARD_CVC: "CVV",
    CHECKOUT_BILLING_ADDRESS: "Billing Address",
    CHECKOUT_SAVE_ADDRESS: "Save Address",
    CHECKOUT_SAVE_SUCCESS: "Save successfully!",
    CHECKOUT_SAVE_FAILED:
      "Please fill out the form below. All fileds are required",

    //sharing
    SHARING_PRO_YOU_CAN_SHARE: "Products You Can Share",
    SHARING_UWTTEE: "Use Waves Tech to Earn Exponentially",
    SHARING_UWTTEE_DESC:
      "The to harness your true potential to earn online and maximize your true influence. Grab a link from below and test out the Kwik Waves Technology and get paid for your true influence.",
    SHARING_CLOSE: "Close",
    SHARING_FIVE_TIMES: "You will only see this 5 more times",
    SHARING_SHARE_FULL_CAMP: "Share Full Campaign",
    SHARING_SINGLE_PRO: "Single Product Sharing",
    SHARING_MOST_SHARED: "Most Shared Link From",

    //All product
    ALL_PRO_POLICIES: "Policies",

    //sharelink success
    SS_THANK_YOU: "Thank you",
    SS_SHARE_TIPS:
      "Love your purchase? Great! Why not get rewarded for sharing this product with a friend!",
    SS_SHARE_TIPS_DESC:
      "Create an account and start sharing your Kwik links! Your link tracks purchases made by friends and family, and referral commissions are deposited directly into your Kwik account.",
    SS_CUSTOMER_INFO: "Customer Information",
    SS_ADDRESS_INFO: "Address Information",
    SS_CHECK_YOUR_EARNING: "Check your earnings!",
    SS_WHMIETSYA: "We have made it easy to setup your account!",
    SS_RETURN_HOME: "Return Home",
    SS_CREATE_HOME: "Create Account",
    SS_NEWAGE_PV: "NewAge PV",
    SS_FOOTER_HEAR_YOU: "We would love to hear from you!",
    SS_FOOTER_YOUR_FEEDBACK: "We would love your feedback!",
    SS_FOOTER_MESSAGE_US: "Message us at.",
    SS_FOOTER_EMAIL: "support@wer1.com",
    SS_FOOTER_MAIL_TO: "mailto:support@wer1.com",
    SS_FOOTER_HELLO_EMAIL: "hello@wer1.com",
    SS_FOOTER_PPTOU: "Privacy Policy | Terms of Use",
    SS_FOOTER_COPY_RESERVED: "Copyright. ©2023 All right reserved",
    SS_FOOTER_HOME: "Home",
    SS_FOOTER_HOW_IT_WORKS: "How it Works",
    SS_FOOTER_FOR_BRANDS: "For Brands",
    SS_FOOTER_WHAT_NEW: "What's New",
    SS_FOOTER_HELP_SUPPORT: "Help & Support",
    SS_FOOTER_PRIVACY_POLICY: "Privacy Policy",
    SS_TERMS_OF_USE: "Terms of Use",
    SS_FOOTER_SUB_FOR_UPDATES: "Subscribe for updates",
    SS_FOOTER_FOLLOW_US: "FOLLOW US",

    //sharelink register
    SS_SUCESS: "Sharing Success",
    SS_REGISTER_YSSYP:
      "You successfully shared your product. Time to create an account to manage your earnings!",
    SS_REGISTER_BSUFAK:
      "By signing up for a Kwik account I agree to all Terms and conditions set forward.",
    SS_REGISTER_YAAD: "You’re almost done!",
    SS_REGISTER_WHSAVCT:
      "We have sent a verification code to your email address you used in the checkout process. If you need another code please click resend.",

    LEGAL_INFO: "Legal Information",
    LEGAL_WELCOME_TO_KWIK: "Welcome to WeR1!",
    LEGAL_TC: "Terms & Conditions",
    LEGAL_CONTENT: `
    <div class="tab-content">
    <div class="title">
     Welcome to&nbsp;Kwik!&nbsp;&nbsp;
    </div>
    <p class="description">Kwik is a unique marketplace where you can sell, buy, share, and earn through your online influence. We want all who visit our site and use our Products to have a good experience. Our Products include our website, our mobile app,&nbsp;etc.</p>
    <p class="description">This is a legally binding contract between you and Kwik. By using Kwik Products, you agree to the following Terms and Conditions—our rules for helping everyone have the best experience possible. If you do not agree with these terms, please do not use Kwik Products.&nbsp;&nbsp;</p>
    <p class="description">Be aware that these Terms and Conditions do not create any partnership, joint venture, employment, or franchise relationship between you and Kwik.</p>
    <p class="description">If you are interested in Selling through&nbsp;Kwik, you must also&nbsp;understand and agree to&nbsp;our&nbsp;Seller Policy</p>
    <div class="sub-item">
     <div class="sub-title">
      Your&nbsp;Account
     </div>
     <p>To use our Products, you will need to create an&nbsp;account.&nbsp;Click&nbsp;here&nbsp;to learn how to set up an account with&nbsp;Kwik.&nbsp;By creating an account, you agree to the following terms:</p>
     <ul>
      <li>There must be only one account per person; you are responsible for your account</li>
      <li>You must be 18 years of age or older. Youth over 13 may use&nbsp;Kwik&nbsp;only through the account of a parent or legal guardian. Any use of services by a minor on your account is your responsibility. Children under 13 may not use&nbsp;our Products.</li>
      <li>Information you provide&nbsp;about yourself&nbsp;must be honest and accurate. Impersonating&nbsp;another person or company on your account&nbsp;is prohibited.</li>
      <li>You are responsible for keeping&nbsp;your account secure and protecting&nbsp;your password.</li>
     </ul>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Use of&nbsp;Kwik&nbsp;Products
     </div>
     <p>By using&nbsp;Kwik&nbsp;Products,&nbsp;you affirm that you understand and agree to the following terms. When using&nbsp;Kwik:</p>
     <ul>
      <li>Do not access data from&nbsp;Kwik&nbsp;products using robots, spiders, or any automated manner that you do not have permission to access</li>
      <li>Do not collect, copy, utilize,&nbsp;transfer, or sell,&nbsp;any data from&nbsp;Kwik&nbsp;products that is not provided to you by&nbsp;Kwik</li>
      <li>Do not upload an unauthorized link to an outside site</li>
      <li>Do not upload or aid in uploading the introduction of any malicious code or virus to&nbsp;Kwik&nbsp;Products</li>
      <li>Do not do anything to violate another’s rights, including intellectual property rights</li>
      <li>Do not impair the proper function of&nbsp;Kwik&nbsp;products</li>
      <li>Do not violate local or international laws, trademarks, rights, or intellectual property&nbsp;rights&nbsp;</li>
      <li>Follow our&nbsp;Trademark Policy</li>
      <li>Pay all fees owed to&nbsp;Kwik</li>
     </ul>
    </div>
    <p>Content</p>
    <p class="description">In using&nbsp;Kwik, you will have various opportunities to create content. Content that you produce&nbsp;includes (but&nbsp;is&nbsp;not limited&nbsp;to)&nbsp;reviews,&nbsp;comments,&nbsp;photos, profile pictures, descriptions on your profile,&nbsp;and messages to Sellers. You are responsible for&nbsp;the&nbsp;content&nbsp;that is posted or uploaded on your account.</p>
    <p class="description">To ensure a good experience for all Kwik members, there are certain types of content we do not want posted on Kwik Products. By using our Products, you agree to not&nbsp;produce content that is hateful, abusive, threatening, vulgar,&nbsp;obscene, false, misleading, or deceptive in any way.&nbsp;</p>
    <p class="description">Kwik is committed to protecting intellectual property rights. If you upload or post content that infringes upon another’s intellectual property, we will take appropriate action. This may include disabling your account temporarily or terminating your account if you repeatedly use or infringe upon other’s intellectual property. We will notify you of these changes should any of that happen. Contact Kwik if any content that you own or have rights to that has been posted without your permission.</p>
    <p class="description">If you are a Seller, see our&nbsp;Seller Policy&nbsp;for additional information on Our Standards.</p>
    <div class="sub-item">
     <div class="sub-title">
      Your&nbsp;Privacy
     </div>
     <p>We are committed to protecting your privacy and personal information.&nbsp;The following describes what personal information&nbsp;Kwik&nbsp;collects and how it is used. If you do not want&nbsp;Kwik&nbsp;to use your information in the ways described below, do not use&nbsp;Kwik&nbsp;Products.</p>
     <p>Kwik can obtain information that you voluntarily provide. To use Kwik Products and create an account, you must provide a valid email address and a name that will be associated with your account.&nbsp;&nbsp;You may choose to provide other information on your account, such as a birthday, bio, location, or gender. You can also leave reviews on products or other comments. This information will be available to other Kwik visitors and members.</p>
     <p>If you earn wave distributions&nbsp;(or Kwik Cash)&nbsp;you need to supply additional financial&nbsp;data&nbsp;(e.g.&nbsp;credit card and account numbers, transaction details, and form of payment).&nbsp;If you intend to sell through Kwik, see our&nbsp;Seller Policy&nbsp;for additional information that must be provided to Kwik to verify your identity&nbsp;and financial information for you to benefit from your sales.</p>
     <p>When you post content through Kwik Products, you grant Kwik a license to use it. We never&nbsp;claim&nbsp;the content as our own.&nbsp;We use and share&nbsp;content posted by Sellers&nbsp;to&nbsp;promote Seller products to other Sellers and Buyers based on their listings, searches, and use of Kwik Products. This permission also allows Kwik to make certain adjustments to content, such as enhancing or resizing a photo or translating content into a different language as needed.</p>
     <p>Kwik also automatically collects information when you use our Products, including our Site and our mobile App. This can include your IP address, other unique device identifier, or the version of mobile app you are using. This information can help us prevent user fraud. Kwik will also automatically collect data about your usage of the site, such as the pages you visit and how you interact with those pages (like clicking on a link or the duration of time on a page). This helps us personalize your experience and facilitate advertising based on your interests.</p>
     <p>Kwik and Sellers both collect&nbsp;buyer personal information and are both controllers of data. When you purchase an item through Kwik, you also provide information to Sellers, which can include your name, address, billing address, and payment information. Sellers must abide by standards to protect buyer’s privacy as outlined in our&nbsp;Seller Policy.&nbsp;During checkout, we may store credit card information, billing address, and other information to process your transaction and for billing and payment. Members in some countries may choose for Kwik to remember their information to make future purchases easier.</p>
     <p>Other information we obtain might be provided by you when you contact customer support.&nbsp;</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Earning Kwik Cash
     </div>
     <p>Influencers can earn Kwik Cash when they purchase a product, they share a unique link to that product on through social media, that link is clicked on to visit the product page, and a sale is made. <b>The influencer MUST buy the product and the sale must be made using the influencer’s link for the influencer to earn Kwik Cash.</b>&nbsp;As stated above, you will need to submit additional information including financial information to receive your Kwik Cash.</p>
     <p>In the United States, where Kwik operates, influencers must abide by the Federal Trade Commision (FTC) Disclosure of Material Connection [link]. This is required when the material connection (such as payment, a free product or service, or a sponsorship) would affect the credibility of the influencer’s endorsement (for example, the endorsement would only be positive because the influencer benefits) or when the audience would not expect it because there is no obvious connection to the Brand.</p>
     <p>To earn Kwik Cash, Influencers must abide by the following rules:</p>
     <ul>
      <li>The influencer must clearly disclose there is a material connection to a Seller’s products, goods, or services. Here are some examples of how you can disclose this:</li>
      <li>Disclosure: by clicking on this link and purchasing [Brand product] on Kwik, I benefit by earning Kwik Cash.</li>
      <li>Clearly visible hashtags such as #advertisement, #sponsored, #[Brand]ad, or #ad</li>
      <li>The disclosure must be within influencer’s post about the product and clearly visible.</li>
      <li>The influencer must be honest and not misleading about the product.</li>
      <li>The influencer cannot make false claims about the product, including outright falsehoods, unrepresentative testimonials (such as an effect of a product that would not apply to all i.e., “this face cream made me look five years younger”), or unsubstantiated claims (the influencer does not have adequate experience with the product to endorse it).</li>
     </ul>
     <p>If you are outside the United States, similar laws may apply. Residents of Canada should be aware of the Competition Act [link] under the Competition Bureau and its penalties for violating influencer regulations. If you are in the United Kingdom, be aware of social media influencer regulations released by the Advertising Standards Authority (ASA), Committee of Advertising Practice (CAP), and the Competition and Markets Authority (CMA).&nbsp;[links]&nbsp;You are responsible for understanding the laws of your country of residents and using your social media influence accordingly.</p>
     <p>401(k)&nbsp;Exclusion&nbsp;Policy</p>
     <p>When you earn Kwik Cash, you understand that Kwik does not and is not responsible for offering you a 401(k) plan as you are not a Kwik employee.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Disputes Policy
     </div>
     <div class="sub-title">
      Case Policy&nbsp;
     </div>
     <p>If you have an issue with a product,&nbsp;we ask that you&nbsp;contact the Seller directly first to resolve that issue.&nbsp;If the Seller does not respond or does not resolve the issue, you can open a case with&nbsp;Kwik.&nbsp;</p>
     <p>A case can be opened if the item arrives and is not as described or shown on the Seller’s shop or if it is not delivered due to the Seller’s actions. The&nbsp;Buyer must provide a photo for an item that is claimed to be not as described or as shown on the Seller’s shop. Examples might include an item(s) that is/are&nbsp;</p>
     <ul>
      <li>made of a different material or pattern that described</li>
      <li>a different size, color, model, or version</li>
      <li>in a different condition than described (i.e., is described as new but is used)</li>
      <li>the wrong quantity of items</li>
      <li>damaged&nbsp;</li>
      <li>missing pieces or parts</li>
      <li>incomplete promised services</li>
     </ul>
     <p>The Seller must ship a purchased item within a reasonable timeframe or to meet a promised delivery date. However, a&nbsp;case&nbsp;cannot&nbsp;be opened if the item is damaged, not delivered, or delivered late due to third-party shipping&nbsp;as neither the Seller nor&nbsp;Kwik&nbsp;are at fault.&nbsp;</p>
     <p>Cases will be resolved when</p>
     <ul class="numeric">
      <li>The buyer is issued a refund.</li>
      <li>The buyer closes the case.</li>
      <li>If the case was open due to non-delivery and the Seller provides evidence that the item was shipped in a timely manner to the buyer’s address.</li>
     </ul>
     <p>For more information on Seller’s responsibilities, see our&nbsp;Seller Policy.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Disputes with Others
     </div>
     <p>When an issue arises concerning a product or interaction with a Seller or other Kwik member, follow the steps above listed in our Case Policy. We will do all we can to help you resolve any issues regarding our policies. However, we are not obligated to resolve disputes you have with other Kwik members or a third party. You release Kwik from any claims, damages, or demands that arise from disputes with other members or parties.&nbsp;</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Disputes with Kwik
     </div>
     <p>Whether you are a Seller or Buyer, if you have a dispute with us, please&nbsp;contact us&nbsp;first and we will do our best to resolve the issue. If disputes cannot be resolved through our customer service, please see our policies for proceeding below.</p>
     <p class="item-title">Governing Law</p>
     <p>These Terms and Conditions are governed by the laws of the United States. If you live outside the United States, you may be protected by the mandatory consumer protections laws of your local consumer protection law.</p>
     <p>If you are experiencing an issue with us, please reach out and we will work to resolve the issue. However, if the issue cannot be resolved, the following rules will govern any legal dispute regarding Kwik and its Products.</p>
     <p class="item-title">Arbitration</p>
     <p>By using our services, you agree to resolve all disputes with Kwik through binding individual arbitration. This means disputes are handled by an arbitrator and not decided by a judge or jury. You waive any right to participate in class action lawsuits, class arbitrations, or representative actions. (Class arbitrations will only be available if requested by either party under its Class Action Arbitration Rules and approved by the arbitrator.) The arbitration will be administered&nbsp;by the American Arbitration Association, or the “AAA”, under its Consumer Arbitration Rules, or &quot;AAA Rules&quot;, then in effect, unless otherwise required by law. Each party has the right to bring an action to a court that has proper jurisdiction after the final decision of the arbitration or mediator.</p>
     <p>Instead of arbitration, you can also assert your claims in small claims court if your case qualifies. Your case must also only advance on an individual basis (non-class and non-representative). The Federal Arbitration Act (“FAA”) and governing laws will determine the interpretation and enforcement of these Terms and Conditions.&nbsp;</p>
     <p>AAA Consumer Arbitration Rules will determine payments for any reasonable AAA filing, including administrative and arbitrator fees. If your claim’s value does not exceed $10,000 USD, Kwik will pay for reasonable filing, administrative, and arbitrator fees associated with the arbitration. However, if the arbitrator determines your claim or relief sought to be frivolous or brought up for an improper purpose, Kwik will not be responsible for payment.&nbsp;</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Limitation of Liability
     </div>
     <p>When you use&nbsp;Kwik&nbsp;Products, you agree not to hold&nbsp;Kwik&nbsp;liable for the following:&nbsp;</p>
     <p class="item-title">Purchased Items&nbsp;and Use of Items&nbsp;</p>
     <p>When you purchase an item on&nbsp;Kwik&nbsp;Products, you acknowledge that you are not purchasing an item from&nbsp;Kwik. Items that are posted&nbsp;on&nbsp;and sold&nbsp;through&nbsp;Kwik&nbsp;Products are from independent Sellers.&nbsp;If you have an issue with the item, reach out to the Seller or open a Case with&nbsp;Kwik&nbsp;as described above.&nbsp;</p>
     <p>Kwik&nbsp;is not responsible for any consequences that come with the use of the item.&nbsp;If your use or misuse of an item leads to&nbsp;personal&nbsp;injury or damage to the item, you agree to not hold&nbsp;Kwik&nbsp;liable.&nbsp;&nbsp;</p>
     <p class="item-title">Third Party Websites or Services</p>
     <p>Content from Sellers and&nbsp;Kwik&nbsp;members may include links to third-party websites, services, social media pages, or other online marketplaces. You understand that these are not controlled or influenced by&nbsp;Kwik. Many of these websites and services will have their own Terms and Conditions that you agree to abide by, and it is your responsibility to be aware of them. Similarly, you access&nbsp;Kwik&nbsp;Products by using a device such as a mobile phone, and these are also not associated with&nbsp;Kwik.</p>
     <p class="item-title">Content and Interacting with Members</p>
     <p>As a&nbsp;Kwik&nbsp;member, you can share content through your profile, by leaving a review, or sending a message. We care about your experience, which is why we ask all&nbsp;Kwik&nbsp;members to abide by Our Standards. However, any content posted by a member does not reflect&nbsp;Kwik&nbsp;and we do not take ownership of any posted content.&nbsp;Kwik&nbsp;is not responsible for the legality, accuracy copyright compliance, or compliance to our standards of any content posted by members that you access through our Products. You release&nbsp;Kwik&nbsp;from all liability relating to member content.</p>
     <p>&nbsp;Kwik is also not responsible for your interaction with other individuals through Kwik. While members can open Cases in response to&nbsp;damaged or undelivered&nbsp;products, and Kwik will terminate an account when certain rules are violated (see below), understand that we do not screen users of our Products. You release Kwik from all liability relating to your interactions with other users. Use good judgement and caution in all your interactions with others on Kwik or any service. For example, it isn’t always a good idea to agree meeting someone in person that you meet online.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Indemnification&nbsp;
     </div>
     <p>In the rare event that Kwik is sued due to your actions, use or misuse of our Products, breach of these Terms or Conditions, or infringement of someone else’s rights, you agree to indemnify Kwik. This means you will defend us&nbsp;or&nbsp;hold us harmless (including employees, affiliates, and subsidiaries) from any legal claim or demand, including reasonable legal fees.&nbsp;</p>
     <p>We reserve the right to manage our legal defense as we see fit. This includes when you indemnify us, in which case you agree to cooperate with us.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Changes to User Terms and Conditions&nbsp;
     </div>
     <p>We will notify you of any substantive changes to our Terms and Conditions&nbsp;30 days&nbsp;before they become effective. By continuing to use&nbsp;Kwik&nbsp;Products, you agree with all updates. If you&nbsp;disagree with any changes to our terms, you may send&nbsp;Kwik&nbsp;a written notification or terminate your account before changes become effective.</p>
     <p class="item-title">Disputes Regarding Changes</p>
     <p>As with all changes and modifications to our Terms, you will be notified and can choose to accept the changes or reject them by terminating your account. If you reject the updated Terms and have a dispute with Kwik, you agree to arbitration of the dispute in accordance with our Disputes Policy, including any changes made prior to the rejection. If we make any modifications to the Disputes Policy after the date you last accepted the Terms including continual use of our Products after you have been notified of changes, those modifications will not apply to any claims filed in a legal proceeding against&nbsp;Kwik&nbsp;before the date the changes became effective. If you terminate your account and then re-open it, you agree to the current version of these Terms and Conditions.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Termination&nbsp;of Your Account
     </div>
     <p>Your account’s termination can happen in two ways: by us or by you. You may terminate your account any time through your account’s settings. If you terminate your account, content you have created and posted (such as comments, reviews, or uploaded photos) will remain on Kwik Products. Be aware that you may lose other information associated with your account.</p>
     <p>We may suspend or terminate your account if we have reason to believe you have violated these Terms and Conditions using&nbsp;Kwik&nbsp;Products. If we do, know that you do not have any legal or contractual right to continue to use our Products. For example, after your account is terminated, you cannot buy or sell through our online marketplace. Unless you have violated our Terms repeatedly or we have legal or policy reasons not to, we will notify you of any change to your account.</p>
     <p>Kwik&nbsp;reserves the right to discontinue, suspend, or change its Products and how you interact with them. By using our Products, you understand&nbsp;Kwik&nbsp;is not liable for the effect of any change or discontinuation of our Products.&nbsp;</p>
     <p>These Terms and Conditions remain in effect after your account is terminated.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Other Legal Matters&nbsp;
     </div>
     <p>These Terms and Conditions supersede any other agreement you may have with&nbsp;Kwik. If any part of the Terms is unenforceable, that part will be limited to the minimum extent necessary, and the Terms will remain in full effect&nbsp;otherwise.&nbsp;If we do not&nbsp;immediately enforce any part of these Terms, you recognize that we reserve the right to later enforce that&nbsp;or any part of the Terms.</p>
    </div>
    <div class="sub-item">
     <div class="sub-title">
      Questions About Terms and Conditions&nbsp;&nbsp;
     </div>
     <p>If you have any questions about these Terms &amp; Conditions, contact customer support at support@wer1.com.</p>
    </div>
    </div>
    `,

    // 头部
    HEADER_MENU_SHARING: "Share",
    HEADER_MENU_BUY: "Buy",
    HEADER_MENU_SHOPPING: "Shopping",
    HEADER_MENU_HELP: "Help",
    HEADER_MENU_ACCOUNT_SETTING: "Account Setting",
    HEADER_LAYER_ACCOUNT: "Account",
    HEADER_LAYER_EDIT_ACCOUNT: "Edit Account",
    HEADER_LAYER_LOGOUT: "Logout",
    HEADER_LAYER_HELP_CONTACT: "Help/Contact",
    HEADER_LAYER_HELP_LEGAL: "Legal",
    HEADER_LAYER_HELP_LANGUAGE: "Language",
    HEADER_LAYER_HELP_SELECT_YOUR_GEOGRAPHICAL_REGION:
      "Select your geographical region",
    HEADER_LAYER_HELP_SELECT_YOUR_PREFERRED_LANGUAGE:
      "Select your preferred language",

    // setting-header page
    SETTING_HEADER_ACCOUNT: "Account",
    SETTING_HEADER_HELP_CONTACT: "Help/Contact",
    SETTING_HEADER_LEGAL: "Legal",
    SETTING_HEADER_SETTINGS: "Settings",

    // setting-account page
    SETTING_ACCOUNT_CHANGE_IMAGE: "Change Image",
    SETTING_ACCOUNT_FIRST_NAME: "First Name",
    SETTING_ACCOUNT_FIRST_NAME_IS_REQUIRED: "First Name is required",
    SETTING_ACCOUNT_LAST_NAME: "Last Name",
    SETTING_ACCOUNT_LAST_NAME_IS_REQUIRED: "Last Name is required",
    SETTING_ACCOUNT_USER_NAME: "User Name",
    SETTING_ACCOUNT_USER_NAME_IS_REQUIRED: "User Name is required",
    SETTING_ACCOUNT_PROFILE_CONTACT_INFO: "Profile Contact Info",
    SETTING_ACCOUNT_PHONE_NUMBER: "Phone Number",
    SETTING_ACCOUNT_PHONE_NUMBER_IS_REQUIRED: "Phone Number is required",
    SETTING_ACCOUNT_EMAIL: "Email",
    SETTING_ACCOUNT_EMAIL_IS_REQUIRED: "Email is required",
    SETTING_ACCOUNT_PLEASE_NOTE_MESSAGE:
      "Please Note: this is how people will be able to reach you from your Profile Page. Update these so people know where to reach out to you.",
    SETTING_ACCOUNT_YOUR_LOGIN_EMAIL_MESSAGE:
      "Your login email and account phone number will still be the same.",
    SETTING_ACCOUNT_SECURITY: "Security",
    SETTING_ACCOUNT_PASSWORD: "Password",
    SETTING_ACCOUNT_DISCARD: "Discard",
    SETTING_ACCOUNT_CHANGE_PASSWORD: "Change Password",

    // setting-contact page
    SETTING_CONTACT_HELP_CONTACT: "Help & Contact",
    SETTING_CONTACT_CONTACT_US_AT: "Contact us at",
    SETTING_CONTACT_ADDRESS: "Address",
    SETTING_CONTACT_EMAIL: "Email",
    SETTING_CONTACT_PHONE: "Phone",
    SETTING_CONTACT_WHAT_TYPE_OF_PROBLEM: "What type of Problem",
    SETTING_CONTACT_PROBLEM_IS_REQUIRED: "Problem is required",
    SETTING_CONTACT_THIS_FIELD_IS_REQUIRED: "This field is required",
    SETTING_CONTACT_PLEASE_GIVE_US_DETAILS_MESSAGE:
      "Please give us details regarding the issue your reporting. Your name, email address, and order number if related.",
    SETTING_CONTACT_DETAIL_IS_REQUIRED: "Detail is required",
    SETTING_CONTACT_ATTACH_ANY_IMAGES_MESSAGE:
      "Attach any images or screen captures",
    SETTING_CONTACT_DRAG_AND_DROP_MESSAGE:
      "Drag and drop a video file to assets, or browse",
    SETTING_CONTACT_SUBMIT: "Submit",

    // 创建sharelink提示
    SORRY_YOU_CAN_T_SHARE_PRODUCT_MESSAGE:
      "Sorry, you can't share product #{{names}}. Please buy this product now, or apply for the eligibility.",

    USER_REGISTER_INVLID_INPUT_CHARACTERS: "Invalid Input Characters",
    BACK_TO_ORDERS: "Back to Orders",
    ORDER_AGAIN: "Order Again",
    DOWNLOAD_INVOICE: "Download Invoice",
    REPORT_A_PROBLEM: "Report a Problem",
    CONTACT_BRAND: "Contact Brand",
    TERMS_COND: "Terms & Conditions",
    FINANCE: "Finance",
    SETTINGS: "Settings",
    POST_LIVE_SUCCESS: "Congrats! Your Post is live",
    POST_LIVE_DESC:
      "You can share this post with the other to make engagement to this post",
    SHARE_POST: "Share Post",
    VIEW_POST: "View Post",
    BACK_PROFILE: "Back To Profile",
    FOLLOW: "Follow",
    REVIEW_PRODUCT: "Review this Product",
    HOW_LIKE_PRODUCT: "How did you like this product?",
    SAVE_DETAILS: "Save Details",
    PHONE_UNIQUE_ERROR:
      "This phone number is already associated with a Kwik account. Please enter in a new phone number",
    ADD_ACCOUNT_PHONE: "add account phone",
    CASH_NOT_AVAILABLE: "You cannot cash out more than your available balance",
    SELECT_BANK_CARD: "Please select a bank card!",
    INPUT_AMOUNT: "Please input the amount!",
    SIGN_HERE: "Sign in here",
    ALREADY_HAVE_ACCOUNT: "Already Have An Account",

    // 结算页面
    CHECKOUT_PAYMENT: "Payment",
    // vendor添加商品
    ADD_PRODUCT_RETAIL: "Retail Price",
    ADD_PRODUCT_WHOLESALE: "Wholesale Price",
    ADD_PRODUCT_PLEASE_INPUT_YOUR_WHOLESALE: "Please input your Wholesale!",
    ADD_PRODUCT_RETAIL_SHOULD_NOT_MESSAGE:
      "Incentive budget should be less than retail price",
    ADD_PRODUCT_PROMOTIONAL_INSTANT_KWIK_CASH_ACCRUAL:
      "Promotional Instant Kwik Cash Accrual",
    ADD_PRODUCT_OF_RETAIL: "of Retail",
    ADD_PRODUCT_BRAND_PAYOUT: "Brand Payout (Adjusted Wholesale)",
    ADD_PRODUCT_KWIK_REVENUE: "Kwik Revenue",
    ADD_PRODUCT_OF_DELTA: "of Delta",
    ADD_PRODUCT_ADMINISTRATION_FEE: "Administration Fee",
    ADD_PRODUCT_REFERRAL_FEE: "Referral Fee",
    ADD_PRODUCT_INSTANT_KWIK_CASH_ACCRUAL: "Instant Kwik Cash Accrual",
    ADD_PRODUCT_COMMISSION_ALLOCATION: "Commission Allocation",
    ADD_PRODUCT_TRUE_COMMISSION_ALLOCATION: "True Commission Allocation",
    ADD_PRODUCT_LEVEL_WAVE_OF_COMMISSION: "{{level}} Wave of Commission",

    //vendor settings
    VENDOR_SETTINGS_BRAND_ID: "Brand ID",
    VENDOR_SETTINGS_KWIK_REVENUE: "Kwik Revenue(%)",
    VENDOR_SETTINGS_ADMIN_FEE: "Admin Fee(%)",
    VENDOR_SETTINGS_RETAIL_MULTIPLLER: "Sales Tax Multiplier (%):",
    VENDOR_SETTINGS_FEE_MULTIPLLER: "Fee Multiplier (%):",
    VENDOR_SETTINGS_REFERRAL_FEE: "Referral Fee(%)",
    VENDOR_SETTINGS_IKCA: "Instant KwikCash Accrual(%)",
    VENDOR_SETTINGS_REFERRAL_EMAIL: "Referrer's Email",
    VENDOR_SETTINGS_REFERRAL_NAME: "Referrer's Name",
    VENDOR_SETTINGS_TP: "Temporary Promotion(%)",
    VENDOR_SETTINGS_NEWAGE_CAMPAIGN: "NewAge Brand Engagement",
    VENDOR_SETTINGS_FULL_BY_NEWAGE: "Fullfilled by NewAge",
    VENDOR_SETTINGS_CTPV: "Percentage Commission to PV Value(%)",
    VENDOR_SETTINGS_DPP: "Dollar per PV",

    //2.26
    PRODUCTS_DETAIL_VENFOR_PAYOUT: "Vendor Payout",
    PRODUCTS_DETAIL_COMPARE_AT_PRICE: "Compare at Price - Optional",
    PRODUCTS_DETAIL_PAYOUT_REMITTENCE: "Payout Remittence",
    PRODUCTS_DETAIL_CASHBACK_STRUCTRUE: "Cashback Structure",
    PRODUCTS_DETAIL_CASHBACK: "Cashback",
    PRODUCTS_DETAIL_PROMOTIONAL_CASHBACK: "Promotional Cashback",
    PRODUCTS_DETAIL_WAVE_COMM_STRUCTURE: "Wave Commission Structure",
    PRODUCTS_DETAIL_WAVE_COMM_STRUCTURE_DESC:
      "Determined by the Retail Price - Whole Sale Price. Adjust those to see changes in the wave structure",
    PRODUCTS_DETAIL_TOTAL_WAVE_FUNDING: "Total Wave Funding",
    PRODUCTS_DETAIL_1ST_OF_RETAIL: "1st Wave as a % of Retail",
    PRODUCTS_DETAIL_TEMPORARY_PROMOTIONS: "Temporary Promotions",
    PRODUCTS_DETAIL_OPTIONAL: "Optional",
    PRODUCTS_DETAIL_TPR: "Temporary - Payout Remittence",
    PRODUCTS_DETAIL_SALES_CHANNEL: "Sales Channel",
    PRODUCTS_DETAIL_SALES_CHANNEL_DESC:
      "Please note that these additional channels are optional. If you opt into one of them it may impact the various wave amounts.",
    PRODUCTS_DETAIL_DIRECT_DISTRIBUTE: "Direct Distributorship",
    VENDOR_SETTINGS_WRONG_TYPE: "Please enter the number type",

    //2.27
    PRODUCTS_DETAIL_TAX_VAT_DUTY: "Taxes/Vat/Duties",
    PRODUCTS_DETAIL_REQUIRE_EU: "- Required for EU Countries",
    PRODUCTS_DETAIL_REQUIRE_AUS: "- required for Australia",
    PRODUCTS_DETAIL_REQUIRE_CAN:
      "- Required for Cross Border e-Commerce to Canada",
    SHARE_LINK_PRICE_VAT: "Vat",

    //3.2
    SHARELINK_PRODUCT_DETAIL_NO_DATA:
      "This product is not available in your country!",
    VENDOR_SHIPPING_COST_SHIPPING_METHODS: "Shipping Method",

    // 3.4
    CHECKOUT_SAVE_SHIPPING_ADDRESS_MESSAGE: "Please fill out the form below",
    // 3.8
    SORRY_YOUR_PAYMENT_DID_NOT_GO_THROUGH:
      "We were unable to process your payment.Please try again.",
    ORDER_HISTORY_ORDER_MORE: "Order More",
    ORDER_HISTORY_SHARE_PRODUCT: "Share Product",
    ORDER_HISTORY_EDIT_REVIEW: "Edit a Review",
    ORDER_HISTORY_ADD_REVIEW: "Write a Review",
    ORDER_HISTORY_SHIPPING_DETAIL: "Shipping Details",
    ORDER_HISTORY_ARRIVAL_DATE: "Arrival Date",
    ORDER_HISTORY_TRACKING_NUMBER: "Tracking Number",
    ORDER_HISTORY_ORDER_INFO: "Order Information",
    ORDER_HISTORY_TOTAL_COST: "Total Cost",
    ORDER_HISTORY_ORDER_DATE: "Order Date",
    ORDER_HISTORY_SHIP_DATE: "Ship Date",
    ORDER_HISTORY_SHIP_LOCATION: "Ship Location",
    // 3.9
    CHECKOUT_PRE_VAT_SUBTOTAL: "Pre-VAT Subtotal",
    CHECKOUT_VAT_TOTAL: "VAT Total",
    CHECKOUT_PRE_VAT_SHIPPING: "Pre-VAT Shipping",
    CHECKOUT_SHIPPING_VAT: "Shipping VAT",
    CHECKOUT_ORDER_TOTAL: "Order Total",
    CHECKOUT_ORDER_INFORMATION_ERROR_MESSAGE:
      "Order Information Error, Please Contact Customer Service.",

    //3.11
    ACCOUNT_PHONE_UNIQUE:
      "This phone number is already associated with a Kwik account. Please enter in a new phone number",

    // 3.16
    CHECKOUT_ADDRESS_INVALID_FORMAT: "Invalid format",

    // 3.17
    VENDOR_INVENTORY_PLEASE_SELECT_WAREHOUSE: "Please select Warehouse",
    VENDOR_INVENTORY_ADD_INVENTORY_FOR_SKU: "Edit Inventory for SKU#",

    //3.19
    ORDER_HISTORY_VIEW_DETAILS: "View Details",

    // 3.25
    VENDOR_INVENTORY_EDIT_INVENTORY: "Edit Inventory",
    SHARING_NEW_TITLE: "Welcome to WeR1",

    //3.28
    VENDOR_PRODUCT_PSTCAW: "Please select the Country and WareHouse!",
    VENDOR_PRODUCT_PSDCAW: "Please select different Country and WareHouse!",
    VENDOR_INVENTORY_ENTER_A_POSITIVE_OR_NEGATIVE_MESSAGE:
      "Enter a positive or negative number to add to or subtract from the quantity allotted.",
    VENDOR_INVENTORY_INVALID_VALUE: " Invalid Value！",

    // 3.29
    PRODUCT_DETAIL_UPPER_LIMIT_OF_THE_INVENTORY: "Out of Stock",

    //3.30
    PRODUCTS_COPY_BUTTON: "Copy",
    PRODUCT_DETAIL_BACK_TO_PRODUCT_LIST: "Back to Product List",

    //3.31
    NEW_PRODUCT_ID: "New Product ID",
    NEW_PRODUCT_NAME: "New Product Name",

    //4.1
    SHARING_TOP_DESC1: "Buy, Share, and Earn on Kwik!",
    SHARING_TOP_DESC2_1: "Complete the",
    SHARING_TOP_DESC2_2: "Get Started",
    SHARING_TOP_DESC2_3: "guide below",
    SHARING_TOP_DESC3_1: "To earn",
    SHARING_TOP_DESC3_2: "$5 KwikCash!",
    SHARING_CHECKBOX_1: "Create a Kwik Account",
    SHARING_CHECKBOX_2: "Join a Campaign",
    SHARING_CHECKBOX_3: "Share Your Link",
    SHARING_CHECKBOX_4: "View your KwikCash",
    SHARING_CHECKBOX_5: "Check Your Waves",
    SHARING_ALL_PRODUCT: "Share All Products",
    SHARING_MFSP: "Most Frequently Shared Products",
    SHARING_KWIK: "Kwik",
    SHARING_ESS: "Explore, Shop, Share",
    SHARING_FYNCTEM: "Find Your Next Campaign to Earn More",

    // 4.6
    KWIKCASH_ADD_PAYPAL: "Add PayPal",

    //4.7
    ERC__CLICKING_CONFIRM_MESSAGE:
      'I agree to WeR1’s <a href="https://cms.kwik.com/Terms%26ConditionsWeR1.pdf" target="_blank"><span>Terms</span></a> and <a href="https://cms.kwik.com/PrivacyPolicyWeR1.pdf.pdf" target="_blank"><span>Privacy Policy</span></a>',
    // 4.11
    KWIKCASH_CONNECT_TO_PAYPAL: "Paypal",
    KWIKCASH_PLEASE_INPUT_THE_AMOUNT: "Please input the amount!",
    ENTER_LOGIN_EMAIL: "Enter your email to get started or login!",
    I_FORGOT_PASSWORD: "I forgot my password",
    KWIKCASH_THE_ACCOUNT_NUMBER_SHOULD_BE_EMAIL_ADDRESS:
      "The Account Number should be email address",
    // 4.12
    REGISTER_WE_JUST_SEND_YOU_A_VERIFY_MESSAGE:
      "We just sent you a verification code. Check your spam and promotion folders.",
    REGISTER_RESEND_CODE: "Resend Code",
    REGISTER_CREATE_ACCOUNT: "Create Account",
    REGISTER_DONT_RECEIVED: "Don't Received?",
    REGISTER_SIGN_IN: "Sign in",
    REGISTER_ALREADY_A_MEMBER: "Already a member?",
    YOUR_EMAIL: "Your Email",
    LET_FIND_LINK: "Let's find your link!",
    ENTER_EMAIL_FIND_LINK:
      "Enter your email and we can see if you have a link!",

    //4.19
    ENTER_LOGIN_CRED: "Enter your credentials to continue",
    SHARING_TOP_DESC2_NEW:
      "To help you get started we created a Getting Started Checklist!",
    SHARING_TOP_DESC3_NEW: "Click HERE to learn more about Kwik. ",
    SHARING_NO_LINK_TO_SHARE: "No Links to Share?",
    SHARING_ITEM_TEXT_1: "Explore the Kwik Marketplace",
    SHARING_ITEM_TEXT_2: "Buy a Product From a Brand You Love",
    SHARING_ITEM_TEXT_3: "Share a Product Link",
    SHARING_ITEM_TEXT_4: "Earn KwikCash",
    ACCOUNT_PHONE_UNIQUE:
      "This phone number is already associated with a Kwik account. Please enter in a new phone number",
    YOU_MAY_ALSO_LIKE: "You May Also Like",
    ADD_REVIEW: "Add a Review",
    BE_FIRST_REVIEW: "Be the first to review",
    COMMENT: "Comment",
    REFERRAL_MESSAGE: "Referral Message",
    VERIFIED_PURCHASER: "Verified Purchaser",
    COMMENTS: "Comments",
    COPY_FROM_PROD: "Copy From Product",
    COPY_TO_PROD: "Copy To Product(s)",
    KWIKCASH_PAYPAL_EMAIL_ADDRESS: "Email Address",
    SHARELINK_PRODUCT_DETAIL_CASHBACK_ON_PURCHASE: "Cashback on Purchase",
    // 4.20
    SHARE: "Share",
    CASHBACK_ON_PURCHASE: "Cashback on Purchase",

    //4.21
    EMAIL_NOT_REGISTERED: "This email is not registered",
    SEND_SUCCESS: "Send successfully",
    SENT_SUCCESS: "Sent Successfully",
    LOGIN_KIHTM: "WeR1 is Here to Make",
    LOGIN_ERE: "Everyday Recommendations Easier",
    PROCEED: "Proceed",
    TSIPBRATGPP:
      "This site is protected by reCAPTCHA and the Google Privacy Policy",
    BRAND_NEW_TO_KWIK: "Brand new to WeR1",
    CLICK_HERE_TO_CREATE: "Click here to create",
    LOOKING_TO_FIND_YOUR_LINK: "Looking to find your link",
    CLICK_HERE_TO_FIND_IT: "Click here to find it",
    SHARE_INFO_LAYER_CREATE_A_SHARE_LINK: "Create a Share Link",
    SHARE_INFO_LAYER_GIVE_A_RATING: "Give a rating (Optional)",
    SHARE_INFO_LAYER_ADD_A_MESSAGE: "Add a message (Optional)",
    SHARE_INFO_LAYER_250_WORDS_LEFT: "{{count}} Words Left",
    SHARE_INFO_LAYER_ADD_ADDITIONAL_MEDIA: "Add Additional Media",
    SHARE_INFO_LAYER_UPLOAD: "Upload",
    SHARE_INFO_LAYER_SHARE_YOUR_LINK: "Share Your Link",
    SHARE_INFO_LAYER_COPY_LINK: "Copy Link",
    SHARE_INFO_LAYER_THE_MAXIMUM_FILE_SIZE: "The maximum file size is 80 MB",
    SHARE_INFO_LAYER_THE_FILE_FORMAT_IS_INCORRECT:
      "The file format is incorrect",
    SHARE_INFO_LAYER_VERIFIED_PURCHASER: "Verified Purchaser",
    USER_HAS_GOT_PASSWORD:
      "User Already Has Account.  Please Use Sign In Below and Reset Password if needed.",
    INVALID_EMAIL: "Invalid Email Address",
    HEY_WE_MISS_YOU: "Hey {{name}},",
    HEY_WE_MISS_YOU2: "We Missed You!",
    LAST_REMEMBER_YOU: "Last We Remember You From",
    SAVE_YOUR_INFO_TEXT:
      "We were hoping you would come back, so we decided to save your information.",
    PHONE_HAS_REGISTERED: "Phone has been registered",
    IMPACT_OF_YOUR_RECOMMENDATIONS_1: "See The True Impact of Your",
    IMPACT_OF_YOUR_RECOMMENDATIONS_2: "Recommendations.",
    FULL_NAME_NOT_BE_EMPTY: "Full Name must not be empty",
    CODE_VERIFICATION: "Code Verification",
    ENTER_4_DIGIT_TIPS: "Please enter the 4-Digit code sent to you at ",
    CHECK_JUNK_EMAIL_FOLDER:
      "If you do not easily find an email from KwikClick, please check your junk or spam folder. If you use Gmail, please check the Promotions tab.",
    // 4.22
    PRODUCT_DETAIL_PAGE_CASHBACK_IS_THE_AMOUNT_MESSAGE:
      "Cashback is the amount you will earn instantly when purchasing this product. It will become avaliable after the return period expires.",
    LINK_HEADER_ALREADY_A_USER: "Already a user?",
    RESET_PASSWORD_PASS_RECOVERY: "Password Recovery",
    RESET_PASSWORD_ENTER_EMAIL_TIPS: "Enter the email you use for WeR1",
    RESET_PASSWORD_SEND_TITLE: "Reset Your Password",
    RESET_PASSWORD_SEND_TIPS_1: "We've sent instructions to: ",
    RESET_PASSWORD_SEND_TIPS_2:
      "if you didn't get the email, ask to resend the instructions.",
    RESET_PASSWORD_SEND_BTN_BACK_SIGN_IN: "Back To Sign In",
    RESET_PASSWORD_SEND_LINK_RESEND: "Resend the instruction again",
    RESET_PASSWORD_UPDATE_TIPS_SAME_PASS:
      "The password is inconsistent with the confirmation password",
    RESET_PASSWORD_UPDATE_TIPS_CONFIRM: "Confirm New Password",
    RESET_PASSWORD_UPDATE_SAVE_PASS: "Save Password",
    RESET_PASSWORD_SUCCESS_TIPS:
      "Your Password has been created. Please return to the login screen.",
    RESET_PASSWORD_SUCCESS_LOGIN_ACCOUNT: "Login to Your Account",
    RESET_PASSWORD_SUCCESS_BACK_HOME: "Back to Homepage",
    RESET_PASSWORD_REQUIRED_EMAIL: "Email is required",
    RESET_PASSWORD_RESEND_SUCCESS: "Resend Success",
    RESET_PASSWORD_CHOOSE_PASSWORD: "Choose your new password",
    ALL_TIME: "All Time",
    LAST_DAY: "Last {{day}} Days",
    MY_CAMPAIGNS: "My Campaigns",
    SHOW_MORE: "Show More",
    SHOW_LESS: "Show Less",
    OTHER_PRODUCTS: "Other Products",
    CLICK_PRODUCT_SHOW:
      "Click on a product to view data on that specific product!",
    SEARCH_PRODUCT: "Search For a Product",
    SHOWING_PAGE: "Showing {{pagination}}",
    REFERRAL: "{{referral}} Referrals",
    PREVIOUS: "Previous",
    NEXT: "Next",
    CASH: "Cash",
    LEARN_KWIK: "Learn Kwik",
    KWIK_TIPS: "Kwik Tips",
    WANT_LEARN_ABOUT: "Want to learn about what data Kwik provides?",
    TOTAL_REFERRAL: "Total Referrals Generated",
    DIRECT_REFERRAL: "Direct Referrals",
    WAVE_DEPTH: "Wave Depth",
    RECENT_ADDITIONS: "Recent Additions",
    TRANSACTIONS: "Waves",
    TOP_SHARED_PRODUCT: "Top {{count}} Shared Products",
    VIEW_ALL: "View All",
    TOP_COUNTRIES: "Top Countries",
    ANALYTICS: "Analytics",
    PEOPLE_REACHED: "People Reached",
    SHARES: "Shares",
    LINK_CLICKS: "Link Clicks",
    RECENT_SHARES: "Recent Shares",
    DATE: "Date",
    PLATFORM: "Platform",
    OPENED: "Views",
    CONVERTED: "Converted",
    SIGNED_UP: "Signed Up",
    CLAIMS: "Claims",
    ORDERED: "Ordered",
    WAVE_SOURCES: "Wave Sources",
    REFERRAL_SUCCESS_RATE: "Referral Success Rate",
    OPEN_RATE: "Open Rate",
    CONVERSION: "Conversion",
    IMPACT_RECOMMENDATIONS: "Most Impactful Recommendations",
    REFFERED_BRANDS: "Referred Brands",
    REFERRED_BRANDS_DESCRIPTION:
      "These are the brands that you have referred to Kwik.",
    SEARCH_BRAND: "Search for a brand",
    SELECTED_CAMPAIGN: "Selected Campaign",
    ACTIVE_CAMPAIGN: "Active Campaign",
    INACTIVE_CAMPAIGN: "Inactive Campaign",
    SELECTED_CAMPAIGN_DESCRIPTION:
      "You are viewing data for “{{campaignName}}” campaign",
    SELECT_SPECIFIC_CAMPAIGN:
      "Select a specific campaign if you just want to see data around that campaign.",
    SEARCH_CAMPAIGN: "Search for a campaign",
    GENERAL_SALES_INFO:
      "The amount of sales you have generated for companies across all the campaigns/brands you are a part of. This includes sales from your purchases, as well as your wave of influence.",
    GENERAL_SALES_INFO_INDV:
      "The amount of sales you have generated for this campaign. This includes sales from your purchases, as well as your wave of influence.",
    KWIKCASH_INFO:
      "The amount you've earned in 1Cash across all campaigns. This includes Cashback, Commissions & Bonuses earned from your wave of influence & purchases.",
    KWIKCASH_INFO_INDV:
      "The amount you've earned in 1Cash from this campaign. This includes Cashback, Commissions & Bonuses earned from your wave of influence & purchases.",
    TOTAL_REFERRALS: "Total Referrals",
    SALES_GENERATED: "Sales Generated",
    WAVE_NUMBER: "Wave {{number}}",
    MEMBERS: "Members",
    MEMBERS_NUMBER: "Members ({{number}})",
    SEARCH_MEMBER: "Search For Member",
    RECENT_REFERRALS: "Recent Referrals",
    ALL_REFERRALS: "All Referrals",
    // 4.29
    SHOPPING_PV: "PV",

    //5.7
    POLICY_POLICIES: "Policies",
    POLICY_VTPTTBHUTK: "View the policies that the brand has uploaded to WeR1",
    POLICY_SHIPPING_POLICY: "Shipping Policy",
    POLICY_TAC: "Terms and Conditions",
    POLICY_RP: "Return Policy",
    POLICY_PP: "Privacy Policy",
    POLICY_NOT_SEEING: "Not seeing what you want? Contact the brand directly",

    //5.9
    DASHBOARD_PROFILE_STATUS: "Getting Started",
    DASHBOARD_GET_5_CASHBACK: "Complete the 5 steps to earn $5 cashback!",
    DASHBOARD_COMPLETE_STEPS_EXPERT:
      "Complete these steps to become an expert!",
    DASHBOARD_CREATE_ACCOUNT: "Create an Account",
    DASHBOARD_ALREADY_CREATED: "Already Created",
    DASHBOARD_DONE: "Done!",
    DASHBOARD_FAPOCWAB: "Find a product or connect with a brand",
    DASHBOARD_MAKE_A_REFER: "Make a Referral",
    DASHBOARD_SEE_ROLLIN: "Nice! See the money rollin",
    DASHBOARD_SRAPTAF: "Successfully refer a product to a friend",
    DASHBOARD_SHOW_ME_MONEY: "Show me the money",
    DASHBOARD_SEE_YOUR_IMPACT: "See your impact",
    DASHBOARD_CLAIM_REWARD: "Claim Reward",
    DASHBOARD_UPLOAD_IMAGE: "Upload Image",
    DASHBOARD_HEY: "Hey",
    DASHBOARD_WELCOME_TO_KWIK:
      "Welcome to WeR1! This is the Dashboard. This is where you will learn everything you need to know about WeR1 and how to start sharing and earning. Also, there is free money in “Getting Started” to the right!",
    DASHBOARD_WELCOME_TO_KWIK_MOBILE:
      "Welcome to WeR1! This is the Dashboard. This is where you will learn everything you need to know about WeR1 and how to start sharing and earning. Also, there is free money in the “Getting Started” section below!",
    DASHBOARD_WELCOME_TO_KWIK2:
      "Welcome to WeR1! We’re excited about your recent purchase! We want to help you share that purchase with those you know in a meaningful way.",
    DASHBOARD_WELCOME_TO_KWIK3:
      "Things might look a bit different here. If you missed the latest update, Click here to see all the new features added to WeR1!",
    DASHBOARD_WELCOME_TO_KWIK4:
      "Welcome to WeR1. It’s the easiest and Most Rewarding way to Recommend your Products to Friends & Followers.",
    DASBOARD_LOOK_TO_THE_RIGHT: "Look to the right",
    DASBOARD_LOOK_TO_BELOW: "Look below",
    DASHBOARD_SALES_GENERATED: "Sales Generated",
    DASHBOARD_VIEW_ANALYTICS: "View Analytics",
    DASHBOARD_NOTIFICATION: "Notifications",
    DASHBOARD_PURCHASED_FORM_YOUR_LINK: "Purchased from your link",
    MOST_RECENT: "Most Recent",
    KWIK: "Kwik",
    KB_VERSION: "KB",
    KB_THANKYOU: "Let Us Know What You Think!",
    KB_TITLE_00: "KB 3.0.3.2",
    KB_TITLE_01: "User Notifications",
    KB_TITLE_02: "Brand Reports & Data",
    KB_TITLE_03: "Checkout & Account Settings Addresses",
    KB_TITLE_04: "Product Overview",
    KB_TITLE_05: "Add Product Update for Kwikified Brands ",
    KB_TITLE_06: "User Improvements",
    KB_TITLE_07: "Brand Improvements",
    KB_TITLE_08: "Let Us Know What You Think!",
    WELCOME_KWIK:
      "3.0.6 is proof Kwik is committed to continually providing brands and users with solutions and opportunities for expansion and income growth.  With new features, re-designs, and plenty of improvements, you’ll know we are invested in providing customers and users with the most user-friendly and innovative word-of-mouth marketing program out there.",
    THANKYOU_KWIK:
      "If you haven’t already, sign up for the blog today so you’ll be the first to hear about our newest features!. We love hearing from our users and brands, so please Email us at hello@wer1.com with any feedback you may have. Enjoy 3.0.5!",
    WELCOME_KWIK_01: "Check out what’s new!",
    VERSION_DESC_01:
      "Check out our new User Notifications page by clicking “View All” from the notifications center on the dashboard.  Notifications not only keep you updated on your account activity but on your referrals' activity as well.  This gives you the opportunity to drive real-time engagement with your referrals and those within your waves resulting in greater waves of earning for you!",
    VERSION_DESC_02:
      "Our new Reports page joins the line-up of recently added or updated pages in the menu of our vendor accounts.  It provides data-driven solutions for increasing brand growth through Kwik utilization, as well tools and insight for increasing Ambassador trust and connections.",
    VERSION_DESC_03:
      " We’ve made adding and revising addresses right in checkout more user friendly and intuitive so ordering your favorite products will be a breeze.  This information syncs to your account settings, so they’ll never be a need to update an address in more than one place.",
    VERSION_DESC_04:
      "Our new Product Overview page was re-designed with efficiency and simplicity in mind.  Easily find relevant information pertaining to your inventory on one page.  From product sales to user count, you’ll get important information surrounding the success of all your products promoted through Kwik.",
    VERSION_DESC_05:
      "We’ve simplified the add product screen for our integrated brands!  You’ll no longer be required to add information which doesn’t pertain to selling directly through the Kwik marketplace.  Just another step on our journey to make Kwik the easiest and most innovative marketing platform for brands to grow their businesses.",
    VERSION_DESC_06:
      "If you haven’t already, sign up for the blog today so you’ll be the first to hear about our newest features!. We love hearing from our users and brands, so please Email us at hello@wer1.com with any feedback you may have. Enjoy 3.0.5!",
    VERSION_DESC_07:
      "If you haven’t already, sign up for the blog today so you’ll be the first to hear about our newest features!. We love hearing from our users and brands, so please Email us at hello@wer1.com with any feedback you may have. Enjoy 3.0.5!",
    VERSION_DESC_08:
      "Have an idea for us? Find a typo? Something not working quite right… let us know! We love hearing from our users and brands, so please Email us at hello@wer1.com with any feedback you may have. We have some big things in the works, and we promise, you - our blog subscribers- will always be the first to know about them!",
    // 5.19
    BASEHEADER_LINK_DASHBOARD: "Dashboard",

    NEW_SHARE_LINK_SAVE_FOR_LATER: "Save for later",
    NEW_SHARE_LINK_CART_TEXT_WHAT_WANT_SAVED: "Want to view what you saved? ",
    NEW_SHARE_LINK_CART_TEXT_OR: " or ",
    NEW_SHARE_LINK_CART_TEXT_CREATE_ACCOUNT: "Create an Account",
    NEW_SHARE_LINK_CART_TEXT_SIGN_IN: "Sign in",
    NEW_CHECKOUT_SELECT_SHIPPING_RATE: "Select a shipping method per brand",
    NEW_CHECKOUT_TITLE_CART: "Cart:",

    //5.21
    DASHBOARD_WELCOME_BACK: "Welcome back",

    DASHBOARD_MY_ORDERS: "My Orders",
    DASHBOARD_NO_ORDERS: "No Orders",
    DASHBOARD_MADE_ORDER_TIPS:
      "Seems like you haven’t made any orders from a brand utilizing Kwik!",
    DASHBOARD_RECENT_SHARE: "Recent Share",
    DASHBOARD_SHARE_OTHER_PRODUCTS: "Share other products",
    DASHBOARD_SEE_MORE: "See more",
    DASHBOARD_SHOW_LESS: "Show Less",

    DASHBOARD_WELCOME: "Welcome",
    ALREADY_SHARED_FAVOURITE:
      "You already share your favorite products. We make it easy to earn the commissions you deserve.",
    SHOW_ME_HOW: "Show Me How",
    ALREADY_BRAND_AMBASSADOR: "— You’re already an official brand ambassador",
    WHY_MAKE_AMBASSADOR:
      "Why not make it official? Kwik gives you the tools you need to make sharing and earning simple and fun",
    MEET_SHARE_PAGE: "— Meet the Share Page",
    MEET_SHARE_PAGE_DESC:
      "This is your new best friend. It’s where you’ll find all your referral links and product campaigns.",
    SHARE_PAGE_FEATURE: "— Share Page Features",
    SHEARCH_FAVOURITE_PRODUCT:
      "<ul><li>Search for your favorite products and brands you can share.</li><li>Share individual products or the full brand catalog.</li></ul>",
    CUSTOM_LINKS_COLLECTION: "— Custom Links and Collections",
    PERSONAL_SHARE_LINK:
      "<ul><li>Personalize your share link with custom messages, images, videos, and reviews. </li><li>Create a collection of multiple products based on shared interests and activities.</li></ul>",
    GOT_IT: "Got it",
    MEET_KWIK_CASH: "— Meet Kwikcash!",
    BEST_PART_USING_KWIK:
      "The best part of using Kwik—the money! \n Kwikcash shows up in two forms, available and pending. Earnings become available when the return period ends with a minimum 7 day pending period.",
    MONEY_MOVES: "— Money Moves",
    MONEY_MOVE_DESC:
      "<ul><li>Cashout through ACH or Paypal</li><li>View your Kwikcash, see where you earned it, how you spent it, and when your latest earnings will be available.</li><li>Learn more about Kwikcash here.</li></ul>",
    MEET_CAMPAIGNS: "— Meet Campaigns",
    MEET_CAMPAIGNS_DESC:
      "This is where we put our blockchain technology to work. \n We track every link so you can see the true impact of your product recommendations",
    KEY_FEATURES: "— Key Features",
    KEY_FEATURE_DESC:
      "<ul><li>Data is broken down into three levels: all campaigns, single campaigns, and individual products.</li><li>Use waves to see how one referral can turn into many!</li></ul>",
    ADDITIONAL_FEATURES: "— Additional Features",
    ADDITIONAL_FEATURES_DESC:
      "<ul><li>Lost? Click the ‘I’ icon for more info.</li><li>For more details on how waves of earnings work or how campaigns work, <a href='https://kwik.com/work' target='_blank'>click here</a>.</li></ul>",
    KWIK_TEAM: "— Kwik Team",
    KWIK_TEAM_QUESTION:
      "<p>Got questions or feedback? We want to hear from you! Email us at <a href='mailto:hello@wer1.com'>hello@wer1.com</a></p><p>PS: We read every message. :) Thanks for helping us make Kwik even better!</p>",
    IAMREADY: "I am Ready",
    DASHBOARD_PAGE: "— Dashboard Page",
    DASHBOARD_DESCRIPTION:
      "<p>Your data dashboard shows past orders, notifications, campaigns, and tips for making waves with product referrals.</p><p>You should find some basic data to help you get started!</p>",
    I_GOT_IT: "I Got It",
    DASHBOARD_CHECK_YOUR_EARINGS: "Check Your Earnings",
    DASHBOARD_BLOG_ARTICLES: "Blog / Articles",

    //5.29

    SETTINGS_GENERAL_CONTACT_REFERRER_FIRST_NAME: "Referrer's First Name",
    SETTINGS_GENERAL_CONTACT_REFERRER_LAST_NAME: "Referrer's Last Name",
    SETTINGS_GENERAL_CONTACT_REFERRER_NAME: "Referrer's Name",
    SETTINGS_GENERAL_CONTACT_REFERRER_EMAIL: "Referrer's Email",
    VENDOR_FINANCE_TABLE_HD_DATE: "Date",
    VENDOR_FINANCE_TABLE_HD_TRANSACTION_STATUS: "Transaction status",
    VENDOR_FINANCE_TABLE_HD_TRANSACTION_METHOD: "Transaction Method",
    VENDOR_FINANCE_TABLE_HD_NET_PAYMENT: "Net Payment",
    VENDOR_FINANCE_TABLE_HD_TYPE: "Type",
    VENDOR_FINANCE_TABLE_HD_KWIKCASH_TYPE: "Kwikcash Type",
    VENDOR_FINANCE_TABLE_HD_AMOUNT: "Amount",
    VENDOR_FINANCE_TABLE_HD_BALANCE: "Balance",
    CAMPAIGNS_SELECTED_KWIKCASH: "1Cash",

    // 5.31
    CAMPAIGNS_MY_CAMPAIGNS_THESE_ARE_THE_MESSAGE:
      "These are the campaigns that you’re participating in. To gain more campaigns, either purchase a product from that brand or apply directly to the brand to become an ambassador.",
    CAMPAIGNS_IMPACTFULRECOMMEND_THIS_WILL_SHOW_MESSAGE:
      "This will show you who you have referred that has generated the most income or you.",
    DASHBOARD_SEE_LESS: "See Less",
    SHARELIINK_REGISTER_PASSWORD_PLACEHOLDER: "Password 8+ Characters",

    //6.1
    JOIN_CAMPAIGN_INFO:
      "Easily become brand Ambassadors for all WeR1 brands and start sharing links for them to earn 1Cash and extra bonuses and loyalty points!",
    MAKE_REFERRAL_INFO:
      "Share a link to any product with anyone. If they make a purchase, you’ve successfully made a referral and can check this off your list!",
    CHECK_EARNINGS_INFO: "Easy, just go to the 1Cash page via navigation.",
    CHECK_WAVES_INFO: "Go to the campaigns page via the navigation menu.",
    CAMPAIGNS_ALL_CAMPAIGNS: "All Campaigns",
    // 6.2
    CAMPAIGNS_WAVE_INSTANT_CASH_BACK: "Instant Cash Back",
    CAMPAIGNS_WAVE_RECENT_CASH_BACK_ORDERS: "Recent Cash Back Orders",
    CAMPAIGNS_WAVE_ALL_CASH_BACK_ORDERS: "All Cash Back Orders",
    CAMPAIGNS_WAVE_CASHBACK_EARNED: "CashBack Earned",
    // 6.3
    ALL_TIME2: "All Time",
    DASHBOARD_THIS_MONTH_ITEM2: "This Month",
    LAST_DAY2: "Last {{day}} Days",

    CAMPAIGNS_NO_SHARED_PRODUCTS: "No Shared Products",
    CAMPAIGNS_NO_MADE_REFERRALS:
      "Seems like you haven’t made any referrals or recommendations yet.",
    CAMPAIGNS_SHARE_SOMETHING: "Share Something",
    CAMPAIGNS_NO_WAVES: "No Waves",
    CAMPAIGNS_ONLY_WORKS:
      "Waves only works when you’re sharing with your friends!",
    CAMPAIGNS_WAVE_TEXT_REFERRALS: "Referrals",

    YOUR_ACCOUNT_ALMOST_CREATED: "Your Account is Almost Created!",
    ORDER_DETAIL_REVIEW_CONTENT_MESSAGE: "Please leave your review!",

    // 6.16
    NEW_CHECKOUT_DELIVERY_INFORMATION: "Delivery Information",
    NEW_CHECKOUT_EDIT: "Edit",
    NEW_CHECKOUT_SHIPPING_METHOD: "Shipping Method",
    NEW_CHECKOUT_PAYMENT_METHOD: "Billing Information",

    //6.20
    ENTER_LOGIN_EMAIL_NEW: "Enter your Email, and we will do the rest!",

    // kwikcash 137
    KWIKCASH_BALANCE_TITLE_AVAILABLE: "Available Balance",
    KWIKCASH_BALANCE_TITLE_PENDING: "Pending",
    KWIKCASH_BALANCE_SEARCH_TRANSACTION_ID: "Search Transactions / ID",
    KWIKCASH_BALANCE_VIEW_TRANSACTIONS: "View Transactions",
    KWIKCASH_ALL_TIME: "All Time",
    KWIKCASH_THIS_MONTH: "This Month",
    KWIKCASH_LAST_7_DAYS_1: "Last ",
    KWIKCASH_LAST_7_DAYS_2: "7 Days",
    KWIKCASH_TEXT_TRANSACTIONS: "Transactions",
    KWIKCASH_TEXT_JOINED_WAVES: "Joined Waves",
    KWIKCASH_JOINED_WAVES_tips:
      "These are the Users who have joined your Waves.",
    KWIKCASH_TEXT_Earnings: "Earnings",
    KWIKCASH_CASH_OUT_TITLE: "Cash Out Methods",
    KWIKCASH_BTN_CASH_OUT: "Cash out",

    KWIKCASH_ADD_PAYMENT_BTN_NEXT_STEP: "Next Step",
    KWIKCASH_ADD_PAYMENT_BTN_BACK: "Back",
    KWIKCASH_ADD_PAYMENT_SELECT_TYPE_TITLE: "Select Type",
    KWIKCASH_ADD_PAYMENT_SELECT_TYPE_MSG:
      "Select the type of account you want to add.",
    KWIKCASH_ADD_PAYMENT_ADD_BANK_TITLE: "Add Bank",
    KWIKCASH_ADD_PAYMENT_ADD_BANK_MSG_1: "Please enter the details below to ",
    KWIKCASH_ADD_PAYMENT_ADD_BANK_MSG_2: "Add a Bank Account",
    KWIKCASH_ADD_PAYMENT_ADD_BANK_MSG_3:
      "This information will be securely saved as per the ",
    KWIKCASH_ADD_PAYMENT_ADD_BANK_MSG_4: "Terms of Service & ",
    KWIKCASH_ADD_PAYMENT_ADD_BANK_MSG_5: "Privacy Policy",
    KWIKCASH_ADD_PAYMENT_ADD_PAYPAL_MSG_1: "Please enter the details below to ",
    KWIKCASH_ADD_PAYMENT_ADD_PAYPAL_MSG_2: "Add a Paypal Account",
    KWIKCASH_CASH_OUT_TRANSFER_AMOUNT: "Transfer Amount",
    KWIKCASH_CASH_OUT_MSG1:
      "*You can transfer amount easily to any of the linked accounts",
    KWIKCASH_CASH_OUT_METHOD: "Cash Out Methods",
    KWIKCASH_CASH_OUT_MSG21: "Get started by adding your first ",
    KWIKCASH_CASH_OUT_MSG22: "cash out",
    KWIKCASH_CASH_OUT_MSG23: " method",
    KWIKCASH_CASH_OUT_ADD: "Add Method",
    KWIKCASH_CASH_OUT_LEARN_MORE: "Learn More",
    KWIKCASH_TRANSACTION_STATUS_TIPS_DELIVERED: "You should have your money!",
    KWIKCASH_TRANSACTION_STATUS_TIPS_INPROCESS:
      "The cash out is going through the process to arrive in your account",
    KWIKCASH_TRANSACTION_STATUS_TIPS_CANCELED:
      "This cash out has been canceled",
    KWIKCASH_TRANSACTION_STATUS_TIPS_ONHOLD:
      "The cash out is being reviewed by our team. Any cash out's over $500 will be revieweb by the Kwik team. It should take less than 24 hours.",
    KWIKCASH_TRANSACTION_CASH_OUT: "Cash Out Transactions",
    KWIKCASH_TRANSACTION_EXPORT: "Export",
    KWIKCASH_TRANSACTION_TABLE_HEADER_TYPE: "Type",
    KWIKCASH_TRANSACTION_TABLE_HEADER_DATE: "Date",
    KWIKCASH_TRANSACTION_TABLE_HEADER_TRANSACTION_ID: "Transaction ID",
    KWIKCASH_TRANSACTION_TABLE_HEADER_AMOUNT: "Amount",
    KWIKCASH_TRANSACTION_TABLE_HEADER_STATUS: "Status",
    KWIKCASH_TRANSACTION_TABLE_HEADER_TRANSACTION_TYPE: "Transaction Type",
    KWIKCASH_TRANSACTION_TABLE_HEADER_BALANCE: "Balance",
    KWIKCASH_TRANSACTION_TABLE_HEADER_WAVE: "Wave",
    KWIKCASH_TRANSACTION_TABLE_HEADER_INFLUENCER: "Influencer",
    KWIKCASH_TRANSACTION_TABLE_HEADER_AVAILABLE_DATE: "Available Date",
    KWIKCASH_TRANSACTION_NO_DATA_TITLE: "No Transaction to Display",
    KWIKCASH_TRANSACTION_NO_DATA_DESCRIPTION1:
      "Start sharing your products with friends to ",
    KWIKCASH_TRANSACTION_NO_DATA_DESCRIPTION2: "Earn 1Cash",
    KWIKCASH_TRANSACTION_NO_DATA_DESCRIPTION3:
      " Then cash out your earnings and they will show up here :)",
    KWIKCASH_TRANSACTION_NO_DATA_BTN_START_SHARING: "Start Sharing",
    KWIKCASH_TRANSACTION_DETAIL_BACK: "Back",
    KWIKCASH_TRANSACTION_DETAIL_TRANSACTIONS: "Transactions",
    KWIKCASH_TRANSACTION_DETAIL_NO_DATA: "No Transaction to Display",
    KWIKCASH_TRANSACTION_DETAIL_DESCRIPTION1:
      "Start sharing your products with friends to ",
    KWIKCASH_TRANSACTION_DETAIL_DESCRIPTION2: "Earn Kwikcash",
    KWIKCASH_TRANSACTION_DETAIL_DESCRIPTION3:
      " Then cash out your earnings and they will show up here :)",
    KWIKCASH_VERIFY_NUMBER_TEXT_1:
      "Before continuing with 1Cash, please verify your",
    KWIKCASH_VERIFY_NUMBER_TEXT_2: " Email",
    KWIKCASH_VERIFY_NUMBER_BTN: "Verify Now",
    KWIKCASH_CASH_OUT_TIPS_SELECT_CARD: "Please select a bank card!",
    KWIKCASH_CASH_OUT_MINIMUM_AMOUNTS:
      "Minimum amount to cash out is {{currency}}{{amount}}!",
    KWIKCASH_CASH_OUT_TIPS_AMOUNT_INVALID:
      "You cannot cash out more than your available balance",
    KWIKCASH_CASH_OUT_TIPS_SENT_SUCCESS: "Code sent successfully",
    KWIKCASH_CASH_OUT_MODAL_TITLE: "Cash Out",
    KWIKCASH_CASH_OUT_TIPS_MSG1: "Please enter your 4 ",
    KWIKCASH_CASH_OUT_TIPS_MSG2: "Digit Code",
    KWIKCASH_CASH_OUT_TIPS_MSG3: " to Cash Out",
    KWIKCASH_CASH_OUT_TITLE_VERIFY: "Verify To Cash Out",
    KWIKCASH_CASH_OUT_TIPS_SENT_TO1: "Code is sent to ",
    KWIKCASH_CASH_OUT_TIPS_DONT_RECEIVE: "Didn't receive code? ",
    KWIKCASH_CASH_OUT_LINK_REQUEST_AGAIN: "Request Again",
    KWIKCASH_CASH_OUT_CONTENT_MSG1: "Select your ",
    KWIKCASH_CASH_OUT_CONTENT_MSG2: "Cash Out",
    KWIKCASH_CASH_OUT_CONTENT_MSG3:
      " method, then enter the amount and press transfer!",
    KWIKCASH_CASH_OUT_AVAILABLE_BALANCE: "Available Balance",
    KWIKCASH_CASH_OUT_SELECT_METHOD: "Select a Payout Method",
    KWIKCASH_CASH_OUT_TO_CITY_BANK: "Transfer Amount",
    KWIKCASH_CASH_OUT_TITLE_SUCCESS: "Transfer Successful!",
    KWIKCASH_CASH_OUT_SUCCESS_MSG1:
      "Your payment was successfully transferred. It will take ",
    KWIKCASH_CASH_OUT_SUCCESS_MSG2: "9-10 Working days",
    KWIKCASH_CASH_OUT_SUCCESS_MSG3: " to process.",
    KWIKCASH_VERIFY_ENTER_EMAIL: "Please Enter a valid Email",
    KWIKCASH_VERIFY_ENTER_4_CODE: "Please Enter 4 digits code",
    KWIKCASH_VERIFY_EMAIL: "Verify Email",
    KWIKCASH_VERIFY_TIPS_1:
      "We will send a code to your Email to make sure this Email is yours",
    KWIKCASH_VERIFY_TIPS_ENTER_EMAIL: "Please enter your Email",
    KWIKCASH_VERIFY_MAKE_SURE_EMAIL:
      "Please make sure you have entered correct Email.",
    KWIKCASH_VERIFY_CODE_SENT_TO: "Code is sent to ",
    KWIKCASH_VERIFY_ENTER_EMAIL_CHECK: "Email must be login Email",
    KWIKCASH_ADD_DIFFERENT_NUM: "The two account numbers are different",
    KWIKCASH_ADD_SELECT_PAYMENT: "Please select a Payment Type",
    KWIKCASH_ADD_ACCOUNT_ADDED: "Bank Account Added",
    KWIKCASH_ADD_ADDED_TIPS_1:
      "Looks like you're good to go! Your bank account will now show up as a cash out option! Enjoy your cash :)",
    KWIKCASH_ADD_ADDED_PAYPAL: "Paypal Account Added",
    KWIKCASH_ADD_ADDED_TIPS_PAYPAL_1:
      "Looks like you're good to go! Your paypal account will now show up as a cash out option! Enjoy your cash :)",
    KWIKCASH_ADD_VERIFY_TIPS_1: "Please enter your 4 ",
    KWIKCASH_ADD_VERIFY_TIPS_2: "Digit Code",
    KWIKCASH_ADD_VERIFY_TIPS_3: " to ",
    KWIKCASH_ADD_VERIFY_TIPS_ADD_PAYPAL: "Add Paypal",
    KWIKCASH_ADD_VERIFY_TIPS_ADD_BANK: "Add Bank",
    KWIKCASH_EDIT_BANK_ACCOUNT: "Edit Bank Account",
    KWIKCASH_EDIT_VERIFY_REALLY_YOU: "Verify this is really you",
    KWIKCASH_EDIT_PAYPAL_ACCOUNT: "Edit Paypal Account",
    KWIKCASH_EDIT_PAYPAL_MSG_1:
      "This information will be securely saved as per the ",
    KWIKCASH_EDIT_PAYPAL_MSG_2: "Terms of Service & ",
    KWIKCASH_EDIT_PAYPAL_MSG_3: "Privacy Policy.",
    KWIKCASH_EDIT_PAYPAL_VERIFY_MSG_1: "Please verify this is your below to ",
    KWIKCASH_EDIT_PAYPAL_VERIFY_MSG_2: "Edit a Paypal Account",
    KWIKCASH_EDIT_BANK_VERIFY_MSG_2: "Edit a Bank Account",
    KWIKCASH_TIPS_SELECT_COUNTRY: "Please Select a Country",
    KWIKCASH_TIPS_SELECT_CURRENCY: "Please Select a Currency",
    KWIKCASH_MANAGE_CASH_OUT_METHOD: "Manage Your Cash Out Methods",
    KWIKCASH_CASH_OUT_METHOD2: "Cashout Methods",
    KWIKCASH_ITS_EASY: "It’s super easy!",
    KWIKCASH_MANAGE_BTN_ADD: "+ Add",
    KWIKCASH_MANAGE_BTN_EDIT: "Edit",
    KWIKCASH_MANAGE_BTN_CANCEL: "Cancel",
    KWIKCASH_MANAGE_TIPS_SELECT_METHOD: "Select a cashout method to edit",
    KWIKCASH_MANAGE_REMOVE_PAYPAL: "Remove Paypal",
    KWIKCASH_MANAGE_REMOVE_CARD: "Remove Account",
    KWIKCASH_MANAGE_TO_REMOVE_PAYPAL: " to remove your Paypal",
    KWIKCASH_MANAGE_TO_REMOVE_CARD: " to remove your Account",
    KWIKCASH_MANAGE_VERIFY_REMOVE: "Verify To Remove ",
    KWIKCASH_MANAGE_VERIFY_TO: "Verify To ",
    KWIKCASH_MANAGE_WORD_PAYPAL: "Paypal",
    KWIKCASH_MANAGE_WORD_CARD: "Account",
    KWIKCASH_MANAGE_WORD_BANK: "Bank",
    KWIKCASH_MANAGE_ACCOUNT_REMOVED: " Account Removed",
    KWIKCASH_MANAGE_REMOVE_TIPS_1:
      "Once you remove card it will delete from the KWIK.",
    KWIKCASH_MANAGE_BANK_ACCOUNT_UPDATED: "Bank Account Updated",
    KWIKCASH_MANAGE_PAYPAL_ACCOUNT_UPDATED: "Paypal Account Updated",
    KWIKCASH_MANAGE_BTN_ADD_ACCOUNT: "Verify & Add Account",
    KWIKCASH_BTN_CONFIRM_NUMBER: "Confirm Number",
    KWIKCASH_BTN_CONFIRM_EMAIL: "Confirm Email",

    //6.26
    VENDOR_SETTINGS_UP: "Users & Permissions",
    VENDOR_SETTINGS_MWUCSODIYS:
      "Manage what users can see or do in your store.",
    VENDOR_SETTINGS_BRAND_OWER: "Brand Owner",
    VENDOR_SETTINGS_ADDS_MEMBERS_TIPS: "Added Members (Up to 15)",
    VENDOR_SETTINGS_NO_DATA_TIPS:
      "You haven't added any users. Please add users to view details",
    VENDOR_SETTINGS_LAST_LOGIN: "Last Login was",
    VENDOR_SETTINGS_ADD_USERS: "Add User",
    VENDOR_SETTINGS_EDIT_USERS: "Edit User",
    VENDOR_SETTINGS_ETU_GOVERNMENTID:
      "Enter the user's First Name & Last Name as they appear in government-issued ID.",
    VENDOR_SETTINGS_SELECT_PERMISSIONS: "Select permissions",
    VENDOR_SETTINGS_SELECT_ALL: "Select All",

    //7.5
    VERSION_DETAIL_01: "Vendor Bio",
    VERSION_DETAIL_02: "Removing Short Descriptions",
    VERSION_DETAIL_03: "Vendor Referral Information",
    VERSION_DETAIL_04: "No Names on Reviews",
    VERSION_DETAIL_05: "Edit Shopping Cart",
    VERSION_DETAIL_06: "Show Vendor Logo on Post Purchase Screen",
    VERSION_DETAIL_07: "Grammar Fixes",
    VERSION_DETAIL_08: "Showing Wrong Data",
    VERSION_DETAIL_09: "Show Product Specific Data",
    INVALID_LINK: "Invalid Link",

    // 8.10
    ORDER_LIST_ORDER_DATE: "Order Date",

    // vendor overview
    VENDOR_ACCEPTING_ORDERS_STEP_1: "STEP 1",
    VENDOR_ACCEPTING_ORDERS_STEP_2: "STEP 2",
    VENDOR_ACCEPTING_ORDERS_STEP_3: "STEP 3",
    VENDOR_ACCEPTING_ORDERS_STEP_4: "STEP 4",
    VENDOR_ACCEPTING_ORDERS_STEP_5: "STEP 5",
    VENDOR_ACCEPTING_ORDERS_STEP_6: "STEP 6",
    VENDOR_ACCEPTING_ORDERS_TITLE_1: "Setup where your products ship out of",
    VENDOR_ACCEPTING_ORDERS_TITLE_2: "Add products",
    VENDOR_ACCEPTING_ORDERS_TITLE_3: "Customize your campaigns",
    VENDOR_ACCEPTING_ORDERS_TITLE_4: "Set your shipping rates",
    VENDOR_ACCEPTING_ORDERS_TITLE_5: "Customize your brand",
    VENDOR_ACCEPTING_ORDERS_TITLE_6: "Connect Your Bank Account",
    VENDOR_ACCEPTING_ORDERS_MESSAGE_1:
      "This will make sure the quantity is tracked appropriately and for your shipping coordination. We already set one address as your business address. Update it if that is incorrect or you have more than one location.",
    VENDOR_ACCEPTING_ORDERS_MESSAGE_2:
      "Let us know which of your products you want to Kwikify and we will get that done! We just need some information on each product you want. Add that information with our Shopify Plugin, CVS Import, or Manually.",
    VENDOR_ACCEPTING_ORDERS_MESSAGE_3:
      "It’s easiest to have one campaign. What that means is when someone joins your campaign they have access to share all of your products. If you want to separate them feel free to do so!",
    VENDOR_ACCEPTING_ORDERS_MESSAGE_4:
      "This step is only required if you want to participate in the Kwik Marketplace. If you’re interested in that, just click here and we can show you some benefits. :) If not, just click through and all will be good! ",
    VENDOR_ACCEPTING_ORDERS_MESSAGE_5:
      "We need some of your cool brand designs to make your customers experience in Kwik even better. Give us some colors, images, bio, etc.",
    VENDOR_ACCEPTING_ORDERS_MESSAGE_6:
      "We need your banking information, so you can get paid.",
    VENDOR_ACCEPTING_ORDERS_BTN_11_NAME: "Edit Warehouse",
    VENDOR_ACCEPTING_ORDERS_BTN_21_NAME: "Add Products",
    VENDOR_ACCEPTING_ORDERS_BTN_31_NAME: "Customize Campaigns",
    VENDOR_ACCEPTING_ORDERS_BTN_41_NAME: "Set Shipping Rates",
    VENDOR_ACCEPTING_ORDERS_BTN_42_NAME: "I’m not interested",
    VENDOR_ACCEPTING_ORDERS_BTN_51_NAME: "Customize my Brand",
    VENDOR_ACCEPTING_ORDERS_BTN_61_NAME: "Connect Your Bank Account",
    VENDOR_ACCEPTING_ORDERS_BTN_PREVIOUS: "Previous Step",

    VENDOR_ACCEPTING_ORDERS_MENU_1: "Customize Your Warehouses",
    VENDOR_ACCEPTING_ORDERS_MENU_2: "Add Products",
    VENDOR_ACCEPTING_ORDERS_MENU_3: "Customize Your Campaigns",
    VENDOR_ACCEPTING_ORDERS_MENU_4: "Setup Your Shipping Rates",
    VENDOR_ACCEPTING_ORDERS_MENU_5: "Customize Your Brand",
    VENDOR_ACCEPTING_ORDERS_MENU_6: "Connect your Bank Account",
    VENDOR_ACCEPTING_ORDERS_TEXT_OF_6: " of 6",
    VENDOR_ACCEPTING_ORDERS_TEXT_COMPLETED: " Completed",
    VENDOR_ANALYTIC_REPORT_TITLE: "Analytic Report",
    VENDOR_ANALYTIC_REPORT_TITLE_1: "Total Sales",
    VENDOR_ANALYTIC_REPORT_TITLE_2: "Total Orders",
    VENDOR_ANALYTIC_REPORT_TITLE_3: "New Customers",
    VENDOR_ANALYTIC_REPORT_TITLE_4: "Sharing Customers",
    VENDOR_ANALYTIC_REPORT_TIPS_1: "-----",
    VENDOR_OVERVIEW_COMPLETE_STEP_TITLE: "Complete the setup!",
    VENDOR_OVERVIEW_COMPLETE_STEP_TAX_RATES_TITLE: "Set up your tax rates",
    VENDOR_OVERVIEW_COMPLETE_STEP_TAX_RATES_MESSAGE:
      "This feature is coming soon. For now, US vendors - your taxes are automatically set by Kwik. All other vendors, adjust tax rates per individual product in the add/edit product section.",
    VENDOR_OVERVIEW_COMPLETE_STEP_TAX_RATES_BTN_NAME: "Set Tax Rates",
    VENDOR_OVERVIEW_COMPLETE_STEP_ADD_TITLE: "Add Ambassadors",
    VENDOR_OVERVIEW_COMPLETE_STEP_ADD_MESSAGE:
      "Send your loyal customers a link so they can begin sharing for your brand!",
    VENDOR_OVERVIEW_COMPLETE_STEP_ADD_BTN_NAME: "Add Users",
    VENDOR_OVERVIEW_CUSTOMIZATION_TITLE: "Customization",
    VENDOR_OVERVIEW_CUSTOMIZATION_BRAND_TITLE: "Customize Your Brand",
    VENDOR_OVERVIEW_CUSTOMIZATION_BRAND_MESSAGE:
      "Update the colors and logos you want users to see. Make your brand yours.",
    VENDOR_OVERVIEW_CUSTOMIZATION_BRAND_BTN_NAME: "Let's Customize",
    VENDOR_OVERVIEW_CUSTOMIZATION_FINANCES_TITLE: "Add Your Finances",
    VENDOR_OVERVIEW_CUSTOMIZATION_FINANCES_MESSAGE:
      "Make sure you can get your money for sales and have a method to pay those selling your products!",
    VENDOR_OVERVIEW_CUSTOMIZATION_FINANCES_BTN_NAME: "Update Now",
    VENDOR_OVERVIEW_EARNERS_TITLE: "Top Earners",
    VENDOR_OVERVIEW_TABLE_TITLE: "Top Selling Products",
    VENDOR_OVERVIEW_TABLE_HEADER_1: "Image",
    VENDOR_OVERVIEW_TABLE_HEADER_2: "Product Name",
    VENDOR_OVERVIEW_TABLE_HEADER_3: "Inventory",
    VENDOR_OVERVIEW_TABLE_HEADER_4: "Price",
    VENDOR_OVERVIEW_TABLE_HEADER_5: "Sales",
    VENDOR_OVERVIEW_TABLE_HEADER_6: "Kwikcash Value",
    VENDOR_OVERVIEW_TABLE_HEADER_7: "Customers",
    VENDOR_OVERVIEW_TABLE_HEADER_8: "Active Earners",
    VENDOR_OVERVIEW_TABLE_HEADER_9: "Commissions Paid",
    "": "",

    //8.17
    SHOP_BY_CATEGORY: "Shop by Categories",
    NEWEST_PRODUCTS: "Newest Products",
    PRODUCT_YOU_MIGHT_LIKE: "Product You Might Like",
    HIGHEST_KCASH: "Highest 1Cash",
    HIGHEST_INS_CASHBACK: "Highest Instant Cashback",
    SEE_ALL: "See All",
    NEW_BRANDS_ON_KWIK: "New Brands on Kwik",
    SHOP_BY_BRAND: "Shop By Brand",
    SHOPPING_EMAIL_TITLE1: "Want To Find Out When New Brands Are Launching?",
    SHOPPING_EMAIL_DESC1:
      "Get notifications when the new brands are launching on Kwik and be the first to get a linK!",
    SHOPPING_EMAIL_TITLE2: "Your Recommendation Goes Far",
    SHOPPING_EMAIL_DESC2:
      "Finding a product you love is the first step in making a recommendation!",
    SHOPPING_EMAIL_DESC3:
      "Find a product that you love to use and Kwik will make it easy to recommend it to your friends and to get paid on that recommendation!",
    SEARCH_PRODUCT: "Search Product",
    ALL: "All",
    RECENT_PURCHASES: "Recent Purchases",
    RECENTLY_PURCHASED_PRODUCTS: "Recently purchased products",
    PAGE: "Page",
    OF: "of",
    PREV: "Prev",
    NEXT: "Next",
    TOP_SHARED_PRODUCTS: "Top Shared Products",
    YOUR_PRODUCTS: "Your Products",
    MOST_EARNED: "Most Earned",
    MOST_SHARED: "Most Shared",
    SEARCH: "Search",
    SEARCH_BY_PRODUCT_NAME: "Search by Product Name",

    CAMPAIGNS_YOU_HAVENT_JOINED_TEXT: "You haven’t joined any campaigns yet :(",
    CAMPAIGNS_FIND_PRODUCTS_OR_CONNECT_TEXT:
      "Find products or connect with brands to get links!",
    CAMPAIGNS_FIND_THE_BRANDS_TEXT: "Find the Brands",
    CAMPAIGNS_SHARES: "Shares",
    CAMAPIGNS_NO_DATA: "No Data",
    CAMPAIGNS_LOOKS_LIKE_YOU_HAVE_MESSAGE:
      "Looks like you have not made any referrals or recommendations yet.",
    CAMPAIGNS_SHARE_SOMETHING: "Share Something",
    CAMPAIGNS_LEARN_MORE_AT: "Learn more at",
    CAMPAIGNS_SALES_FROM: "Sales from",

    VERSION_DETAIL_01_3_0_5_1: "Fixed: Typos, grammar, misspellings.  Again.",
    VERSION_DETAIL_02_3_0_5_1:
      "Added: Time buttons on the Kwikcash page to filter Kwikcash transactions -pending & available- by time period.",
    VERSION_DETAIL_03_3_0_5_1:
      "Improved: We’ve made our messages displaying when a brand is no longer on Kwik more clear.",
    VERSION_DETAIL_04_3_0_5_1:
      "Fixed: When an unverified purchaser shares a link, the Click Here link in the pop up goes to the proper place now.",
    VERSION_DETAIL_05_3_0_5_1:
      "Improved: Recent additions are campaign specific now.",
    VERSION_DETAIL_06_3_0_5_1:
      "Improved: We cleaned up some of the data & UI in the waves sections of campaign data.",
    VERSION_DETAIL_07_3_0_5_1:
      "Fixed: Returned orders weren’t showing in order history, but we brought them back so your order history is complete.",
    VERSION_DETAIL_08_3_0_5_1:
      "Added: We’re growing!  We added three new shopping categories: dietary supplements, entertainment, & kids/baby",
    VERSION_DETAIL_09_3_0_5_1:
      "Improved: We changed the look of our share link preview in text messages and Emails.",
    VERSION_DETAIL_10_3_0_5_1: "Fixed: Typos, grammar, misspellings.",
    VERSION_DETAIL_11_3_0_5_1:
      "Fixed: We updated our pricing calculations so negative payouts based on brand input are no longer possible.",
    VERSION_DETAIL_12_3_0_5_1:
      "Improved: Added some sorting functions so you’ll be able to find the Ambassadors you’re looking for easier.",
    VERSION_DETAIL_13_3_0_5_1:
      "Improved: The flow for removing a bank account on the finance page is better.",
    VERSION_DETAIL_14_3_0_5_1:
      "Improved: You’ll only see your brand’s data in an Ambassador’s details.",
    VERSION_DETAIL_15_3_0_5_1:
      "Improved: When an Ambassador is sent a link to join a brand, but already as a Kwik account, they’ll stay on the co-branded screen to log in.",
    VERSION_DETAIL_16_3_0_5_1:
      "Fixed - Searching for an Ambassador will no longer cause never-ending load time.",
    VERSION_DETAIL_17_3_0_5_1: "Fixed - Typos, misspellings, etc..  Always.",

    VERSION_DETAIL_01: "Various grammar, punctuation, spelling fixes",
    VERSION_DETAIL_02: "App: Shopping link moved a bit",
    VERSION_DETAIL_03:
      "App: Images are showing in order history and details now",
    VERSION_DETAIL_04: "App: Recent shares on the dashboard updating properly",
    VERSION_DETAIL_05: "App: Shopping Cart Icon Changes",
    VERSION_DETAIL_06:
      "App: Wave Images are now User Initials Instead of Wave Numbers",
    VERSION_DETAIL_07:
      "Brands: the Kwikification Status is unable to be changed for now",
    VERSION_DETAIL_08: "Brands: Registration steps accurate & clear",
    VERSION_DETAIL_09:
      "Brands: Country Will have outline when adding product to that country",
    VERSION_DETAIL_10:
      "Brands Fixed the issue with adding a product within a long description",
    VERSION_DETAIL_11:
      "Brands: A Little Fix to the Menu - it’s looking and working better than ever.",
    VERSION_DETAIL_12:
      "Mobile: the background color extends all the way to the top of the screen in the app",
    VERSION_DETAIL_13: "Mobile: the menu has been fixed",

    // 用户设置-account settings
    USER_SETTING_ACCOUNT_SETTINGS: "Account Settings",
    USER_SETTING_UPDATE_PROFILE: "Update Profile",
    USER_SETTING_ACCOUNT_INFO: "Account info",
    USER_SETTING_PUBLIC_ACCOUNT_NAME: "Public Account Name",
    USER_SETTING_PUBLIC_ACCOUNT_NAME_IS_REQUIRED:
      "Public Account Name is required",
    USER_SETTING_USERNAME: "Username",
    USER_SETTING_PUBLIC_ACCOUNT_USERNAME_IS_REQUIRED:
      "Public Account Username is required",
    USER_SETTING_CONTACT_DETAIL: "Contact Detail",
    USER_SETTING_EMAIL_ADDRESS: "Email Address",
    USER_SETTING_BIRTHDAY: "Birthday",
    USER_SETTING_UPDATE_EMAIL_ADDRESS: "Update Email Address",
    USER_SETTING_UPDATE: "Update",
    USER_SETTING_PHONE_NUMBER: "Phone Number",
    USER_SETTING_UPDATE_PHONE_NUMBER: "Update Phone Number",
    USER_SETTING_LOGIN: "Login",
    USER_SETTING_PASSWORD: "Password",
    USER_SETTING_LAST_UPDATED_AGO: "Last updated {{lastUpdatePwd}} ago",
    USER_SETTING_UPDATE_PASSWORD: "Update Password",
    USER_SETTING_LANGUAGE: "Language",
    USER_SETTING_UPDATE_LANGUAGE: "Update Language",
    USER_SETTING_COUNTRY: "Country",
    USER_SETTING_UPDATE_COUNTRY: "Update Country",
    USER_SETTING_ACCOUNT_DETAILS: "Account Details",
    USER_SETTING_THESE_CAN_BE_USED_TO_MAKE_MESSAGE:
      'These can be used to make sure that it\'s really you signing in or to contact you. To update these details contact our <a target="_blank" href="mailto:support@wer1.com">Customer Support</a>',
    USER_SETTING_LEGAL_NAME: "Legal Name",
    USER_SETTING_RECENT_LOGIN: "Recent Login",
    USER_SETTING_NUM_SESSION_ON_SYSTEM_COMPUTER1:
      "{{sessionNum}} Session on {{system}} Computer",
    USER_SETTING_NUM_SESSION_ON_SYSTEM_COMPUTER2:
      "{{sessionNum}} Sessions on {{system}} Computer",
    USER_SETTING_NUM_SESSION_ON_SYSTEM_MOBILE1:
      "{{sessionNum}} Session on {{system}} mobile phone",
    USER_SETTING_NUM_SESSION_ON_SYSTEM_MOBILE2:
      "{{sessionNum}} Sessions on {{system}} mobile phone",
    USER_SETTING_CHANGE_PASSWORD: "Change Password",
    USER_SETTING_ENTER_THE_DETAILS_BELOW_MESSAGE:
      "Enter the details below to <span>Change password</span> on WeR1.",
    USER_SETTING_ENTER_CURRENT_PASSWORD: "Enter Current Password",
    USER_SETTING_CURRENT_PASSWORD_IS_REQUIRED: "Current Password is required",
    USER_SETTING_INCORRECT_PASSWORD_FORMAT: "Incorrect password format",
    USER_SETTING_YOUR_NEW_PASSWORD: "Your New Password",
    USER_SETTING_NEW_PASSWORD_IS_REQUIRED: "New Password is required",
    USER_SETTING_RE_ENTER_YOUR_NEW_PASSWORD: "Re-Enter Your New Password",
    USER_SETTING_INCONSISTENT_PASSWORDS: "Inconsistent passwords",
    USER_SETTING_THIS_INFORMATION_WILL_BE_MESSAGE:
      'This information will be securely saved as per the <span><a href="https://cms.kwik.com/Terms%26ConditionsWeR1.pdf" target="_blank"><u>Terms of Service</u></a> & <a href="https://cms.kwik.com/PrivacyPolicyWeR1.pdf.pdf" target="_blank"><u>Privacy Policy </u></a></span>',
    USER_SETTING_BACK: "Back",
    USER_SETTING_PASSWORD_SUCCESSFULLY_CHANGED: "Password Successfully Changed",
    USER_SETTING_YOU_CAN_NOW_USE_YOUR_MESSAGE:
      "You can now use your new password to <span>Log in to your account</span>",
    USER_SETTING_CHOOSE_A_LANGUAGE: "Choose a Language",
    USER_SETTING_YOUR_LANGUAGE_WILL_MESSAGE:
      "Your language will save the next time you load Kwik.",
    USER_SETTING_DEFAULT: "Default",
    USER_SETTING_SAVE: "Save",
    USER_SETTING_CHOOSE_A_COUNTRY: "Choose a Country",
    USER_SETTING_YOUR_COUNTRY_WILL_MESSAGE:
      "Your Country will save the next time you load Kwik.",
    // 用户设置-菜单
    USER_SETTING_MENU_SAVED_INFORMATION: "Saved Information",
    USER_SETTING_MENU_ADDRESSES: "Addresses",
    USER_SETTING_MENU_CASH_OUT_METHODS: "Cash Out Methods",
    USER_SETTING_MENU_PRIVACY_SETTINGS: "Privacy Settings",
    USER_SETTING_MENU_NOTIFICATION: "Notification",
    // 用户设置-地址
    USER_SETTING_ADD_ADDRESS: "Add Address",
    USER_SETTING_ZIP_CODE: "Zip code",
    USER_SETTING_PHONE_NUMBER: "Phone number",
    USER_SETTING_EDIT: "Edit",
    USER_SETTING_REMOVE: "Remove",
    USER_SETTING_SUBMIT: "Submit",
    USER_SETTING_COUNTRY: "Country",
    USER_SETTING_PHONE_NUMBER2: "Phone Number",
    USER_SETTING_PHONE_NUMBER_IS_REQUIRED: "Phone Number is required",
    USER_SETTING_NAME: "Name",
    USER_SETTING_NAME_IS_REQUIRED: "Name is required",
    USER_SETTING_ADDRESS: "Address",
    USER_SETTING_ADDRESS_IS_REQUIRED: "Address is required",
    USER_SETTING_APARTIMENT_SUITE_ETC_OPTIONAL:
      "Apartment, Suite, Etc. (Optional)",
    USER_SETTING_CITY: "City",
    USER_SETTING_CITY_IS_REQUIRED: "City is required",
    USER_SETTING_STATE: "State",
    USER_SETTING_STATE_IS_REQUIRED: "State is required",
    USER_SETTING_POSTAL_CODE: "Postal Code",
    USER_SETTING_POSTAL_CODE_IS_REQUIRED: "Postal Code is required",
    USER_SETTING_EMAIL: "Email",
    USER_SETTING_EMAIL_IS_REQUIRED: "Email is required",
    USER_SETTING_GET_STARTED_BY_ADDING_MESSAGE:
      "Get started by adding your first <span>cash out</span> method!",
    USER_SETTING_ADD_METHOD: "Add Method",
    // 用户设置-privacy
    USER_SETTING_SHOW_MY_FULL_NAME_IN_WAVES: "Show My Full Name In Waves",
    USER_SETTING_ALLOW_USERS_TO_SEE_MESSAGE:
      "Allow users to see my full name in Waves",
    USER_SETTING_ALLOW_OTHER_USERS_TO_MESSAGE_ME:
      "Allow Other Users To Message Me",
    USER_SETTING_COMING_SOON: "Coming soon",
    USER_SETTING_SUBSCRIBE_TO_KWIK_NEWSLETTER: "Subscribe to Kwik Newsletter",
    USER_SETTING_ALL_WE_DO_IS_LET_MESSAGE:
      "All we do is let you know when we make your life better!",
    USER_SETTING_SUBSCRIBE_TO_NEWSLETTERS:
      "Subscribe To Newsletters From Vendors I Have Purchased From",
    USER_SETTING_AUTOMATICALLY_GET_MESSAGE:
      "Automatically get signed up for newsletters from the brands you bought from.",
    USER_SETTING_ALLOW_OTHER_USERS_MESSAGE:
      "Allow Other Users To See My Profile Photo In Their Waves",
    USER_SETTING_IF_ON_OTHERS_CAN_MESSAGE:
      "If on, others can see your profile photo in their waves.",
    USER_SETTING_DOWNLOAD_MY_DATA: "Download My Data",
    USER_SETTING_YOU_CAN_DOWNLOAD_MESSAGE:
      "You can download a copy of your data at any time",
    USER_SETTING_REQUEST_TO_DOWNLOAD_MU_DATA: "Request to Download My Data",
    USER_SETTING_DELETE_MY_DATA: "Delete My Data",
    USER_SETTING_YOU_CAN_DELETE_MESSAGE: "Delete the data stored in my account",
    // 用户设置-Notification Settings
    USER_SETTING_NOTIFICATION_SETTINGS: "Notification Settings",
    USER_SETTING_NOTIFY_ME_ON: "Notify Me On",
    USER_SETTING_FINANCE: "Finance",
    USER_SETTING_CASHOUT_ALERTS: "Cashout Alerts",
    USER_SETTING_NOTIFY_ME_ON_EVERY_CASHOUT: "Notify me on every cash out",
    USER_SETTING_NEW_BANK_ACCOUNT_ADDED: "New Bank Account Added",
    USER_SETTING_NOTIFY_ME_WHEN_A_NEW_BANK_ACCOUNT_IS_ADDED:
      "Notify me when a new bank account is added",
    USER_SETTING_KWIKCASH_CLEARS: "1Cash Clears",
    USER_SETTING_WAVES: "Waves",
    USER_SETTING_WHEN_SOMEONE_JOINS_MY_WAVES: "When Someone Joins my Waves",
    USER_SETTING_NOTIFY_ME_EVERY_TIME_SOMEONE_JOINS_MY_WAVES:
      "Notify me every time someone joins my waves",
    USER_SETTING_MARKETING: "Marketing",
    USER_SETTING_SIGN_ME_UP_FOR_KWIK_NEWSLETTER:
      "Sign me up for Kwik Newsletter",
    USER_SETTING_GET_UPDATES_ON_THE_RELEASES_MESSAGE:
      "Get updates on the releases, and get important updates that might impact your account",
    USER_SETTING_ALLOW_BRANDS_MESSAGE:
      "Allow Brands to Send Me Their Newsletter",
    USER_SETTING_ALLOW_THE_BRANDS_OF_THE_MESSAGE:
      "Allow the brands of the campaigns I am in to send me newsletters by default",
    USER_SETTING_SHOPPING: "Shopping",
    USER_SETTING_NEW_BRANDS_ON_KWIK: "New Brands on Kwik",
    USER_SETTING_TRENDING_DEALS: "Trending Deals",
    USER_SETTING_SETTINGS: "Settings",
    USER_SETTING_ANY_SETTINGS_CHANGES_ARE_MADE: "Any settings changes are made",
    USER_SETTING_NOTIFY_ME_WHEN_ANY_SETTINGS_ARE_CHANGED:
      "Notify me when any settings are changed",
    USER_SETTING_LANGUAGE_CHANGED: "Language Changed",
    USER_SETTING_NOTIFY_ME_WHEN_THE_LANGUAGE_CHANGES:
      "Notify me when the language changes",
    // 注册字段
    USER_REGISTER_LEGAL_NAME: "Legal Name",
    USER_REGISTER_PLEASE_INPUT_YOUR_ADDRESS_LEGAL_NAME:
      "Please input your Address Legal Name",

    // 10.24
    INVENTORY_SKU_NAME: "SKU Name",

    // 10.27
    PRODUCT_DETAIL_TEMPORARY_UNAVAILABLE: "Temporary Unavailable",

    // 10.28
    SHARELINK_CART_GUEST_CHECKOUT: "Guest Checkout",
    SHARELINK_CART_CREATE_KWIK_ACCOUNT_AND_CHECKOUT:
      "Create Kwik Account and Checkout",
    SHARELINK_CART_MEMBER_CHECKOUT: "Member Checkout",

    // 11.1
    SHARELINK_PRODUCT_DETAIL_NON_VERIFIED_PURCHASER: "Non-Verified Purchaser",
    SHARELINK_PRODUCT_DETAIL_HAS_NOT_PURCHASED_MESSAGE:
      "Has not purchased from the brand, but has received approval by the brand or Wer1 to share from campaign . Limited permissions, including inability to give rating. ",
    SHARELINK_PRODUCT_DETAIL_HAS_PURCHASED_THE_PRODUCT_MESSAGE:
      "Has purchased the product or brand has granted verified status. Includes full sharing capabilities and feature use, including rating products. ",

    // 11.12
    SHARELINK_CART_DEMO_CHECKOUT: "Checkout",
    LEARN_ABOUT_KWIK_PROFILE_SECTION: "Learn About Wer1",
    KWIKCASH_BALANCE_PROFILE_SECTION: "1Cash Balance",
    HEADER_LAYER_EDIT_PROFILE: "Edit Profile",

    // Ambassadors
    AMBASSADORS_GET_STARTED_TITLE: "Get Started with Wer1",
    AMBASSADORS_GET_STARTED_INVITE_TEXT:
      "Only a couple people to invite? Send them an email and get them a link.",
    AMBASSADORS_GET_STARTED_INVITE_TITLE: "Invite Ambassadors",
    AMBASSADORS_GET_STARTED_SIGN_UP_TITLE: "Sign Up Links",
    AMBASSADORS_GET_STARTED_SIGN_UP_TEXT:
      "Create a direct sign up link that you can share with your customers to make them ambassadors!",
    AMBASSADORS_TOAST_COPY_FAILED: "Copy Failed",
    AMBASSADORS_TOAST_SELECT_CAMPAIGN: "Please select Campaign",
    AMBASSADORS_TOAST_SELECT_IMAGE: "Please select Image",
    AMBASSADORS_TOAST_SUCCESS: "Success",
    AMBASSADORS_TOAST_REMOVE_SUCCESS: "Remove Success",
    AMBASSADORS_TOAST_ASSIGN_SUCCESS: "assign success",
    AMBASSADORS_TOAST_SELECT_AMBASSADORS: "Please select Ambassadors",
    AMBASSADORS_TOAST_SELECT_VENDOR: "Please select a vendor",
    AMBASSADORS_TOAST_ADD_EMAIL: "Please Add Email",
    AMBASSADORS_ADD_TEXT: "Add Ambassador",
    AMBASSADORS_EXPORT_TEXT: "Export",
    AMBASSADORS_NO_DATA_TITLE: "Ambassadors",
    AMBASSADORS_NO_DATA_TIPS: "No Ambassadors Yet",
    AMBASSADORS_NO_DATA_DESCRIPTION:
      "We will automatically give your customers links, but here are your other ambassadors and you can see what they’re sharing!",
    AMBASSADORS_DATA_TABLE_TITLE_AMBASSADORS: "Ambassadors",
    AMBASSADORS_DATA_TABLE_TITLE_STATUS: "Ambassador Status",
    AMBASSADORS_DATA_TABLE_TITLE_CAMPAIGN_STATUS: "Campaign Status",
    AMBASSADORS_DATA_TABLE_TITLE_EARNINGS: "Earnings",
    AMBASSADORS_DATA_TABLE_TITLE_SALES_GENERATED: "Sales Generated",
    AMBASSADORS_DATA_TABLE_TITLE_DIRECT_REFERRALS: "Direct Referrals",
    AMBASSADORS_DATA_TABLE_TITLE_INDIRECT_REFERRALS: "Indirect Referrals",
    AMBASSADORS_DATA_TABLE_TITLE_CAMPAIGNS: "Campaigns",
    AMBASSADORS_FILTER_SELECTED_TEXT: "Selected",
    AMBASSADORS_FILTER_ALL_TEXT: "All",
    AMBASSADORS_BUTTON_REMOVE_AMBASSADORS: "Remove Ambassadors",
    AMBASSADORS_BUTTON_ASSIGN_STATUS: "Assign a Status",
    AMBASSADORS_BUTTON_MORE_ACTIONS: "More Actions",
    AMBASSADORS_BUTTON_CANCEL: "Cancel",
    AMBASSADORS_BUTTON_COPY_LINK: "Copy Link",
    AMBASSADORS_BUTTON_REMOVE: "Remove",
    AMBASSADORS_BUTTON_INVITE: "Invite",
    AMBASSADORS_BUTTON_SAVE: "SAVE",
    AMBASSADORS_BUTTON_INVITE_USERS: "Invite Users",
    AMBASSADORS_TITLE_SELECT_CAMPAIGNS: "Select Campaigns",
    AMBASSADORS_TITLE_SEND_EMAIL_TO_AMB: "Send Email to Ambassador",
    AMBASSADORS_MESSAGE_SEND_EMAIL_TO_AMB:
      "Send An Email Now to Invite Ambassador to Your Campaigns",
    AMBASSADORS_MESSAGE_SELECT_CAMPAIGNS:
      "Set the default status of those who come in through your link. You can adjust at any time or adjust an individual ambassador on the “Ambassador” page.",
    AMBASSADORS_TITLE_CAMPAIGN_NAME: "Campaign Name",
    AMBASSADORS_TITLE_UPLOAD_IMAGE: "Image",
    AMBASSADORS_TITLE_SIGN_UP_LINK: "Sign Up Link",
    AMBASSADORS_TITLE_UPLOAD_TEXT: "Upload",
    AMBASSADORS_TITLE_UPLOAD_IMAGE_TEXT:
      "Add an Image: This is the image that users will see when signing up. Copy then paste the link to see a preview.",
    AMBASSADORS_TITLE_DROP_TIPS: "Drag & Drop, or click to Browse",
    AMBASSADORS_TITLE_UPLOAD_SUGGEST_1: "PNG and JPEG only",
    AMBASSADORS_TITLE_UPLOAD_SUGGEST_2:
      "Suggested size: 637px Wide x 1024px High",
    AMBASSADORS_TITLE_SHARE_SIGN_UP_LINK: "Share Sign Up Link",
    AMBASSADORS_MESSAGE_SIGN_UP_LINK:
      "Want to get people into your campaign quickly? Share this sign-up link and anyone who completes the sign-up will gain access to your campaign!",
    AMBASSADORS_MESSAGE_LEARN_1: "To learn more about this feature, ",
    AMBASSADORS_MESSAGE_LEARN_2: "click here",
    AMBASSADORS_MESSAGE_COPY_LINK:
      "This is your unique link. You can put this on your website, social, or anywhere.",
    AMBASSADORS_FILTER_CAMPAIGNS: "Campaign(s)",
    AMBASSADORS_DETAIL_TOTAL_GENERATED: "Total Sales Generated",
    AMBASSADORS_DETAIL_TOTAL_REFERRALS: "Total Referrals",
    AMBASSADORS_DETAIL_TOTAL_DIRECT_REFERRALS: "Total Direct Referrals",
    AMBASSADORS_DETAIL_TOTAL_EARNED: "Total Earned",
    AMBASSADORS_DETAIL_SINCE: "Ambassador For ",
    AMBASSADORS_DETAIL_NOTE_BTN_ADD: "Add Notes",
    AMBASSADORS_DETAIL_NOTE_BTN_EDIT: "Edit",
    AMBASSADORS_DETAIL_NOTE_BTN_SAVE: "Save",
    AMBASSADORS_DETAIL_NOTE_BY: "Note by: ",
    AMBASSADORS_DETAIL_NOTE_NO_DATA_TEXT: "No Note about Ambassador",
    AMBASSADORS_DETAIL_NOTE_CONTENT: "Note Content",
    AMBASSADORS_DETAIL_WAVES_TITLE: "Waves",
    AMBASSADORS_DETAIL_WAVES_NO_DATA_TITLE: "No Wave Data",
    AMBASSADORS_DETAIL_WAVES_NO_DATA_MESSAGE:
      "User must generate an additional sale",
    AMBASSADORS_DETAIL_WAVES_NO_DATA_EXPLORE: "Explore Products",
    AMBASSADORS_DETAIL_WAVES_EARNING: "Earning",
    AMBASSADORS_DETAIL_WAVES_MEMBERS: "Members",
    AMBASSADORS_DETAIL_WAVES_SHOW_ALL: "Show All",
    AMBASSADORS_DETAIL_WAVES_SALES_FROM: "Sales From ",
    AMBASSADORS_DETAIL_IMPACTFUL_TITLE: "Most Impactful Recommendations",
    AMBASSADORS_DETAIL_IMPACTFUL_VIEW_ALL: "View All",
    AMBASSADORS_DETAIL_LAST_SIGN_IN_ON: "Last Sign in On  ",
    AMBASSADORS_DETAIL_SIGN_IN_DEFAULT: "User Has Not Yet Signed In",
    AMBASSADORS_DETAIL_LAST_SHARE_DATE: "Last Share Date",
    AMBASSADORS_DETAIL_LAST_SHARE_DEFAULT: "User Has Not Shared Yet",
    AMBASSADORS_DETAIL_TOTAL_SHARES: "Total Shares",
    AMBASSADORS_DETAIL_CONVERSION_RATE: "Conversion Rate",
    AMBASSADORS_DETAIL_CONVERSION_DEFAULT: "User Has Not Shared Yet",
    AMBASSADORS_DETAIL_TOP_PLATFORM: "Top Performing Platform",
    AMBASSADORS_DETAIL_TOP_DEFAULT: "User Has Not Shared Yet",
    AMBASSADORS_DETAIL_RECENT_SHARES_TITLE: "Recent Shares",
    AMBASSADORS_DETAIL_RECENT_SHARES_TABLE_HEAD_DATE: "Date",
    AMBASSADORS_DETAIL_RECENT_SHARES_TABLE_HEAD_PRODUCT: "Product",
    AMBASSADORS_DETAIL_RECENT_SHARES_TABLE_HEAD_PLATFORM: "Platform",
    AMBASSADORS_DETAIL_RECENT_SHARES_TABLE_HEAD_OPENED: "Views",
    AMBASSADORS_DETAIL_RECENT_SHARES_TABLE_HEAD_CONVERTED: "Converted",
    AMBASSADORS_DETAIL_RECENT_SHARES_TABLE_HEAD_SIGNED_UP: "Signed Up",

    FINANCE_304_VERIFY_CODE_TITLE: "Verify this is really you",
    FINANCE_304_VERIFY_CODE_MESSAGE_1:
      "We have just sent a verification code to ",
    FINANCE_304_VERIFY_CODE_MESSAGE_2: "registered email with the ",
    FINANCE_304_VERIFY_CODE_REQUEST_AGAIN_1: "Didn’t receive code?  ",
    FINANCE_304_VERIFY_CODE_REQUEST_AGAIN_2: "Request Again",
    FINANCE_304_VERIFY_CODE_BOTTOM_MESSAGE:
      "Contact Kwik Support if you are unable to receive your verification code support@wer1.com",
    FINANCE_304_OVER_VIEW_TIPS_BALANCE:
      "Amount currently available for transfer.",
    FINANCE_304_OVER_VIEW_TIPS_PENDING:
      "Balance in pending until all brand return periods are complete. Minimum 7 day pending period.",
    FINANCE_304_OVER_VIEW_TIPS_COMMISSION:
      "Balance owed for incentives due to users for referrals and ecommerce store sales.",
    // 12.6
    PRODUCT_DETAIL_THIS_VARIANT_IS_NOT_AVAILABLE_MESSAGE:
      "This selection is not available now. Please check back later.",
    // 12.16
    CHECKOUT_OUR_RECORDS_SHOW_THAT_THIS_EMAIL_HAS_ALREADY:
      "Our records show that this Email has already been registered with WER1.  Please use the Login at the top of the page.",

    // 1.12
    SHARELINK_ADD_CART_PLEASE_SELECT_PRODUCTS_FIRST:
      "Please select products first",

    // 1.16
    CHECKOUT_POST_ORDER_ERROR_MESSAGE:
      "We could not process your payment.  Please check your card information and try again.  If error persists, please try a different payment method.",

    //1.18
    PRODUCT_OVERVIEW: "Product Overview",
    NO_DATA_TITLE: "No Product Added",
    NO_DATA_TIPS: "Get Started Selling on Kwik By Adding Some Products Now",

    // 2.10
    USER_SETTING_FIRST_NAME: "First Name",
    USER_SETTING_FIRST_NAME_IS_REQUIRED: "First Name is required",
    USER_SETTING_LAST_NAME: "Last Name",
    USER_SETTING_LAST_NAME_IS_REQUIRED: "Last Name is required",
    CHECKOUT_WHATS_YOUT_DELIVERY_INFORMATION:
      "What's Your Delivery Information?",
    CHECKOUT_SELECT_ADDRESS: "Select Address",
    CHECKOUT_DONE: "Done",
    CHECKOUT_EDIT_ADDRESS: "Edit address",
    CHECKOUT_ADD_A_NEW_ADDRESS: "Add a new Address",
    CHECKOUT_ENTER_YOUR_NAME_AND_ADDRESS: "Enter your Name and Address:",
    CHECKOUT_LET_US_KNOW_WHERE_MESSAGE:
      "Let us know where we need to send the product!",

    // 3.3
    NOTIFICATION_NAV_HOME: "Home",
    NOTIFICATION_NAV_NOTIFICATIONS: "Notifications",
    NOTIFICATION_FILTERS: "Filters",
    NOTIFICATION_CHECKBOX_ALL: "All",
    NOTIFICATION_CHECKBOX_UNREAD: "Unread",
    NOTIFICATION_CHECKBOX_READ: "Read",
    NOTIFICATION_BUTTON_APPLY_FILTER: "Apply Filter",
    NOTIFICATION_BUTTON_APPLY_CLEAR: "Clear",
    NOTIFICATION_SELECT_RECENT: "Recent",
    NOTIFICATION_SELECT_NEW: "New",
    NOTIFICATION_SELECT_THIS_YEAR: "This Year",
    NOTIFICATION_SELECT_OLDER: "Older",
    NOTIFICATION_SELECT_MARK_ALL_AS_READ: "Mark all as read",
    NOTIFICATION_SELECT_GO_TO_SETTING: "Go to Setting",
    // 3.7
    SHARELINK_CHECKOUT_DELIVERY_INFORMATION: "Delivery Information",
    SHARELINK_CHECKOUT_WHAT_S_YOUR_CONTACT_INFORMATION:
      "What’s Your Contact Information?",

    REPORTS_AMBASSADORS_TITLE_BRAND: "Brand Ambassadors",
    REPORTS_AMBASSADORS_TITLE_SIGN_UPS: "Ambassador Sign Ups",
    REPORTS_AMBASSADORS_TITLE_PRODUCT: "Product Shares",
    REPORTS_AMBASSADORS_TEXT_PER: "Average Shares Per Ambassadors",
    REPORTS_AMBASSADORS_TEXT_WAVES: "Total Shares from Waves",
    REPORTS_COMMISSION_TITLE_PAID: "Total Commissions Paid",
    REPORTS_COMMISSION_TITLE_ROI: "ROI on the Ambassadors",
    REPORTS_COMMISSION_TITLE_AVERAGE: "Earners",

    //4.7
    NO_SEARCH_DATA_TITLE: "Back to Search",
    NO_SEARCH_DATA_TIPS: "Sorry!  We couldn’t find any matches for ",

    //4.13
    REPORT_TIPS_GENERATED_SALES:
      "This metric represents the total number of sales that have been made by your brand ambassadors. It includes both initial referrals and subsequent repurchases from those new customers.",
    REPORT_TIPS_ORDER_GENERATED:
      "This metric indicates the number of orders placed with Kwik technology, including orders resulting from referrals and repurchases from Kwik-generated customers.",
    REPORT_TIPS_PRODUCT_VIEWS:
      "This metric reflects the number of unique views of your products generated through the share links used by your brand ambassadors.",
    REPORT_TIPS_BRAND_AMBASS:
      "This metric represents the total number of brand ambassadors who have joined your campaign and have registered for an account.",
    REPORT_TIPS_AMBASS_SIGN_UPS:
      "This metric represents the number of new brand ambassadors who have signed up for your program during the selected time frame.",
    REPORT_TIPS_TOTAL_COMM_PAID:
      "This metric represents the total amount of commissions paid to your brand ambassadors during the selected time frame.",
    REPORT_TIPS_ROI_ON_THE_AMBASS:
      "This metric represents the return on investment from what you have paid to your brand ambassadors versus the revenue that your ambassadors have generated for your business.",
    REPORT_TIPS_AVER_COMM_PER_AMBASS:
      "This metric represents the number of ambassadors who have earned a commission during the selected time frame.",
    REPORT_TIPS_CONVERSION:
      "This metric represents the rate at which potential customers click on a link from your brand ambassadors and subsequently make a purchase. It is calculated by dividing the number of orders generated by the total number of views.",

    NEW_DASHBOARD_KWIK_CONNECT: "WeR1 Connect",
    NEW_DASHBOARD_FACEBOOK_GROUP: "Facebook Group",
    NEW_DASHBOARD_WANT_TO_MEET_MORE_PIOPLE_MESSAGE:
      "Want to meet more people like you that want to earn some extra cash online doing what they love?",
    NEW_DASHBOARD_JOIN_THE_EXCLUSIVE_MESSAGE:
      "Join the exclusive WeR1 facebook group to see real people seeing results with WeR1.",
    NEW_DASHBOARD_JOIN_NOW_BTN: "Join Now",
    NEW_DASHBOARD_DONT_SHOW_THIS_AGAIN: "Don’t Show this Again",
    NEW_DASHBOARD_REFERRED: "Referred",
    NEW_DASHBOARD_SHARE: "Share",
    NEW_DASHBOARD_EXPLORE_MORE: "Explore More",
    NEW_DASHBOARD_THANK_YOU: "Thank You",
    NEW_DASHBOARD_HEY: "Hey",

    //4.19
    NEW_DASHBOARD_RECOMMENDED_PRODUCTS: "Recommended Products",
    NEW_DASHBOARD_RECOMMENDED_PRODUCTS_NO_DATAS:
      "Join a campaign to find products to share!",

    SIDEBAR_BONUS: "Bonus",
    VIEW_ALL_BONUSES: "View All Bonuses",
    LOYALTY_SUBTEXT:
      "View all the reward points you collected from different brands and campaigns as well as the bonuses that they have going on!",

    NEW_POPUP_CONTENT1: "-Re-designed User Dashboard",
    NEW_POPUP_CONTENT2: "-QR Codes for Sharing Links",
    NEW_POPUP_CONTENT3: "-Improvements for Brands and Users",
    NEW_POPUP_WHAT_IS_NEW: "Check out what’s new…",
    NEW_POPUP_BLOG_CONTENT:
      "Visit our Blog to read more about all of the changes included in this release!",
    NEW_POPUP_BLOG_URL: "blog.kwik.com",

    MOST_POPULAR: "Most Popular",
    MOST_RECENT_SHARE: "Most Recent Shares",
    SETTINGS_KWIK_TOOLTIP:
      "Kwikification: This option is selected when an integrated solution is used for creating, managing, and selling products. \n Kwik Marketplace: This option is used when the Kwik system is used for creating, managing, and selling products.",
    SHARE_INFO_LAYER_CREATE_A_SIGNUP_LINK: "Create a Sign Up Link",
    PAID_PROMOTION: "Paid Promotion",
    PRODUCT_NO_LONGER_AVAILABLE: "Product No Longer Available",
    BRAND_NO_LONGER_AVAILABLE: "Brand is No Longer on Kwik",
    DASHBOARD_TODAY: "Today",
    DASHBOARD_PAST_WEEK: "Past Week",
    DASHBOARD_PAST_MONTH: "Past Month",
    DASHBOARD_PAST_YEAR: "Past Year",
    DASHBOARD_VIEW_ALL_CAMPAIGN: "View all Campaign",
    DASHBOARD_ORDERS_NO_DATA:
      "No more losing orders numbers & tracking. Find it all here.",
    CAMPAIGNS_LEARN_KWIK: "Learn Kwik",
    CAMPAIGNS_WANT_TO_LEARN_ABOUT:
      "Want to learn about what data Kwik provides?",
    CAMPAIGNS_WHAT_IS_A_CAMP: "What is a campaign?",
    CAMPAIGNS_MINS_READ: "mins read",
    CAMPAIGNS_HOW_WE_TRACK_WAVES: "How we track waves",
    CAMPAIGNS_HOW_TO_NAVIGATE_DATA: "How to navigate your data",
    CAMPAIGNS_SHARING_LINKS: "Sharing Links",
    SPACES_ARE_NOT_ALLOWED_IN_USERNAME:
      "Spaces are not allowed in the username",
    DASHBOARD_SHARE_BRANDS: "Share Brands",
    DASHBOARD_TOP_CAMPAIGN: "Top Campaign",
    MINIMUM_8_CHARACTERS_FOR_SECURE_PASSWORD:
      "Minimum 8 characters for secure password.",
    UPPER_CASE: "Upper Case",
    LOWER_CASE: "Lower Case",
    ONE_NUMBERS: "One Numbers",
    A_8_CHARC: "8 characters",
    WALK_THROUGH: "Walk Through",
    KWIKCASH_ADD_PAYMENT_BTN_NEXT_STEP: "Next Step",
    SHARING_CHECKBOX_2: "Join a Campaign",
    YROYW:
      "You’re on your way! Now that you’ve created your account, get acquainted with WER1 by taking a minute to review the steps included here. They will have you on the fast track to making waves!",
    EJABCBM:
      "Easily join a brand’s campaign by making a purchase from their brand, by being added by the brand directly, or join a free-to-join campaign!",
    SASLN:
      "Send a share link now!  Once someone purchases from your link, you have completed a successful referral!",
    GTTKPTSHMY:
      "Go to the 1Cash page to see how much you have earned by sharing links for products and services you love and use everyday!",
    SHAYDAIRC:
      "See how all your direct and indirect referrals comprise your waves and grow your waves of earnings!",
    SHARE_ORDER: "Share Order",
    WAYS_TO_REDEEM: "Ways to Redeem",
    WAYS_TO_EARN: "Ways to Earn",
    VIEW_ALL_PRODUCTS: "View All Products",
    SHARE_BRAND: "Share Brand",
    LOYALTY_POINTS: "Loyalty Points",
    LOYALTY_POINTS_REWARDS: "Rewards",
    INVITE_A_FRIEND: "Invite a Friend",
    REDEEM: "Redeem",
    FREE_SHIPPING_COUPONS: "Free Shipping Coupons",
    COUPONS_DESC:
      "Use the coupon code on your next purchase to use your reward.",
    FREE_SHIPPING: "FREESHIPPING",
    FREE_PRODUCT: "Free Product",
    FREEMTNP: "FREEMTNP",
    DISCOUNT_AMOUNT: "DISCOUNTAMOUNT",
    DISCOUNT_PERCENT: "DISCOUNTPERCENT",
    DISCOUNT_AMOUT_COUPONS: "Discount Amount Coupons",
    DISCOUNT_PERCENT_COUPONS: "Discount Percent Coupons",
    CODE_COPY_SUCCESS: "Copied Successfully!",
    COPY_CODE: "Copy Code",
    TAKE_ME_TO_STORE: "Take me to the store",
    POINTS: "Points",
    POST_TRANSACTION_BALANCE: "Post Transaction Balance",
    REDEEM_REWARD: "Redeem Reward",
    REDEMPTION_SUCCESSFUL: "Redemption Successful",
    YRHBRYWFTC: "Your reward has been redeemed! You will find the code",
    IYCRSUTCO: "In your claimed reward section! Use that code on",
    YNPTCTR: "Your next purchase to claim the reward!",
    BACK_TO_REWARDS: "Back to Rewards",
    LAMAUYCR: "Learn about managing and using your claimed rewards",
    CONFIRM_TRANSACTION: "Confirm Transaction",
    PLEASE_CONFIRM_THE_TRANSACTION: "Please confirm the transaction",
    NWGFPITB:
      "Nice work! Getting free product is the best! All you gotta do is see how many points you have and how much the product costs. Then all you gotta do it his redeem! Then a coupon code will be generated that you will be able to use on that brand website for free product!",
    RTGFSOYNO:
      "Ready to get free shipping on your next order? Click redeem and you will get generated a unique one time use coupon code that you can use on {{storeName}} website to get free shipping on your next order!",
    KWIKCASH_VALID_TILL: "Valid till",
    KWIKCASH_ESTIMATED_BONUS: "Estimated Bonus",
    KWIKCASH_VIEW_BONUS_DETAILS: "View Bonus Details",
    KWIKCASH_EST_BONUS_FOR_PERIOD_END_DATE: "Bonus Qualification Deadline",
    KWIKCASH_ACTIVE_BONUS: "Active Bonuses",
    KWIKCASH_CLAIMED_REWARDS: "Claimed Rewards",
    POINTS_EARNED: "Points earned",
    WHATSNEW_TOP_TEXT: "Double Extension",
    WHATSNEW_HEADER_TEXT: "We Have Good News!",
    WHATSNEW_BODY_TEXT:
      "Due to popular demand and the overwhelming enthusiasm of our incredible community, we've decided to extend the Double deadline for an additional week!",
    WHATSNEW_DEADLINE_TEXT:
      "New Double Bonus Deadline: Friday, October 6th, 11:59PM",
    WHATSNEW_BTN_TEXT: "Learn More",
    IAMACOMPANY: "I am a company",
    BUSINESSNAME_OPTIONAL: "Business Name (Optional)",
    EINNUMBER: "EIN Number",
    SOCIAL_POPUP_CONTENT_1:
      "Follow the WeR1 social accounts to get all updates!",
    SOCIAL_POPUP_CONTENT_2:
      "To kick things off, get 5 free giveaway entries for each page you follow!",
    SOCIAL_POPUP_INS: "Follow the WeR1 Instagram",
    SOCIAL_POPUP_FB: "Follow the WeR1 Facebook",
    SOCIAL_POPUP_YTB: "Follow the WeR1 Youtube",
    SOCIAL_FOLLOW_US: "Follow Us!",
    SOCIAL_BOTTOM_DESC: "Click on a page to follow",
    SOCIAL_BTN_TEXT: "Click when you’re done!",
    NEW_DASHBOARD_IMPORTANT_STEP: "Important Step!",
    DSTSWUYEATGYYR:
      "Don’t skip this step! WeR1 utilizes your email address to give you your rewards. ",
    YAEAI: "Your account email address is: ",
    IYUADEAWPFWYRWGRTTE:
      "If you use a different email address when purchasing from WeR1, your rewards will get routed to that email.",
    TOHELPWITHTHISWE:
      "To help with this, we allow you to add email aliases where if you utilize any of these emails while checking out your rewards will still be put in your WeR1 account.",
    ENTER_YOUR_EMAILS: "Enter your emails",
    AEAEWBSUC:
      "Alias Email addresses entered will be saved upon clicking SAVE.  After clicking SAVE, any additional changes to alias Emails should be made in Account Settings.",
    WUYAETAYRWYA:
      "We utilize your account email to associate your rewards with your account, add up to two different emails that you use when buying products so that if you use one of those emails when purchasing you will still get the rewards in your account.",
    EMAIL_ALIASES: "Email Aliases",
    VIEW_REWARDS: "View Rewards",
    SHARE_PRODUCT: "Share Product",
    PURCHASING: "Purchasing",
    REFERRING: "Referring",
    BUY: "Buy",
    BONUSES: "Bonuses",
    YANAVP: "You are not a Verified purchaser",
    NVUCGRFAP: "Non-Verified user cannot give ratings for a product",
    BRAND: "Brand",
  },
};
